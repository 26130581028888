import clsx from "clsx";
import { HtmlHTMLAttributes } from "react";
import IconAiWhiteSrc from '../../images/icon-ai-technology-white.png';
import IconAiSrc from '../../images/icon-ai-technology.png';

export interface IconTypeProps extends HtmlHTMLAttributes<HTMLDivElement>{ color?:'white' }

export const IconWarning:React.FC<IconTypeProps> = (props)=>
    <i {...props} className={clsx("bi-exclamation-triangle-fill", props.className)}>{props.children}</i>;

export const IconDanger:React.FC<IconTypeProps> = (props)=>
    <i {...props} className={clsx("bi-x-octagon-fill", props.className)}>{props.children}</i>;

export const IconSuccess:React.FC<IconTypeProps> = (props)=>
    <i {...props} className={clsx("bi-check-circle-fill", props.className)}>{props.children}</i>;

export const IconInfo:React.FC<IconTypeProps> = (props)=>
    <i {...props} className={clsx("bi-info-circle-fill", props.className)}>{props.children}</i>;
    
export const IconShieldCheck:React.FC<IconTypeProps> = (props)=><div style={{position:'relative', display:'flex'}}>
    <i {...props} className={clsx("bi-shield-fill", props.className)} style={{opacity:0.3, ...props.style}}>{props.children}</i>
    <i {...props} className={clsx("bi-check", props.className)} style={{position:'absolute', ...props.style}}>{props.children}</i>
</div>;

export const IconAi:React.FC<IconTypeProps> = (props)=>
    <img
        alt='AI'
        src={props.color === 'white' ? IconAiWhiteSrc : IconAiSrc }
        style={{width:'1.3em', height:'1.3em', ...props.style}}
        className={clsx('app-sidebar-logo-default', props.className)}
    />
export const IconAiWhite:React.FC<IconTypeProps> = (props)=>
    <img
        alt='AI'
        src={IconAiWhiteSrc}
        style={{width:'1.3em', height:'1.3em', ...props.style}}
        className={clsx('app-sidebar-logo-default', props.className)}
    /> 


