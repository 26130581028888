import React, {CSSProperties, useEffect, useState} from 'react';
import {GfaService} from '../../../common/services';
import {
    StatReportResKeys,
    StatReportResType,
} from '../../../common/services/models/gfa/StatReportType';
import {LoadingLayer} from '../../../modules/modal/LoadingModal';
import Table from '../../modules/table/Table';
import {HeaderColumn, TablePropsType} from '../../modules/table/TableType';
import {GfaOxfordColumns, StatDataExtends} from '../modules/GfaOxfordColumns';
import ExplorerChart from './ExplorerChart';
import {getStatDateFormatter, getStatReportTotal} from './StatInfoData';

interface ExplorerListDetailProps {
    level?: string;
    id?: string;
    className?: string;
    style?: CSSProperties;
}
const ExplorerListDetail: React.FC<ExplorerListDetailProps> = (props) => {
    const [data, setData] = useState<StatReportResType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        if (props.level && props.id) {
            setLoading(true);
            GfaService.performance(props.level, props.id)
                .then((res) => {
                    const data: any[] =
                        res?.data?.result?.daily?.map((v: StatReportResType) =>
                            StatDataExtends(v)
                        ) || []; //일부러 any처리함 - 아래 빠진 일자데이터 추가
                    const dateRange = GfaService.common.getDateRange();
                    const days = dateRange.end.dateDiff(dateRange.start);
                    const result: any[] = [];
                    for (let i = 0; i <= days; i++) {
                        const date = new Date(dateRange.start).addDays(i).format('yyyy-MM-dd');
                        result.push({
                            key: date,
                            ...data.find((v) => v.key === date),
                        });
                    }
                    setData(result);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [props.level, props.id]);

    const keys: string[] = [
        StatReportResKeys.impressions,
        StatReportResKeys.clicks,
        StatReportResKeys.cost,
        StatReportResKeys.videoView,
        StatReportResKeys.ctr,
        StatReportResKeys.vtr,
        StatReportResKeys.cpc,
        StatReportResKeys.cpm,
        StatReportResKeys.cpv,
    ];

    const dataColumns: HeaderColumn[] = GfaOxfordColumns.filter((v) =>
        keys.includes(v.accessor)
    ).sort((a, b) => keys.indexOf(a.accessor) - keys.indexOf(b.accessor)); //위 키 순서대로 노출하도록함
    const columns: HeaderColumn[] = [
        {
            accessor: StatReportResKeys.key,
            header: '날짜',
            style: {minWidth: '100px', textAlign: 'center'},
            useSort: false,
            formatter: getStatDateFormatter,
        },
        ...dataColumns,
    ];

    const tableProps: TablePropsType<StatReportResType> = {
        columns: columns,
        data: data,
        filterable: false,
        showColumns: false,
        showDeviceType: false,
        pageable: false,
        staticData: [getStatReportTotal(data)],
    };

    return (
        <div className={props.className} style={props.style}>
            {loading && (
                <LoadingLayer
                    className='border bg-gray-100 pt-20 pb-20 text-secondary'
                    variant='secondary'
                    style={{paddingLeft: '350px'}}
                />
            )}
            {!loading && (
                <>
                    <ExplorerChart data={data} columns={dataColumns} />
                    <Table {...tableProps} />
                </>
            )}
        </div>
    );
};

export default ExplorerListDetail;
