import UserSelect from '../../common/helpers/UserSelect';
import Code from '../../modules/code/Code';
import { Explorer as GfaExplorer } from '../gfa/explorer/Explorer';
import { Explorer as GoogleExplorer } from '../google/explorer/Explorer';
import { Explorer as KaKaoExplorer } from '../kakao/explorer/Explorer';
import { Explorer as KakaoMomentExplorer } from '../kakaomoment/explorer/Explorer';
import { Explorer as MetaExplorer } from '../meta/explorer/Explorer';
import CustomCard from '../modules/card/CustomCard';
import { Explorer as NaverExplorer } from '../naver/explorer/Explorer';
import { Explorer as NospExplorer } from '../nosp/explorer/Explorer';
import ExplorerHeader from './ExplorerHeader';

export function ExplorerLayer() {
    const account  = UserSelect.account.get();
    const isNaver = account?.accountClient?.mediaType === Code.base.mediaType.NAVER.value;
    const isGFA = account?.accountClient?.mediaType === Code.base.mediaType.NAVER_GFA.value;
    const isNOSP = account?.accountClient?.mediaType === Code.base.mediaType.NAVER_NOSP.value;
    const isKakao = account?.accountClient?.mediaType === Code.base.mediaType.KAKAO.value;
    const isKakaoMoment = account?.accountClient?.mediaType === Code.base.mediaType.KAKAO_MOMENT.value;
    const isGoogle = account?.accountClient?.mediaType === Code.base.mediaType.GOOGLE.value;
    const isMeta = account?.accountClient?.mediaType === Code.base.mediaType.META.value;

    return (<>
        {/* begin::Header */}
        <ExplorerHeader />
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body bg-light p-5 pb-0' id='kt_explore_body'>
            {/* begin::Content */}
            <div
                id='kt_explore_scroll'
                className='scroll-y me-n5 pe-5'
                data-kt-scroll='true'
                data-kt-scroll-height='auto'
                data-kt-scroll-wrappers='#kt_engage_explorer_body'
                data-kt-scroll-dependencies='#kt_engage_explorer_header'
                data-kt-scroll-offset='5px'
                style={{maxHeight:'calc(100vh - 102px)'}}
            >
                {!account?.accountClient?.mediaType && <CustomCard className='text-center pt-9 pb-9'>광고주 계정을 선택하셔야 합니다!</CustomCard>}
                {isNaver && <NaverExplorer />}
                {isGFA && <GfaExplorer />}
                {isNOSP && <NospExplorer />}
                {isKakao && <KaKaoExplorer />}
                {isKakaoMoment && <KakaoMomentExplorer />}
                {isGoogle && <GoogleExplorer />}
                {isMeta && <MetaExplorer />}
                
            </div>
            {/* end::Content */}
        </div>
        {/* end::Body */}
    </>)
}
