import { FC, useState } from 'react';
import { ThemeControll } from '../../../../app/common/conf/ThemeConfig';
import { useUserContext } from '../../../../app/contexts/UserContext';
import AdlyAiCode from '../../../../app/modules/code/AdlyAiCode';
import Code from '../../../../app/modules/code/Code';
import { IconAi } from '../../../../app/modules/icon';

const ExplorerToggleDrawer: FC = () =>{
  const [color, setColor] = useState<'white'|undefined>();
  const userContext = useUserContext();
  const isShow = ThemeControll.isStatShow();

  const onChangeTab = (tab:string)=>{
    const data = userContext.explorerStorage.data;
    if(data){
        data.selectTab = tab;
        data.type = AdlyAiCode.data.type.entryType.list.value;
        userContext.explorerStorage.set(data);
    }
}
  return <>{isShow && <>
      <button
        id='kt_engage_explorer_toggle'
        className='engage-explorer-toggle btn btn-flex h-35px btn-light-info bg-hover-info bg-active-info btn-active-color-white shadow-sm fs-6 px-4 rounded-top-0'
        title={`Check out ${process.env.REACT_APP_THEME_NAME} more explorer`}
        data-bs-toggle='tooltip'
        data-bs-placement='left'
        data-bs-dismiss='click'
        data-bs-trigger='hover'
        onMouseOver={()=>{setColor('white')}}
        onMouseOut={()=>{setColor(undefined)}}
        onClick={()=>{ onChangeTab(Code.base.explorerTabType.aiExplorer.value); }}
      >
        <IconAi color={color} className='me-2' style={{transform: 'rotate(-90deg)'}} />
        <span>adly AI</span>
      </button>


      <button
        id='kt_engage_explorer_toggle'
        className='engage-explorer-toggle btn btn-flex h-35px btn-light-primary bg-hover-primary bg-active-primary btn-active-color-white  shadow-sm fs-6 px-4 rounded-top-0'
        title={`Check out ${process.env.REACT_APP_THEME_NAME} more explorer`}
        data-bs-toggle='tooltip'
        data-bs-placement='left'
        data-bs-dismiss='click'
        data-bs-trigger='hover'
        onClick={()=>{ onChangeTab(Code.base.explorerTabType.explorer.value); }}
      >
        <span id='kt_engage_explorer_label'>Explorer</span>
      </button>
    </>
  }</>
}

export { ExplorerToggleDrawer };

