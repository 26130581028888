import axios from 'axios';
import UserSelect from '../../helpers/UserSelect';
import { Service } from '../Service';
import AppHelper from './AppHelper';
import ContentHelper from './ContentHelper';
import DownloadHelper from './DownloadHelper';
import ModelHelper from './ModelHelper';
import OpenAiHelper from './OpenAiHelper';
import ReqHistoryHelper from './ReqHistoryHelper';
import TokenUsageHelper from './TokenUsageHelper';

/** 서비스 */
export default class AdlyAiService extends Service{
    public static common = class{
        public static getDateRange(range?:{start:Date, end:Date}):{start:Date, end:Date}{
            const dateRange = UserSelect.dateRange.get();
            const start = (range?.start || new Date(dateRange?.startDate || new Date().addDays(-15)));
            const end = (range?.end || new Date(dateRange?.endDate || new Date().addDays(-1)));
            return  {start, end};
        }
    }

    /**
     * S3URL을 이용하여 원하는 파일명으로 다운로드 합니다.
     * @param url S3 URL
     * @param filename : 다운로드시 생성될 파일명, 파일명을 지정하지 않는 경우 blob을 사용하지 않고 바로 새창열기를 통해 다운로드 시도합니다.
     */
    public static S3downloadReName(url:string, filename?:string){
        const server = axios.create();
        server.get(url).then((res)=>{
            //파일명을 지정하지 않는 경우 바로 다운로드
            if(!filename){
                window.open(url, '_blank');
            }else{
                // // 응답 헤더에서 response-content-disposition 값을 읽음
                // const contentDisposition = res.headers['response-content-disposition'];

                // // filename 속성의 값을 추출하기 위한 정규 표현식
                // const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                
                // // 정규 표현식을 사용하여 filename 값을 추출
                // const matches = filenameRegex.exec(contentDisposition);
                
                // if (matches != null && matches[1]) {
                //     // filename 값이 존재할 경우 공백을 제거하고 출력
                //     const filename = matches[1].replace(/['"]/g, '');
                //     console.log('파일 이름:', filename);
                // } else {
                //     console.log('파일 이름을 찾을 수 없습니다.');
                // }

                const blob = new Blob([res.data]);
                // Blob을 파일로 다운로드
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = filename; // 새 파일 이름 설정
                link.click();
            }
        })
    }


    //////// Class /////////
    public static openai        = OpenAiHelper;
    public static model         = ModelHelper;
    public static content       = ContentHelper;
    public static app           = AppHelper;
    public static tokenUsage    = TokenUsageHelper;
    public static reqHistory    = ReqHistoryHelper;

    public static download      = DownloadHelper;
}