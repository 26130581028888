import Code from './Code';

export default class GfaCode extends Code {
    public static readonly data = {
        ////옵션선택용 //
        options: {
            ExplorerLevel: {
                campaign: {value: 'campaigns', label: '캠페인'},
                adGroup: {value: 'ad-groups', label: '광고그룹'},
                ad: {value: 'ads', label: '광고소재'},
            },
        },

        assets: {},

        type: {
            /** ON/OFF */
            activated: {
                ON: {value: 'true', label: 'ON'},
                OFF: {value: 'false', label: 'OFF'},
            },

            /** 캠페인 목적 */
            objectiveType: {
                conversion: {value: 'CONVERSION', label: '웹사이트 전환'},
                web_site_traffic: {value: 'WEB_SITE_TRAFFIC', label: '인지도 및 트래픽'},
                install_app: {value: 'INSTALL_APP', label: '앱 전환'},
                shopping: {value: 'SHOPPING', label: '쇼핑 프로모션'},
                watch_video: {value: 'WATCH_VIDEO', label: '동영상 조회'},
                catalog: {value: 'CATALOG', label: '카탈로그 판매'},
                lead: {value: 'LEAD', label: '참여 유도'},
                null: {value: 'null', label: '알 수 없음'},
            },

            /** 예산 타입 */
            budgetType: {
                daily: {value: 'DAILY', label: '일예산'},
                total: {value: 'TOTAL', label: '총예산'},
            },

            /** 소재 선택 방식 */
            creativeChooserType: {
                VALUE_WEIGHTED_RANDOM: {value: 'VALUE_WEIGHTED_RANDOM', label: '가중치별 게재'},
                SIMPLE_RANDOM: {value: 'SIMPLE_RANDOM', label: '임의 게재'},
                OPTIMIZATION: {value: 'OPTIMIZATION', label: '최적화 게재'},
            },

            /** 노출 빈도 단위 */
            frequencyAdUnit: {
                AD_SET: {value: 'AD_SET', label: '광고 그룹'},
                CREATIVE: {value: 'CREATIVE', label: '광고 소재'},
            },

            /** 계속 게재 여부 */
            ongoing: {
                true: {value: 'true', label: '설정'},
                false: {value: 'false', label: '미설정'},
            },

            /** 삭제 여부 */
            deleted: {
                true: {value: 'true', label: '삭제'},
                false: {value: 'false', label: '미삭제'},
            },

            /** 캠페인 > 상태 */
            campaignStatus: {
                RUNNABLE: {value: 'RUNNABLE', label: '운영 가능'},
                RUNNABLE_LEARNING: {value: 'RUNNABLE_LEARNING', label: '운영 가능(학습중)'},
                RUNNABLE_LEARNING_LIMIT: {
                    value: 'RUNNABLE_LEARNING_LIMIT',
                    label: '운영 가능(학습 제한)',
                },
                DELETED: {value: 'DELETED', label: '삭제됨'},
                BUDGET_EXHAUSTED: {value: 'BUDGET_EXHAUSTED', label: '예산 도달'},
            },

            /** 광고그룹 > 상태 */
            adGroupStatus: {
                RUNNABLE: {value: 'RUNNABLE', label: '운영가능'},
                BEFORE_STARTING: {value: 'BEFORE_STARTING', label: '광고집행전'},
                TERMINATED: {value: 'TERMINATED', label: '광고집행종료'},
                DELETED: {value: 'DELETED', label: '삭제됨'},
                BUDGET_EXHAUSTED: {value: 'BUDGET_EXHAUSTED', label: '예산 도달'},
                LOW_TARGETED: {value: 'LOW_TARGETED', label: '타겟 부족'},
                TARGET_DISABLED: {value: 'TARGET_DISABLED', label: '타겟팅 비활성화'},
                LEARNING: {value: 'LEARNING', label: '운영가능(학습중)'},
                LEARNING_LIMITED: {value: 'LEARNING_LIMITED', label: '운영가능(학습제한)'},
            },

            /** 광고소재 > 상태 */
            adStatus: {
                PENDING: {value: 'PENDING', label: '검수중'},
                REJECT: {value: 'REJECT', label: '반려'},
                ACCEPT: {value: 'ACCEPT', label: '승인'},
                PENDING_IN_OPERATION: {
                    value: 'PENDING_IN_OPERATION',
                    label: '승인(수정사항 검수중)',
                },
                REJECT_IN_OPERATION: {value: 'REJECT_IN_OPERATION', label: '승인(수정사항 반려)'},
                DELETED: {value: 'DELETED', label: '삭제됨'},
            },

            /** 전환추적대상 운영체재 */
            conversionUrlStorePlatform: {
                ANDROID: {value: 'ANDROID', label: 'Android'},
                IOS: {value: 'IOS', label: 'iOS'},
            },

            /** 전환추적대상 상태 */
            inspectionStatus: {
                PENDING: {value: 'PENDING', label: '검수중'},
                APPROVED: {value: 'APPROVED', label: '승인'},
                REJECTED: {value: 'REJECTED', label: '반려'},
            },

            /** 전환추적대상 비즈채널 타입 */
            bizChannelType: {
                NAVER_SHOPPING: {value: 'NAVER_SHOPPING', label: '네이버 쇼핑'},
                WEBSITE: {value: 'WEBSITE', label: '웹사이트'},
                MOBILE_APP: {value: 'MOBILE_APP', label: '모바일 앱'},
            },

            /** 예산 최적화 여부 */
            cboActivated: {
                false: {value: 'false', label: '미설정'},
                true: {value: 'true', label: '설정'},
            },

            /** 입찰 전략 */
            bidGoal: {
                MAX_CLICK: {value: 'MAX_CLICK', label: '클릭 수 최대화'},
                MAX_CONV: {value: 'MAX_CONV', label: '전환 수 최대화'},
                MAX_CONV_VALUE: {value: 'MAX_CONV_VALUE', label: '전환 가치 최대화'},
                NONE: {value: 'NONE', label: '없음'},
            },

            /** 캠페인 > 비용 관리 */
            campaignBidStrategy: {
                FIXED_BID: {value: 'FIXED_BID', label: '고정 입찰가'},
                BID_CAP: {value: 'BID_CAP', label: '입찰가 한도'},
                COST_CAP: {value: 'COST_CAP', label: '비용 한도'},
                NO_CAP: {value: 'NO_CAP', label: '입찰가 한도 없음'},
                TARGET_COST: {value: 'TARGET_COST', label: '목표 비용'},
            },

            /** 광고그룹 > 비용 관리 */
            adGroupBidStrategy: {
                FIXED_BID: {value: 'FIXED_BID', label: '고정 입찰가'},
                BID_CAP: {value: 'BID_CAP', label: '입찰가 한도'},
                COST_CAP: {value: 'COST_CAP', label: '비용 한도'},
                NO_CAP: {value: 'NO_CAP', label: '입찰가 한도 없음'},
            },

            /** 철구 기준 */
            pricingType: {
                CPC: {value: 'CPC', label: 'CPC'},
                CPM: {value: 'CPM', label: 'CPM'},
                CPV: {value: 'CPV', label: 'CPV'},
            },

            /** 최적화 전환 유형 */
            conversionType: {
                PURCHASE: {value: 'PURCHASE', label: '구매완료'},
                MEMBERSHIP: {value: 'MEMBERSHIP', label: '회원가입'},
                CART: {value: 'CART', label: '장바구니 담기'},
                RESERVATION: {value: 'RESERVATION', label: '신청/예약'},
                INSTALL: {value: 'INSTALL', label: '설치'},
                ETC: {value: 'ETC', label: '기타'},
            },

            /** 다운로드 Entity 타입 */
            downlaodEntityType: {
                // all: {value:"NGDT0001", label:"전체데이터"},
                // all: {value:"NGDT0002", label:"리포트"},
                // all: {value:"NGPT0001", label:"네이버 성과형 DA"},
                all: {value: 'NGET0001', label: '전체', data: {path: 'all'}},
                campaign: {value: 'NGET0002', label: '캠페인', data: {path: 'campaign'}},
                adgroup: {value: 'NGET0003', label: '광고그룹', data: {path: 'ad-group'}},
                ad: {value: 'NGET0004', label: '광고소재', data: {path: 'ad'}},
            },

            /** 게제 위치 */
            placementType: {
                BAND: {value: 'BAND', label: '네이버 패밀리 매체 > 피드 영역'},
                F_BANNER: {value: 'F_BANNER', label: '네이버 패밀리 매체 > 배너 영역'},
                F_SMARTCHANNEL: {value: 'F_SMARTCHANNEL', label: '네이버 패밀리 매체 > 스마트채널'},
                M_BANNER: {value: 'M_BANNER', label: '네이버 > 배너 영역 > 서비스 통합'},
                M_FEED: {value: 'M_FEED', label: '네이버 > 피드 영역'},
                M_MAIN: {value: 'M_MAIN', label: '네이버 > 배너 영역 > 네이버 메인'},
                M_SMARTCHANNEL: {value: 'M_SMARTCHANNEL', label: '네이버 > 스마트채널'},
                NW_BANNER: {value: 'NW_BANNER', label: '네이버 퍼포먼스 네트워크 > 배너 영역'},
                NW_SMARTCHANNEL: {
                    value: 'NW_SMARTCHANNEL',
                    label: '네이버 퍼포먼스 네트워크 > 스마트채널',
                },
                N_COMMUNICATION: {value: 'N_COMMUNICATION', label: '네이버 > 커뮤니케이션 영역'},
                N_INSTREAM: {value: 'N_INSTREAM', label: '네이버 > 인스트림 영역'},
                N_SHOPPING: {value: 'N_SHOPPING', label: '네이버 > 쇼핑 영역'},
            },
            /** 소재타입 */
            creativeType: {
                single_image: {value: 'SINGLE_IMAGE', label: '네이티브 이미지'},
                multiple_image: {value: 'MULTIPLE_IMAGE', label: '이미지 슬라이드'},
                single_video: {value: 'SINGLE_VIDEO', label: '동영상'},
                image_banner: {value: 'IMAGE_BANNER', label: '이미지 배너'},
                catalog: {value: 'CATALOG', label: '카탈로그'},
            },

            /** 타겟팅 유형 */
            targetingType: {
                AUDIENCE: {value: 'AUDIENCE', label: '오디언스'},
                CONTEXT: {value: 'CONTEXT', label: '콘텍스트'},
            },

            /** 성별 */
            genders: {
                M: {value: 'M', label: '남자'},
                F: {value: 'F', label: '여자'},
                U: {value: 'U', label: '성별모름'},
            },

            /** 연령 */
            ageRanges: {
                1418: {value: `{\"from\":14, \"to\":18}`, label: '14~18세'},
                1924: {value: `{\"from\":19, \"to\":24}`, label: '19~24세'},
                2529: {value: `{\"from\":25, \"to\":29}`, label: '25~29세'},
                3034: {value: `{\"from\":30, \"to\":34}`, label: '30~34세'},
                3539: {value: `{\"from\":35, \"to\":39}`, label: '35~39세'},
                4044: {value: `{\"from\":40, \"to\":44}`, label: '40~44세'},
                4549: {value: `{\"from\":45, \"to\":49}`, label: '45~49세'},
                5054: {value: `{\"from\":50, \"to\":54}`, label: '50~54세'},
                5559: {value: `{\"from\":55, \"to\":59}`, label: '55~59세'},
                60: {value: `{\"from\":60, \"to\":200}`, label: '60세 이상'},
                not: {value: `{\"from\":-1, \"to\":-1}`, label: '비해당자 포함'},
            },

            /** 확장 데모 */
            extensionDemos: {
                1: {value: '1', label: '영아(0~2세) 부모'},
                2: {value: '2', label: '유아/아동(3~6세) 부모'},
                3: {value: '3', label: '초등학생 저학년(7~9세) 부모'},
                4: {value: '4', label: '초등학생 고학년(10~12세) 부모'},
            },

            /** 관심사/구매의도 타겟 설정 방식 여부 */
            interestIntersection: {
                true: {value: 'true', label: '설정'},
                false: {value: 'false', label: '미설정'},
            },

            /** 노출 가능한 모든 디바이스 선택 여부 */
            allDevice: {
                true: {value: 'true', label: '설정'},
                false: {value: 'false', label: '미설정'},
            },

            /** 디바이스 타입 */
            devices: {
                DESKTOP: {value: 'DESKTOP', label: 'PC'},
                MOBILE: {value: 'MOBILE', label: '모바일'},
            },

            /** 노출 가능한 모든 게재위치 선택 여부 */
            allPlacementGroup: {
                true: {value: 'true', label: '설정'},
                false: {value: 'false', label: '미설정'},
            },

            /** 운영체제 */
            platforms: {
                ANDROID: {value: 'ANDROID', label: 'Android'},
                IOS: {value: 'IOS', label: 'iOS'},
            },

            /** 자동입찰 상태 */
            autoBidStatus: {
                LEARNING: {value: 'LEARNING', label: '학습중'},
                RE_LEARNING: {value: 'RE_LEARNING', label: '재학습중'},
                STABLE: {value: 'STABLE', label: '안정'},
                LIMITED: {value: 'LIMITED', label: '제한됨'},
            },

            /** 캠페인 지출 한도 도달 여부 */
            budgetExhausted: {
                true: {value: 'true', label: '도달'},
                false: {value: 'false', label: '미도달'},
            },

            /** 카탈로그 사용가능 여부 */
            catalogAvailable: {
                true: {value: 'true', label: '가능'},
                false: {value: 'false', label: '불가'},
            },

            /** 게재 위치 */
            placementGroupsList: {
                BAND: {value: 'BAND', label: '네이버 패밀리 매체 > 피드 영역'},
                F_BANNER: {value: 'F_BANNER', label: '네이버 패밀리 매체 > 배너 영역'},
                F_SMARTCHANNEL: {value: 'F_SMARTCHANNEL', label: '네이버 패밀리 매체 > 스마트채널'},
                M_BANNER: {value: 'M_BANNER', label: '네이버 > 배너 영역 > 서비스 통합'},
                M_FEED: {value: 'M_FEED', label: '네이버 > 피드 영역'},
                M_MAIN: {value: 'M_MAIN', label: '네이버 > 배너 영역 > 네이버 메인'},
                M_SMARTCHANNEL: {value: 'M_SMARTCHANNEL', label: '네이버 > 스마트채널'},
                NW_BANNER: {value: 'NW_BANNER', label: '네이버 퍼포먼스 네트워크 > 배너 영역'},
                NW_SMARTCHANNEL: {
                    value: 'NW_SMARTCHANNEL',
                    label: '네이버 퍼포먼스 네트워크 > 스마트채널',
                },
                N_COMMUNICATION: {value: 'N_COMMUNICATION', label: '네이버 > 커뮤니케이션 영역'},
                N_INSTREAM: {value: 'N_INSTREAM', label: '네이버 > 인스트림 영역'},
                N_SHOPPING: {value: 'N_SHOPPING', label: '네이버 > 쇼핑 영역'},
            },

            /** 게재 방식 */
            accelerated: {
                true: {value: 'true', label: '빠른 게재'},
                false: {value: 'false', label: '일반 게재'},
            },

            /** 상세 일정 */
            scheduleTimeSlotsName: {
                total: {value: '전체 요일/시간', label: '전체 요일/시간'},
                partial: {value: '일부 요일/시간', label: '일부 요일/시간'},
            },
        },
    };
}
