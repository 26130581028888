import Code from './Code';

export default class MetaCode extends Code {
    public static readonly data = {
        options: {
            /*
            실적Level
            biz-channels
            campaigns
            adsets
            ads
            ad-extensions
            keywords
            creatives // 카카오
            creative-links // 카카오
            */
            ExplorerLevel: {
                campaign: {value: 'campaigns', label: '캠페인'},
                adGroup: {value: 'adsets', label: '광고세트'},
                ad: {value: 'ads', label: '광고'},
            },
        },

        type: {
            downlaodEntityType: {
                campaign: {value: 'GET0002', label: '캠페인', data: {path: 'campaign'}},
                adgroup: {value: 'GET0003', label: '광고세트', data: {path: 'adset'}},
                ad: {value: 'GET0004', label: '광고', data: {path: 'ads'}},
            },
        },

        Assets: {
            /** ON/OFF */
            status: {
                ON: {label: 'ON', value: 'ON'},
                OFF: {label: 'OFF', value: 'OFF'},
            },
            /** 구매 유형 */
            buyingType: {
                AUCTION: {label: '경매', value: 'AUCTION'},
                RESERVED: {label: '예약', value: '예약'},
            },
            /** 입찰 전략 */
            bidStrategy: {
                LOWEST_COST_WITH_MIN_ROAS: {label: 'ROAS 목표', value: 'ROAS 목표'},
                LOWEST_COST_WITHOUT_CAP: {label: '최고 볼륨', value: '최고 볼륨'},
                LOWEST_COST_WITH_BID_CAP: {label: '입찰가 한도', value: '입찰가 한도'},
                COST_CAP: {label: '결과당 비용 목표', value: '결과당 비용 목표'},
                recommend: {label: '추천 설정', value: '추천 설정'},
                adSetBudgetOptimization: {
                    label: '광고세트의 입찰 전략 사용',
                    value: '광고세트의 입찰 전략 사용',
                },
            },
            /** 캠페인 예산 최적화 */
            isAdvantageCampaignBudget: {
                true: {label: '설정됨', value: 'true'},
                false: {label: '해제됨', value: 'false'},
            },
            /** 기여 설정 */
            attributionType: {
                MULTI: {label: '여러 기여 설정', value: 'MULTI'},
                NONE_AND_MULTI: {label: '~', value: 'NONE_AND_MULTI'},
                CLICK_THROUGH_1_AND_VIEW_THROUGH_1: {
                    label: '1일간의 클릭수 또는 1일간의 조회수',
                    value: 'CLICK_THROUGH_1_AND_VIEW_THROUGH_1',
                },
                CLICK_THROUGH_1_AND_ENGAGED_VIDEO_VIEW_1: {
                    label: '클릭 후 1일 또는 참여한 조회 후 1일',
                    value: 'CLICK_THROUGH_1_AND_ENGAGED_VIDEO_VIEW_1',
                },
                CLICK_THROUGH_1_AND_VIEW_THROUGH_1_AND_ENGAGED_VIDEO_VIEW_1: {
                    label: '클릭 후 1일, 조회 후 1일 또는 참여한 조회 후 1일',
                    value: 'CLICK_THROUGH_1_AND_VIEW_THROUGH_1_AND_ENGAGED_VIDEO_VIEW_1',
                },
                CLICK_THROUGH_1: {label: '1일간의 클릭수', value: 'CLICK_THROUGH_1'},
                CLICK_THROUGH_7_AND_VIEW_THROUGH_1: {
                    label: '7일간의 클릭수 또는 1일간의 조회수',
                    value: 'CLICK_THROUGH_7_AND_VIEW_THROUGH_1',
                },
                CLICK_THROUGH_7_AND_ENGAGED_VIDEO_VIEW_1: {
                    label: '클릭 후 7일 또는 참여한 조회 후 1일',
                    value: 'CLICK_THROUGH_7_AND_ENGAGED_VIDEO_VIEW_1',
                },
                CLICK_THROUGH_7_AND_VIEW_THROUGH_1_AND_ENGAGED_VIDEO_VIEW_1: {
                    label: '클릭 후 7일, 조회 후 1일 또는 참여한 조회 후 1일',
                    value: 'CLICK_THROUGH_7_AND_VIEW_THROUGH_1_AND_ENGAGED_VIDEO_VIEW_1',
                },
                CLICK_THROUGH_7: {label: '7일간의 클릭수', value: 'CLICK_THROUGH_7'},
                SKADNETWORK: {label: 'Apple의 SKAdNetwork API에서', value: 'SKADNETWORK'},
            },
            /** 통화 */
            currency: {
                KRW: {value: 'KRW', label: 'KRW'},
                USD: {value: 'USD', label: 'USD'},
            },
            /** 게재 - 캠페인 */
            effectiveStatus: {
                ACTIVE: {label: '활성화', value: '활성화'},
                PAUSED: {label: '일시중지', value: '일시중지'},
                WITH_ISSUES: {label: '문제있음', value: '문제있음'},
            },
            /** 게재 - 광고세트 */
            adSetEffectiveStatus: {
                ACTIVE: {label: '활성화', value: '활성화'},
                CAMPAIGN_PAUSED: {label: '캠페인 중단됨', value: '캠페인 중단됨'},
                PAUSED: {label: '일시중지', value: '일시중지'},
                WITH_ISSUES: {label: '문제있음', value: '문제있음'},
            },
            adEffectiveStatus: {
                ACTIVE: {label: '활성화', value: '활성화'},
                PAUSED: {label: '일시중지', value: '일시중지'},
                ADSET_PAUSED: {label: '광고세트 중단됨', value: '광고세트 중단됨'},
                CAMPAIGN_PAUSED: {label: '캠페인 중단됨', value: '캠페인 중단됨'},
                DISAPPROVED: {label: '미승인', value: '미승인'},
                PENDING_REVIEW: {label: '검토 중', value: '검토 중'},
                WITH_ISSUES: {label: '이슈있음', value: '이슈있음'},
            },
            /** 캠페인 목표 */
            objective: {
                APP_INSTALLS: {value: 'APP_INSTALLS', label: '앱 설치'},
                BRAND_AWARENESS: {value: 'BRAND_AWARENESS', label: '브랜드 인지도'},
                CONVERSIONS: {value: 'CONVERSIONS', label: '전환'},
                EVENT_RESPONSES: {value: 'EVENT_RESPONSES', label: '이벤트 응답'},
                LEAD_GENERATION: {value: 'LEAD_GENERATION', label: '잠재 고객 확보'},
                LINK_CLICKS: {value: 'LINK_CLICKS', label: '링크 클릭'},
                MOBILE_APP_INSTALLS: {value: 'MOBILE_APP_INSTALLS', label: '모바일 앱 설치'},
                MESSAGES: {value: 'MESSAGES', label: '메시지'},
                OFFER_CLAIMS: {value: 'OFFER_CLAIMS', label: '쿠폰 발급'},
                PAGE_LIKES: {value: 'PAGE_LIKES', label: '페이지 좋아요'},
                POST_ENGAGEMENT: {value: 'POST_ENGAGEMENT', label: '게시물 참여'},
                PRODUCT_CATALOG_SALES: {value: 'PRODUCT_CATALOG_SALES', label: '카탈로그 판매'},
                REACH: {value: 'REACH', label: '도달'},
                STORE_VISITS: {value: 'STORE_VISITS', label: '매장 유입'},
                VIDEO_VIEWS: {value: 'VIDEO_VIEWS', label: '동영상 조회'},
                OUTCOME_APP_PROMOTION: {value: 'OUTCOME_APP_PROMOTION', label: '앱 홍보'},
                OUTCOME_ENGAGEMENT: {value: 'OUTCOME_ENGAGEMENT', label: '참여'},
                OUTCOME_TRAFFIC: {value: 'OUTCOME_TRAFFIC', label: '트래픽'},
                OUTCOME_AWARENESS: {value: 'OUTCOME_AWARENESS', label: '인지도'},
                OUTCOME_SALES: {value: 'OUTCOME_SALES', label: '판매'},
                OUTCOME_LEADS: {value: 'OUTCOME_LEADS', label: '잠재 고객'},
            },
            /** 트래픽 유도 위치 */
            destinationType: {
                ON_AD: {value: 'ON_AD', label: '인스턴트 양식(ON_AD)'},
                WEBSITE: {value: 'WEBSITE', label: '웹사이트(WEBSITE)'},
                APP: {value: 'APP', label: '앱(APP)'},
                MESSENGER: {value: 'MESSENGER', label: 'Messenger'},
                ON_REMAINDER: {value: 'ON_REMAINDER', label: '광고(ON_REMAINDER)'},
                ON_PAGE: {value: 'ON_PAGE', label: 'Facebook 페이지(ON_PAGE)'},
                PHONE_CALL: {value: 'PHONE_CALL', label: '통화(PHONE_CALL)'},
                ON_VIDEO: {value: 'ON_VIDEO', label: '광고(ON_VIDEO)'},
                ON_POST: {value: 'ON_POST', label: '광고(ON_POST)'},
                INSTAGRAM_DIRECT: {
                    value: 'INSTAGRAM_DIRECT',
                    label: 'Instagram Direct(INSTAGRAM_DIRECT)',
                },
                INSTAGRAM_PROFILE: {
                    value: 'INSTAGRAM_PROFILE',
                    label: 'Instagram 프로필(INSTAGRAM_PROFILE)',
                },
                UNDEFINED: {value: 'UNDEFINED', label: '정의할 수 없음'},
                APPLINKS_AUTOMATIC: {
                    value: 'APPLINKS_AUTOMATIC',
                    label: '앱이 자동으로 선택됨(APPLINKS_AUTOMATIC)',
                },
                FACEBOOK: {value: 'FACEBOOK', label: 'Facebook'},
                MESSAGING_INSTAGRAM_DIRECT_MESSENGER: {
                    value: 'MESSAGING_INSTAGRAM_DIRECT_MESSENGER',
                    label: '메시지 앱(MESSAGING_INSTAGRAM_DIRECT_MESSENGER)',
                },
                MESSAGING_INSTAGRAM_DIRECT_MESSENGER_WHATSAPP: {
                    value: 'MESSAGING_INSTAGRAM_DIRECT_MESSENGER_WHATSAPP',
                    label: '메시지 앱(MESSAGING_INSTAGRAM_DIRECT_MESSENGER_WHATSAPP)',
                },
                MESSAGING_INSTAGRAM_DIRECT_WHATSAPP: {
                    value: 'MESSAGING_INSTAGRAM_DIRECT_WHATSAPP',
                    label: '메시지 앱(MESSAGING_INSTAGRAM_DIRECT_WHATSAPP)',
                },
                MESSAGING_MESSENGER_WHATSAPP: {
                    value: 'MESSAGING_MESSENGER_WHATSAPP',
                    label: '메시지 앱(MESSAGING_MESSENGER_WHATSAPP)',
                },
                ON_EVENT: {value: 'ON_EVENT', label: '광고(ON_EVENT)'},
                SHOP_AUTOMATIC: {value: 'SHOP_AUTOMATIC', label: '상점(자동)(SHOP_AUTOMATIC)'},
                WHATSAPP: {value: 'WHATSAPP', label: 'Whatsapp'},
                LEAD_FORM_MESSENGER: {value: 'LEAD_FORM_MESSENGER', label: 'LEAD_FORM_MESSENGER'},
                ON_REMINDER: {value: 'ON_REMINDER', label: 'ON_REMINDER'},
            },
        },
    };
}
