import Code from './Code';

export default class GoogleCode extends Code {
    public static readonly data = {
        ////옵션선택용 //
        options: {
            ExplorerLevel: {
                campaign: {value: 'campaigns', label: '캠페인'},
                adgroup: {value: 'ad-groups', label: '광고그룹'},
                ad: {value: 'ads', label: '광고'},
            },
        },

        assets: {},

        type: {
            downlaodEntityType: {
                campaign: {value: 'GET0002', label: '캠페인', data: {path: 'campaign'}},
                adgroup: {value: 'GET0003', label: '광고그룹', data: {path: 'ad-group'}},
                ad: {value: 'GET0004', label: '광고', data: {path: 'ads'}},
                keyword: {value: 'GET0005', label: '검색 키워드', data: {path: 'search-keyword'}},
                negativeKeyword: {
                    value: 'GET0006',
                    label: '제외 검색 키워드',
                    data: {path: 'search-keyword'},
                },
            },
            // ON/OFF
            status: {
                on: {value: 'ON', label: 'ON'},
                off: {value: 'OFF', label: 'OFF'},
                delete: {value: '삭제됨', label: '삭제됨'},
            },
            // 광고 순환 게재
            adServingOptimizationStatus: {
                CONVERSION_OPTIMIZE: {value: 'CONVERSION_OPTIMIZE', label: 'CTR 전환수 최대화'},
                OPTIMIZE: {value: 'OPTIMIZE', label: 'CTR 최적화'},
                ROTATE: {value: 'ROTATE', label: '균등 로테이션 (90일 후 클릭 최적화)'},
                ROTATE_INDEFINITELY: {
                    value: 'ROTATE_INDEFINITELY',
                    label: '최적화 사용 안 함 (무제한 로테이션)',
                },
                UNAVAILABLE: {
                    value: 'UNAVAILABLE',
                    label: '사용 불가',
                },
                UNKNOWN: {value: 'UNKNOWN', label: '알 수 없음'},
                UNSPECIFIED: {value: 'UNSPECIFIED', label: '정의되지 않음	'},
            },
            // 입찰 전략 유형
            biddingStrategyType: {
                MANUAL_CPC_BY_ENHANCED: {
                    value: 'MANUAL_CPC_BY_ENHANCED',
                    label: 'CPC(향상된 입찰기능)',
                },
                MAXIMIZE_CONVERSION_VALUE_BY_ROAS: {
                    value: 'MAXIMIZE_CONVERSION_VALUE_BY_ROAS',
                    label: '전환 가치 극대화(타겟 광고 투자수익(ROAS))',
                },
                MAXIMIZE_CONVERSIONS_BY_TARGET_CPA: {
                    value: 'MAXIMIZE_CONVERSIONS_BY_TARGET_CPA',
                    label: '전환수 최대화(타겟 CPA)',
                },
                TARGET_CPV: {value: 'TARGET_CPV', label: '타겟 CPV'},
                COMMISSION: {value: 'COMMISSION', label: '수수료'},
                ENHANCED_CPC: {value: 'ENHANCED_CPC', label: '향상된 CPC'},
                FIXED_CPM: {value: 'FIXED_CPM', label: '고정 CPM'},
                MANUAL_CPA: {value: 'MANUAL_CPA', label: '수동 CPA'},
                MANUAL_CPC: {value: 'MANUAL_CPC', label: '수동 CPC'},
                MANUAL_CPM: {value: 'MANUAL_CPM', label: '조회 가능 CPM'},
                MANUAL_CPV: {value: 'MANUAL_CPV', label: '수동 CPV'},
                MAXIMIZE_CONVERSIONS: {value: 'MAXIMIZE_CONVERSIONS', label: '전환수 최대화'},
                MAXIMIZE_CONVERSION_VALUE: {
                    value: 'MAXIMIZE_CONVERSION_VALUE',
                    label: '전환 가치 극대화',
                },
                PERCENT_CPC: {value: 'PERCENT_CPC', label: 'CPC 비용'},
                TARGET_CPA: {value: 'TARGET_CPA', label: '타겟 CPA'},
                TARGET_CPM: {value: 'TARGET_CPM', label: '타겟 CPM'},
                TARGET_IMPRESSION_SHARE: {
                    value: 'TARGET_IMPRESSION_SHARE',
                    label: '타겟 노출 점유율',
                },
                TARGET_ROAS: {value: 'TARGET_ROAS', label: '타겟 ROAS'},
                TARGET_SPEND: {value: 'TARGET_SPEND', label: '클릭 최대화'},
                INVALID: {value: 'INVALID', label: '무효'},
                UNKNOWN: {value: 'UNKNOWN', label: '알 수 없음'},
                UNSPECIFIED: {value: 'UNSPECIFIED', label: '정의되지 않음'},
            },
            // 네트워크 설정
            networkSettings: {
                TARGET_GOOGLE_SEARCH: {value: 'TARGET_GOOGLE_SEARCH', label: '구글 검색'},
                TARGET_SEARCH_NETWORK: {value: 'TARGET_SEARCH_NETWORK', label: '검색 네트워크'},
                TARGET_CONTENT_NETWORK: {value: 'TARGET_CONTENT_NETWORK', label: '컨텐츠 네트워크'},
                TARGET_PARTNER_SEARCH_NETWORK: {
                    value: 'TARGET_PARTNER_SEARCH_NETWORK',
                    label: '파트너 검색 네트워크',
                },
                TARGET_YOUTUBE: {value: 'TARGET_YOUTUBE', label: '유튜브'},
                TARGET_GOOGLE_TV_NETWORK: {value: 'TARGET_GOOGLE_TV_NETWORK', label: '구글TV'},
            },

            // 타겟팅 최적화
            optimizedTargetingEnabled: {
                TRUE: {value: 'true', label: '설정'},
                FALSE: {value: 'false', label: '설정해제'},
            },

            /** 캠페인 유형 - 레벨별 노출제외 필터가 있음*/
            campaignType: {
                DEMAND_GEN: {value: 'DEMAND_GEN,DISCOVERY', label: '디맨드젠'},
                // DISCOVERY       : {value : 'DISCOVERY',         label : '디맨드젠'},
                DISPLAY: {value: 'DISPLAY', label: '디스플레이'},
                VIDEO: {value: 'VIDEO', label: '동영상'},
                HOTEL: {value: 'HOTEL', label: '호텔'}, //       data:{filter:['campaign','adgroup','ad']}},
                LOCAL: {value: 'LOCAL', label: '지역'}, //        data:{filter:['campaign','adgroup','ad']}},
                LOCAL_SERVICES: {value: 'LOCAL_SERVICES', label: '지역 서비스'}, //  data:{filter:['campaign','adgroup','ad']}},
                MULTI_CHANNEL: {value: 'MULTI_CHANNEL', label: '앱'},
                PERFORMANCE_MAX: {value: 'PERFORMANCE_MAX', label: '실적 최대화'}, //  data:{filter:['adgroup','ad']}},
                SEARCH: {value: 'SEARCH', label: '검색'},
                SHOPPING: {value: 'SHOPPING', label: '쇼핑'},
                SMART: {value: 'SMART', label: '스마트'}, //      data:{filter:['adgroup','ad']}},
                TRAVEL: {value: 'TRAVEL', label: '여행'}, //     data:{filter:['campaign','adgroup','ad']}},
                UNKNOWN: {value: 'UNKNOWN,UNRECOGNIZED', label: '알 수 없음'},
                // UNRECOGNIZED    : {value : 'UNRECOGNIZED',      label : '알 수 없음'},
                UNSPECIFIED: {value: 'UNSPECIFIED', label: '정의되지 않음'},
            },

            /** 캠페인 하위 유형 */
            campaignSubType: {
                UNSPECIFIED: {value: 'UNSPECIFIED,LOCAL_CAMPAIGN,SMART_CAMPAIGN', label: '표준'},
                // LOCAL_CAMPAIGN                      : {value : 'LOCAL_CAMPAIGN',                    label : '표준'},
                // SMART_CAMPAIGN                      : {value : 'SMART_CAMPAIGN',                    label : '표준'},
                APP_CAMPAIGN: {value: 'APP_CAMPAIGN', label: '앱 설치'},
                APP_CAMPAIGN_FOR_ENGAGEMENT: {
                    value: 'APP_CAMPAIGN_FOR_ENGAGEMENT',
                    label: '앱 참여',
                },
                APP_CAMPAIGN_FOR_PRE_REGISTRATION: {
                    value: 'APP_CAMPAIGN_FOR_PRE_REGISTRATION',
                    label: '앱 사전 등록',
                },
                DISPLAY_EXPRESS: {value: 'DISPLAY_EXPRESS', label: '디스플레이(EXPRESS)'},
                DISPLAY_GMAIL_AD: {value: 'DISPLAY_GMAIL_AD', label: 'Gmail'},
                DISPLAY_MOBILE_APP: {
                    value: 'DISPLAY_MOBILE_APP,SEARCH_MOBILE_APP',
                    label: '앱 설치',
                },
                // SEARCH_MOBILE_APP                   : {value : 'SEARCH_MOBILE_APP',                 label : '앱 설치'},
                DISPLAY_SMART_CAMPAIGN: {
                    value: 'DISPLAY_SMART_CAMPAIGN,SHOPPING_SMART_ADS',
                    label: '스마트',
                },
                // SHOPPING_SMART_ADS                  : {value : 'SHOPPING_SMART_ADS',                label : '스마트'},
                SEARCH_EXPRESS: {value: 'SEARCH_EXPRESS', label: '검색(EXPRESS)'},
                SHOPPING_COMPARISON_LISTING_ADS: {
                    value: 'SHOPPING_COMPARISON_LISTING_ADS',
                    label: '비교 등록 정보',
                },
                TRAVEL_ACTIVITIES: {value: 'TRAVEL_ACTIVITIES', label: '여행 활동'},
                VIDEO_ACTION: {value: 'VIDEO_ACTION', label: '전환 유도'},
                VIDEO_NON_SKIPPABLE: {value: 'VIDEO_NON_SKIPPABLE', label: '건너뛸 수 없음'},
                VIDEO_OUTSTREAM: {value: 'VIDEO_OUTSTREAM', label: '아웃스트림'},
                VIDEO_REACH_TARGET_FREQUENCY: {
                    value: 'VIDEO_REACH_TARGET_FREQUENCY',
                    label: '타겟 게재빈도',
                },
                VIDEO_SEQUENCE: {value: 'VIDEO_SEQUENCE', label: '시퀀스'},
                UNKNOWN: {value: 'UNKNOWN,null', label: '알 수 없음'},
                // null                                : {value : 'null',                              label : '알 수 없음'},
            },

            adgroupType: {
                UNSPECIFIED: {value: 'UNSPECIFIED', label: '정의되지 않음'},
                UNKNOWN: {value: 'UNKNOWN,UNRECOGNIZED,null', label: '알 수 없음'},
                // UNRECOGNIZED                    : {value : 'UNRECOGNIZED',                      label : '알 수 없음'},
                // null                            : {value : 'null',                              label : '알 수 없음'},
                SEARCH_STANDARD: {value: 'SEARCH_STANDARD', label: '일반'},
                SEARCH_DYNAMIC_ADS: {value: 'SEARCH_DYNAMIC_ADS', label: '동적'},
                DISPLAY_STANDARD: {value: 'DISPLAY_STANDARD', label: '디스플레이'},
                SHOPPING_PRODUCT_ADS: {value: 'SHOPPING_PRODUCT_ADS', label: '일반 쇼핑 - 제품'},
                HOTEL_ADS: {value: 'HOTEL_ADS', label: '호텔'},
                SHOPPING_SMART_ADS: {value: 'SHOPPING_SMART_ADS', label: '스마트 쇼핑 - 제품'},
                VIDEO_BUMPER: {value: 'VIDEO_BUMPER', label: '범퍼'},
                VIDEO_TRUE_VIEW_IN_STREAM: {
                    value: 'VIDEO_TRUE_VIEW_IN_STREAM',
                    label: '건너뛸 수 있는 인스트림',
                },
                VIDEO_TRUE_VIEW_IN_DISPLAY: {
                    value: 'VIDEO_TRUE_VIEW_IN_DISPLAY',
                    label: '인피드 광고 동영상',
                },
                VIDEO_NON_SKIPPABLE_IN_STREAM: {
                    value: 'VIDEO_NON_SKIPPABLE_IN_STREAM',
                    label: '건너뛸 수 없는 인스트림',
                },
                VIDEO_OUTSTREAM: {value: 'VIDEO_OUTSTREAM', label: '아웃스트림'},
                SHOPPING_COMPARISON_LISTING_ADS: {
                    value: 'SHOPPING_COMPARISON_LISTING_ADS',
                    label: '비교 등록 정보',
                },
                PROMOTED_HOTEL_ADS: {value: 'PROMOTED_HOTEL_ADS', label: '호텔 프로모션'},
                VIDEO_RESPONSIVE: {value: 'VIDEO_RESPONSIVE', label: '반응형 동영상 광고'},
                VIDEO_EFFICIENT_REACH: {
                    value: 'VIDEO_EFFICIENT_REACH',
                    label: '효율적 잠재고객 도달',
                },
                SMART_CAMPAIGN_ADS: {value: 'SMART_CAMPAIGN_ADS', label: '스마트 광고그룹'},
                TRAVEL_ADS: {value: 'TRAVEL_ADS', label: 'TRAVEL_ADS(여행)'},
            },

            /** 광고유형 */
            adType: {
                UNSPECIFIED: {value: 'UNSPECIFIED', label: '정의되지 않음'},
                UNKNOWN: {value: 'UNKNOWN,UNRECOGNIZED,null', label: '알 수 없음'},
                // UNRECOGNIZED                    : {value : 'UNRECOGNIZED',                      label : '알 수 없음'},
                // null                            : {value : 'null',                              label : '알 수 없음'},
                TEXT_AD: {value: 'TEXT_AD', label: '텍스트 광고'},
                EXPANDED_TEXT_AD: {value: 'EXPANDED_TEXT_AD', label: '확장형 텍스트 광고'},
                RESPONSIVE_SEARCH_AD: {value: 'RESPONSIVE_SEARCH_AD', label: '반응형 검색 광고'},
                EXPANDED_DYNAMIC_SEARCH_AD: {
                    value: 'EXPANDED_DYNAMIC_SEARCH_AD',
                    label: '확장 동적 검색 광고',
                },
                HOTEL_AD: {value: 'HOTEL_AD', label: '호텔 광고'},
                SHOPPING_SMART_AD: {value: 'SHOPPING_SMART_AD', label: '스마트 쇼핑 광고'},
                SHOPPING_PRODUCT_AD: {value: 'SHOPPING_PRODUCT_AD', label: '제품 광고'},
                SHOPPING_COMPARISON_LISTING_AD: {
                    value: 'SHOPPING_COMPARISON_LISTING_AD',
                    label: '비교 등록 정보 광고',
                },
                IMAGE_AD: {value: 'IMAGE_AD', label: '이미지 광고'},
                APP_AD: {value: 'APP_AD', label: '모바일 앱 설치 광고 v2'},
                LEGACY_APP_INSTALL_AD: {
                    value: 'LEGACY_APP_INSTALL_AD',
                    label: '모바일 앱 설치 광고',
                },
                APP_ENGAGEMENT_AD: {value: 'APP_ENGAGEMENT_AD', label: '모바일 앱 재참여 광고 v2'},
                APP_PRE_REGISTRATION_AD: {
                    value: 'APP_PRE_REGISTRATION_AD',
                    label: '모바일 앱 사전 등록 광고',
                },
                LOCAL_AD: {value: 'LOCAL_AD', label: '지역 광고'},
                HTML5_UPLOAD_AD: {value: 'HTML5_UPLOAD_AD', label: 'HTML5 광고'},
                DYNAMIC_HTML5_AD: {value: 'DYNAMIC_HTML5_AD', label: '디스플레이 동적 HTML5 광고'},
                LEGACY_RESPONSIVE_DISPLAY_AD: {
                    value: 'LEGACY_RESPONSIVE_DISPLAY_AD',
                    label: '반응형 광고',
                },
                RESPONSIVE_DISPLAY_AD: {
                    value: 'RESPONSIVE_DISPLAY_AD',
                    label: '반응형 디스플레이 광고',
                },
                VIDEO_AD: {value: 'VIDEO_AD', label: '동영상 광고'},
                VIDEO_BUMPER_AD: {value: 'VIDEO_BUMPER_AD', label: '범퍼 광고'},
                VIDEO_NON_SKIPPABLE_IN_STREAM_AD: {
                    value: 'VIDEO_NON_SKIPPABLE_IN_STREAM_AD',
                    label: '건너뛸 수 없는 인스트림 광고',
                },
                VIDEO_TRUEVIEW_IN_STREAM_AD: {
                    value: 'VIDEO_TRUEVIEW_IN_STREAM_AD',
                    label: '건너뛸 수 있는 인스트림 광고',
                },
                VIDEO_OUTSTREAM_AD: {value: 'VIDEO_OUTSTREAM_AD', label: '아웃스트림 광고'},
                VIDEO_RESPONSIVE_AD: {value: 'VIDEO_RESPONSIVE_AD', label: '반응형 동영상 광고'},
                VIDEO_TRUEVIEW_DISCOVERY_AD: {
                    value: 'VIDEO_TRUEVIEW_DISCOVERY_AD,IN_FEED_VIDEO_AD',
                    label: '인피드 동영상 광고',
                },
                // IN_FEED_VIDEO_AD                : {value : 'IN_FEED_VIDEO_AD',                  label : '인피드 동영상 광고'},
                SMART_CAMPAIGN_AD: {value: 'SMART_CAMPAIGN_AD', label: '스마트 광고'},
                CALL_AD: {value: 'CALL_AD', label: '통화 광고'},
                DEMAND_GEN_MULTI_ASSET_AD: {
                    value: 'DEMAND_GEN_MULTI_ASSET_AD,DISCOVERY_MULTI_ASSET_AD',
                    label: '디맨드젠 이미지 광고',
                },
                // DISCOVERY_MULTI_ASSET_AD        : {value : 'DISCOVERY_MULTI_ASSET_AD',          label : '디맨드젠 이미지 광고'},
                DEMAND_GEN_CAROUSEL_AD: {
                    value: 'DEMAND_GEN_CAROUSEL_AD,DISCOVERY_CAROUSEL_AD',
                    label: '디맨드젠 캐러셀 광고',
                },
                // DISCOVERY_CAROUSEL_AD           : {value : 'DISCOVERY_CAROUSEL_AD',             label : '디맨드젠 캐러셀 광고'},
                DEMAND_GEN_VIDEO_RESPONSIVE_AD: {
                    value: 'DEMAND_GEN_VIDEO_RESPONSIVE_AD,DISCOVERY_VIDEO_RESPONSIVE_AD',
                    label: '디맨드젠 동영상 광고',
                },
                // DISCOVERY_VIDEO_RESPONSIVE_AD   : {value : 'DISCOVERY_VIDEO_RESPONSIVE_AD',     label : '디맨드젠 동영상 광고'},
                DEMAND_GEN_PRODUCT_AD: {
                    value: 'DEMAND_GEN_PRODUCT_AD',
                    label: '디맨드젠 제품 광고',
                },
                TRAVEL_AD: {value: 'TRAVEL_AD', label: 'TRAVEL_ADS(여행)'},
            },

            // 수준
            searchKeywordLevel: {
                campaign: {value: '캠페인', label: '캠페인'},
                adGroup: {value: '광고그룹', label: '광고그룹'},
            },
            // 검색 유형
            matchType: {
                BROAD: {value: 'BROAD', label: '확장'},
                EXACT: {value: 'EXACT', label: '일치'},
                PHRASE: {value: 'PHRASE', label: '구문'},
                UNKNOWN: {value: 'UNKNOWN', label: '알 수 없음'},
                UNSPECIFIED: {value: 'UNSPECIFIED', label: '정의되지 않음'},
            },
            /** 게재 사유 */
            primaryStatusReasons: {
                UNSPECIFIED: {value: 'UNSPECIFIED', label: '지정되지 않음'},
                UNKNOWN: {value: 'UNKNOWN', label: '알 수 없음'},
                CAMPAIGN_PENDING: {value: 'CAMPAIGN_PENDING', label: '캠페인 보류'},
                CAMPAIGN_CRITERION_NEGATIVE: {
                    value: 'CAMPAIGN_CRITERION_NEGATIVE',
                    label: '광고그룹 기준이 제외 캠페인 기준보다 우선함',
                },
                CAMPAIGN_PAUSED: {value: 'CAMPAIGN_PAUSED', label: '캠페인 일시중지'},
                CAMPAIGN_REMOVED: {value: 'CAMPAIGN_REMOVED', label: '캠페인 삭제됨'},
                CAMPAIGN_ENDED: {value: 'CAMPAIGN_ENDED', label: '캠페인 중단됨'},
                AD_GROUP_PAUSED: {value: 'AD_GROUP_PAUSED', label: '광고그룹 일시중지'},
                AD_GROUP_REMOVED: {value: 'AD_GROUP_REMOVED', label: '광고그룹 삭제됨'},
                AD_GROUP_CRITERION_DISAPPROVED: {
                    value: 'AD_GROUP_CRITERION_DISAPPROVED',
                    label: '광고그룹 기준 비승인',
                },
                AD_GROUP_CRITERION_RARELY_SERVED: {
                    value: 'AD_GROUP_CRITERION_RARELY_SERVED',
                    label: '광고 그룹 기준이 거의 게재되지 않음',
                },
                AD_GROUP_CRITERION_LOW_QUALITY: {
                    value: 'AD_GROUP_CRITERION_LOW_QUALITY',
                    label: '광고 그룹 기준 품질평가점수 낮음',
                },
                AD_GROUP_CRITERION_UNDER_REVIEW: {
                    value: 'AD_GROUP_CRITERION_UNDER_REVIEW',
                    label: '광고그룹 기준 검토 중',
                },
                AD_GROUP_CRITERION_PENDING_REVIEW: {
                    value: 'AD_GROUP_CRITERION_PENDING_REVIEW',
                    label: '광고 그룹 기준 검토 대기 중',
                },
                AD_GROUP_CRITERION_BELOW_FIRST_PAGE_BID: {
                    value: 'AD_GROUP_CRITERION_BELOW_FIRST_PAGE_BID',
                    label: '첫 페이지 게재 입찰가보다 낮음',
                },
                AD_GROUP_CRITERION_NEGATIVE: {
                    value: 'AD_GROUP_CRITERION_NEGATIVE',
                    label: '광고그룹 기준 제외',
                },
                AD_GROUP_CRITERION_RESTRICTED: {
                    value: 'AD_GROUP_CRITERION_RESTRICTED',
                    label: '광고그룹 기준 제한',
                },
                AD_GROUP_CRITERION_PAUSED: {
                    value: 'AD_GROUP_CRITERION_PAUSED',
                    label: '광고 그룹 기준 일시중지',
                },
                AD_GROUP_CRITERION_PAUSED_DUE_TO_LOW_ACTIVITY: {
                    value: 'AD_GROUP_CRITERION_PAUSED_DUE_TO_LOW_ACTIVITY',
                    label: '게재 활동이 장기간 저조하여 광고 그룹 기준 일시중지',
                },
                AD_GROUP_CRITERION_REMOVED: {
                    value: 'AD_GROUP_CRITERION_REMOVED',
                    label: '광고그룹 기준 삭제',
                },
            },
        },
    };
}
