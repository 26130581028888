import Code from "./Code";

export default class AdlyAiCode extends Code{

    public static readonly data = {
        //DB코드 데이터
        type:{
            entryType : {
                entity    : {value : 'AIET0001', label : '광고 요소'},
                list      : {value : 'AIET0002', label : '조회 목록'},
                dashboard : {value : 'AIET0003', label : '인사이트 대시보드'},
            },

            status : {
                success     : {value : 'AIRS0001', label : '성공'},
                processing  : {value : 'AIRS0002', label : '처리중'},
                fail        : {value : 'AIRS0003', label : '실패'},
                error       : {value : 'AIRS0004', label : '에러'},
            }
        },

        Assets:{
        }

    };
};

