import {useState} from 'react';
import {GfaService} from '../services';
import {AdGroupSimpleResType} from '../services/models/gfa/AdGroupTypes';
import {CampaignSimpleResType} from '../services/models/gfa/CampaignTypes';
import {MediaResResultType, TreeResDataType} from '../services/models/ResponseTypes';

export type GfaTreeResType = TreeResDataType<CampaignSimpleResType, AdGroupSimpleResType>;

export interface GfaTreeStateType {
    set: (data: GfaTreeResType[]) => void;
    load: () => Promise<GfaTreeResType[]>;
    data: GfaTreeResType[];
}

const GfaTreeStorage = class {
    public state = (): GfaTreeStateType => {
        const [data, setData] = useState<GfaTreeResType[]>([]);
        const load = async () => {
            return this.load().then((data) => {
                setData(data);
                return data;
            });
        };
        const set = (data: GfaTreeResType[]) => {
            setData(data);
        };
        return {set, load, data};
    };

    /** 데이터 로딩 */
    private async load(): Promise<GfaTreeResType[]> {
        const campaign: CampaignSimpleResType[] = await GfaService.campaign
            .getSimpleList<MediaResResultType<CampaignSimpleResType>>()
            .then((res) => {
                const data: CampaignSimpleResType[] = res.data.result || [];
                return data.sort((a, b) => (a.campaignName < b.campaignName ? -1 : 1));
            });

        const adgroup: AdGroupSimpleResType[] = await GfaService.adgroup
            .getSimpleList<MediaResResultType<AdGroupSimpleResType>>()
            .then((res) => {
                const data: AdGroupSimpleResType[] = res.data.result || [];
                return data.sort((a, b) => (a.adsetName < b.adsetName ? -1 : 1));
            });

        const data: GfaTreeResType[] = campaign.map((v) => ({
            data: v,
            children: adgroup.filter((v2) => v.campaignId === v2.campaignId),
        }));
        return data;
    }
};

export default GfaTreeStorage;
