import { AdlyAiServer } from '../../axios';
import Utils from '../../utils/Utils';
import { RequestSearchType } from '../models/RequestTypes';
import { MediaResponseType } from '../models/ResponseTypes';
import HelperComponent from './HelperComponent';

/** Helper */
export default class AppHelper extends HelperComponent{
    static path="/app/info"; //기본 Path

    //표준 목록조회
    public static getList<T = any>(params: RequestSearchType) {
        return (
            Utils.apiRequestValidation({}) ||
            AdlyAiServer.get<MediaResponseType<T>>("/app/list", {
                params: params,
                headers: {useAccount: false},
            })
        );
    }
    
}
