import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { useUserContext } from '../../contexts/UserContext';
import Code from '../../modules/code/Code';
import { IconAi } from '../../modules/icon';
import { AiExplorerLayer } from '../ai-exproler/AiExplorerLayer';
import { ExplorerLayer } from './ExplorerLayer';


const ExplorerDrawer:FC = ()=>{
    const userContext = useUserContext();
    return (<>
        {/* begin::explorer drawer */}
        <div
            id='kt_engage_explorer'
            className='bg-body'
            data-kt-drawer='true'
            data-kt-drawer-name='explore'
            data-kt-drawer-activate='true'
            data-kt-drawer-overlay='true'
            data-kt-drawer-width="{default:'350px', 'lg': '800px'}"
            data-kt-drawer-direction='end'
            data-kt-drawer-toggle='#kt_engage_explorer_toggle'
            data-kt-drawer-close='#kt_engage_explorer_close'
        >
            <Buttons />

            {/* begin::Card  */}
            <div className='card shadow-none w-100'>
                <div style={{display: userContext.explorerStorage.data.selectTab === Code.base.explorerTabType.aiExplorer.value ? 'block' : 'none'}}><AiExplorerLayer/></div>
                <div style={{display: userContext.explorerStorage.data.selectTab === Code.base.explorerTabType.explorer.value ? 'block' : 'none'}}><ExplorerLayer /></div>
            </div>
            {/* begin::Card */}
        </div>
        {/* end::explorer drawer */}
    </>)
}

export { ExplorerDrawer };


const Buttons:FC = ()=>{
    const [color, setColor] = useState<'white'|undefined>();
    const [tab, setTab] = useState<string>('');
    const userContext = useUserContext();
    
    const onChangeTab = (tab:string)=>{
        const data = userContext.explorerStorage.data;
        if(data){
            data.selectTab = tab;
            userContext.explorerStorage.set(data);
        }
    }

    useEffect(()=>{
        const data = userContext.explorerStorage.data;
        if(data){
            setTab(data.selectTab || '');
        }
    }, [userContext.explorerStorage.data?.selectTab]);

    return <div className={'d-flex px-5 fw-bolder transform-90 gap-0'} style={{position:'fixed', right:"800px", top:'200px', }}>
        <button
            className={clsx(
                'btn btn-flex h-35px btn-light-info bg-hover-info bg-active-info btn-active-color-white shadow-sm fs-6 px-4 rounded-top-0',
                tab === Code.base.explorerTabType.aiExplorer.value ? 'active' : ''
            )}
            data-bs-trigger='hover'
            onMouseOver={()=>{setColor('white')}}
            onMouseOut={()=>{setColor(undefined)}}
            onClick={()=>{ onChangeTab(Code.base.explorerTabType.aiExplorer.value); }}
        >
            <IconAi
                color={tab === Code.base.explorerTabType.aiExplorer.value || color=='white' ? 'white' : undefined} 
                className='me-2' style={{transform: 'rotate(-90deg)'}} 
            />
            <span>adly AI</span>
        </button>

        <button
            className={clsx(
                'btn btn-flex h-35px btn-light-primary bg-hover-primary bg-active-primary btn-active-color-white shadow-sm fs-6 px-4 rounded-top-0',
                tab === Code.base.explorerTabType.explorer.value ? 'active' : ''
            )}
            onClick={()=>{onChangeTab(Code.base.explorerTabType.explorer.value); }}
        >
            <span>Explorer</span>
        </button>

    </div>
}