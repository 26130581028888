import { useState } from "react";
import { HeaderColumn } from "../../pages/modules/table/TableType";
import { EntitySimpleType } from "../services/models/PublicTypes";

export interface ExplorerStorageType{
    level?: string;
    value?: string;
    media?: string;
    /** 실제 level과 stat호출 레벨이 다른 경우 지정 - 디멘전 */
    statLevel?:string;
    row?: any;

    type?: string;

    /** 클라이언트 아이디 */
    clientIds?: {mediaType:string, clientId:string}[];

    colums?: HeaderColumn[];

    /** 선택시마다 화면 초기화 처리하기 위한 값 */
    updateTime?: number;

    /** 탭선택 정보 */
    selectTab?: string;
    /** 선택 항목에 대한 데이터 - ai Explorer에서 사용 */
    entityData?: any;
    entityTargetList?: EntitySimpleType[];
    /** 현재 조회페이지에 대한 데이터 - ai Explorer에서 사용 */
    pageData?: any[];
    pageTargetList?: EntitySimpleType[];
}

export interface ExplorerStateType{
    set: (data:ExplorerStorageType)=>void;
    data: ExplorerStorageType;
    setPageData:(arr:any[])=>void;
}

const ExplorerStorage = class {
    public state = ():ExplorerStateType=>{
        const [useData, setData] = useState<ExplorerStorageType>({pageData:[]});
        const set = (data:ExplorerStorageType)=>{ data.updateTime = new Date().getTime(); setData({...data}); }
        const setPageData = (data:any[])=>{  useData.updateTime = new Date().getTime(); useData.pageData = data;  setData({...useData}); }
        return {set, data:useData, setPageData};
    }
}

export default ExplorerStorage;