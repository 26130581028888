/* eslint-disable jsx-a11y/anchor-is-valid */
import ApexCharts, { ApexOptions } from 'apexcharts';
import { format } from 'date-fns';
import { useEffect, useRef } from 'react';
import { getCSS } from '../../../../_metronic/assets/ts/_utils';
import { ChartsProps } from './Types';


const BarLineCharts2:React.FC<ChartsProps> = (props) => {
    const chartRef = useRef<HTMLDivElement | null>(null);
    const refreshChart = () => {
        if (!chartRef.current) { return; }
        const height = parseInt(getCSS(chartRef.current, 'height'));
        const chart = new ApexCharts(chartRef.current, getChartOptions(height, props));
        if (chart) { chart.render(); }
        return chart;
    }

    useEffect(() => {
        const chart = refreshChart();
        return () => { chart && chart.destroy(); }
    // eslint-disable-next-line
    }, [chartRef, props.data]);

    return <div ref={chartRef} className={props.className} style={props.style} />
}

export default BarLineCharts2;

const getChartOptions = (height: number, props:ChartsProps): ApexOptions => {
    const labelColor = 'var(--bs-gray-500)';
    const borderColor = 'var(--bs-gray-400)';
    // const dataCount = Math.max(props.barChart.data.length, props.lineChart.data.length);
    // console.log(dataCount, dataCount.gcd(5));
    // console.log(dataCount, dataCount.gcd(6));
    // console.log(dataCount, dataCount.gcd(7));
    // console.log(dataCount, dataCount.gcd(8));
    // console.log(dataCount, dataCount.gcd(9));
    // const tickAmount = Math.min( Math.max(props.barChart.data.length, props.lineChart.data.length), 10);

    return {
        series: props.data || [],
        chart: {
            // fontFamily: 'inherit',
            stacked: true,
            height: height || 350,
            toolbar: { show: false, offsetX:0, offsetY:0, },
            zoom: { enabled: true },
            background: "transparent",
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 2,
                columnWidth: '30%',
                rangeBarOverlap: true,
                rangeBarGroupRows: false,
                dataLabels: { hideOverflowingLabels: true }
            },
        },
        legend: { show: true, position: 'bottom', offsetY: 0 },//범례
        dataLabels: {
            enabled: false,
            enabledOnSeries: undefined,
            // formatter: (v:string|number|number[], opts):string=>`${v} 회`,
            textAnchor: 'middle',
            distributed: false,
            offsetX: 0,
            offsetY: 0,
            style: {
                // fontSize: '1em',
                // fontFamily: 'Helvetica, Arial, sans-serif',
                // fontWeight: 'bold',
                // colors: undefined
            },
            background: {
                enabled: true,
                foreColor: '#fff',
                padding: 4,
                borderRadius: 2,
                borderWidth: 1,
                borderColor: '#fff',
                opacity: 0.9,
                dropShadow: {
                    enabled: false,
                    top: 1,
                    left: 1,
                    blur: 1,
                    color: '#000',
                    opacity: 0.45
                }
            },
            dropShadow: {
                enabled: false,
                top: 1,
                left: 1,
                blur: 1,
                color: '#000',
                opacity: 0.45
            }
        },
        stroke: { 
            curve: 'straight',
            lineCap: undefined,
            show: true,
            width: 3,
        },
        fill: props.data?.find((v)=>v.type === 'area') ? { opacity:0.2} : {},
        markers: {
            size: 0,
            colors: 'var(--bs-gray-600)',
            strokeColors: 'var(--bs-gray-600)',
            strokeWidth: 3,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [],
            shape: "circle",
            radius: 2,
            offsetX: 0,
            offsetY: 0,
            onClick: undefined,
            onDblClick: undefined,
            showNullDataPoints: true,
            hover: { size: 4, sizeOffset: 2 }
        },
        xaxis: {
            categories: props.categories || [],
            axisBorder: { show: false, },
            axisTicks: {
                show: true,
                // borderType?: string
                // color?: string
                // height?: number
                // offsetX?: number
                // offsetY?: number
            },
            type: props.type, // 'datetime',
            // min: startDate.getTime(),
            // max: addDays(endDate,-1).getTime(),
            // tickAmount: differenceInDays(endDate, startDate) > 10 ? 10 : 'dataPoints', //좌표 표시갯수
            // tickAmount: 'dataPoints',//tickAmount >= 10 ? tickAmount : 'dataPoints',   //tick 제한을 하면 날짜 포인트가 틀어짐
            // tickAmount: 2,
            labels: {
                /**
                * Allows users to apply a custom formatter function to x-axis labels.
                *
                * @param { String } value - The default value generated
                * @param { Number } timestamp - In a datetime series, this is the raw timestamp 
                * @param { object } contains dateFormatter for datetime x-axis
                */
                // formatter:(value:string, timestamp:number)=>{
                //     const time = timestamp-(9 * 3600 * 1000);
                //     console.log(format(time, "MM/dd HH:mm:ss"));
                //     // if(format(time, "MM/dd HH:mm:ss") !== format(time, "MM/dd 00:00:00")){ return ''; }
                //     return format(time, "MM/dd");
                // },  //실제 타임하고 안맞음
                format: 'MM/dd',
                style: { colors: labelColor, fontSize:'0.9em' },
                showDuplicates: false,
            },
            tooltip: { enabled: false, }
        },
        yaxis: [
            {
                labels: {
                    style: { colors: 'var(--bs-gray-600)', fontSize: '0.9em', },
                    // offsetX: -15,
                    formatter: (v)=>v.round(1).addComma(),
                },
                title:{
                    // text: `${props.barChart.title} ${props.barChart.unit ? `(${props.barChart.unit})` : ''}`,
                    // offsetX:-10,
                    style:{ color: 'var(--bs-gray-600)', fontSize: '1em' }
                },
                tickAmount: 5, //좌표 표시갯수
            },
            {
                labels: {
                    style: { colors: 'var(--bs-gray-600)', fontSize: '0.9em',},
                    // offsetX: 10 ,
                    formatter: (v)=>v.round(1).addComma(),
                },
                opposite:true,
                title:{
                    // text: `${props.lineChart.title} ${props.lineChart.unit ? `(${props.lineChart.unit})` : ''}`,
                    // offsetX:10,
                    style:{ color: 'var(--bs-gray-600)', fontSize: '1em' },
                },
                tickAmount: 5
            }
        ],
        states: {
            normal: { filter: { type: 'lighten',  value: 0.03,} }, //'lighten', 'darken', 'none'
            hover: { filter: { type: 'lighten', value: 0.15, } },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: { type: 'darken', value: 0.5, }
            },
        },
        tooltip: {
            // custom: ({ seriesIndex, dataPointIndex, w }) => {
            //   const point = w.config.series[seriesIndex].data[dataPointIndex];
            //   return `<div class="fs-7 border border-gray-300" style="border-radius: 5px; box-shadow: 2px 2px 5px rgba(0,0,0,0.1);">
            //             <div class="p-1 px-3 bg-gray-200 fw-bold border-bottom border-gray-300">${point.date}</div>
            //             <div class="p-1 px-3">
            //                 ${name[0]}: ${(point.x as number|undefined)?.toString().addComma()}<br/>
            //                 ${name[1]}: ${(point.y as number|undefined)?.toString().addComma()}
            //             </div>
            //           </div>`;
            // },
            style: { fontSize: '0.9em', },
            x: {
                show:true,
                formatter: (v,opts)=>props.categories ? props.categories[v-1] : format(v, "yyyy-MM-dd"),
            },
            y: [
                // { formatter: (v)=>`${v.addComma()} ${props.barChart.unit ? `(${props.barChart.unit})` : ''}`, },
                // { formatter: (v)=>`${v.addComma()} ${props.lineChart.unit ? `(${props.lineChart.unit})` : ''}`, }
            ],
            followCursor:true
        },
        grid: {
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: { lines: { show: true, }, },
            padding: { top: 0, bottom: 20, right:20, left: 20, },
        },
    }
}
