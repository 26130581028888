import { ButtonProps } from "react-bootstrap";

interface ThemeConfigType{
    Button:{
        Sub:ButtonProps,
    }
}

const ThemeConfig:ThemeConfigType = {
    Button : {
        Sub: {
            variant:'light-dark',
            size:'sm',
            className:'me-1 btn-outline',
        },
    },
}

export class ThemeControll{
    /** Stat 관련 표시여부 - Explorer버튼, 기간선택 */
    public static isStatShow = ()=>{
        const list:string[] = [
            '^/pages/naver/bizchannel$',
            '^/pages/naver/powerlink/.*$',
            '^/pages/naver/shopping/.*$',
            '^/pages/kakao/keywordad/.*$',
            // '^/pages/naver/d-bid/result$',
            // '^/popup/naver/d-bid/task-result$',

            '^/pages/gfa/all/.*$',
            '^/pages/nosp/all/.*$',
            '^/pages/kakaomoment/all/.*$',
            '^/pages/google/all/.*$',
            '^/pages/meta/all/.*$',

            //tree 모드
            '^/pages/naver/tree/.*$',
            '^/pages/kakao/tree/.*$',

            '^/pages/gfa/tree/.*$',
            '^/pages/nosp/tree/.*$',
            '^/pages/kakaomoment/tree/.*$',
            '^/pages/google/tree/.*$',
            '^/pages/meta/tree/.*$',

            //레이블 모드
            '^/pages/naver/label/[0-9]{1,2}$',
            '^/pages/kakao/label/[0-9]{1,2}$',

            '^/pages/gfa/label/[0-9]{1,2}$',
            '^/pages/nosp/label/[0-9]{1,2}$',
            '^/pages/kakaomoment/label/[0-9]{1,2}$',
            '^/pages/google/label/[0-9]{1,2}$',
            '^/pages/meta/label/[0-9]{1,2}$',
        ];
        for(let i=0; i<list.length; i++){
            //if(window.location.pathname.wildcardMatch(list[i])){ return true; } //특정 path에서는 날짜선택 창을 보이지 않습니다. // 단순 '*' 방식
            if(new RegExp(list[i]).test(window.location.pathname)){ return true; } //특정 path에서는 날짜선택 창을 보이지 않습니다.    //정규식 방식
        }

        // Google > 검색 키워드
        // if (window.location.pathname === '/pages/google/searchKeyword') {
        //     // 제외 키워드는 날짜 X
        //     if (window.location.hash === '#exceptKeyword') {
        //         return false
        //     }
        //     // 검색 키워드는 날짜 O
        //     return true
        // }
        return false;
    }
}

export default ThemeConfig;