import clsx from 'clsx';
import React, {CSSProperties} from 'react';
import {Table} from 'react-bootstrap';
import UserSelect from '../../../common/helpers/UserSelect';
import {StatReportInfoType} from '../../../common/services/models/meta/StatReportType';
import MetaCode from '../../../modules/code/MetaCode';
import ExplorerListDetail from './ExplorerListDetail';
import {convertStatInfoViewData} from './StatInfoData';
import styles from './explorer.module.scss';

export interface ItemsProps {
    index?: number;
    data: StatReportInfoType;
    media?: string;
    level?: string;
    search?: string;
    show?: boolean;
    isDetail?: boolean;
    setSelect?: (id: string) => void;
}

export const Items: React.FC<ItemsProps> = (props) => {
    const Item = getItemTag(props);
    const id: string | undefined = getItemId(props.level, props.data);
    if (!Item) {
        return <>Unknown {props.level}</>;
    }
    return (
        <>
            {props.show && <DetailWindow level={props.level} search={id} />}
            <div
                className='pt-5'
                style={{
                    overflowY: 'scroll',
                    maxHeight: 'calc(100vh - 276px)',
                    marginRight: '-10px',
                    paddingRight: '10px',
                }}
            >
                {!props.isDetail && (
                    <>
                        {props.index !== undefined && (
                            <span className='me-3 fw-bold'>{props.index + 1}</span>
                        )}
                        <Item {...props} />
                    </>
                )}
                <Viewer {...props} />
                {props.show && (
                    <ExplorerListDetail {...props} id={getItemId(props.level, props.data)} />
                )}
            </div>
        </>
    );
};

export const ItemsCampaign: React.FC<ItemsProps> = (props) => {
    return (
        <SearchHighlight
            className={styles.link}
            text={props.data.campaignName}
            search={props.search}
            onClick={() => {
                props.setSelect && props.setSelect(props.data.campaignId || '');
            }}
        />
    );
};

export const ItemsAdGroup: React.FC<ItemsProps> = (props) => {
    return (
        <SearchHighlight
            className={styles.link}
            text={props.data.adsetName}
            search={props.search}
            onClick={() => {
                props.setSelect && props.setSelect(props.data.adsetId || '');
            }}
        />
    );
};

export const ItemsAd: React.FC<ItemsProps> = (props) => <ItemAdType {...props} />;

const Viewer: React.FC<{level?: string; data?: StatReportInfoType; isDetail?: boolean}> = (
    props
) => {
    const viewData = convertStatInfoViewData(props.level, props.data);
    return (
        <Table
            className={
                props.isDetail
                    ? 'table border table-bordered border-gray-300 table-row-bordered table-row-gray-300 mb-0'
                    : ''
            }
        >
            <colgroup>
                <col width={props.isDetail ? '200px' : '120px'} />
                <col />
            </colgroup>
            <tbody>
                {viewData?.map((v, index) => (
                    <tr key={index}>
                        <th
                            className={clsx(
                                'text-gray-500 me-3 text-right',
                                props.isDetail ? '' : 'p-1'
                            )}
                        >
                            {v.title}
                        </th>
                        <td className={clsx(props.isDetail ? '' : 'p-1')}>{v.value}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export const getItemId = (level?: string, item?: StatReportInfoType): string | undefined => {
    switch (level) {
        case MetaCode.data.options.ExplorerLevel.campaign.value:
            return item?.campaignId;
        case MetaCode.data.options.ExplorerLevel.adGroup.value:
            return item?.adsetId;
        case MetaCode.data.options.ExplorerLevel.ad.value:
            return item?.adId;
        default:
    }
    return undefined;
};

export const getItemTag = (props: ItemsProps): React.FC<ItemsProps> | undefined => {
    switch (props.level) {
        case MetaCode.data.options.ExplorerLevel.campaign.value:
            return ItemsCampaign;
        case MetaCode.data.options.ExplorerLevel.adGroup.value:
            return ItemsAdGroup;
        case MetaCode.data.options.ExplorerLevel.ad.value:
            return ItemsAd;
    }
    return undefined;
};

interface DetailWindowProps {
    level?: string;
    search?: string;
}
const DetailWindow: React.FC<DetailWindowProps> = (props) => {
    const qry = UserSelect.storage.getQueryString({
        explorer: {level: props.level, search: props.search},
    });
    return (
        <div className='text-right w-100'>
            <a target='_blank' rel='noreferrer' href={`/popup/meta/explorer/?${qry}`}>
                자세히 보기 <i className='bi bi-box-arrow-up-right' />
            </a>
        </div>
    );
};

/**
 * 검색키워드 하일라이트 처리
 * @param props
 * @returns
 */
const SearchHighlight: React.FC<{
    text?: string;
    search?: string;
    className?: string;
    style?: CSSProperties;
    onClick?: () => void;
}> = (props) => {
    const replaceText = (
        text: string,
        needle: string,
        replace: React.ReactNode
    ): React.ReactNode => {
        return (
            <>
                {text.split(needle).map((v, index) => (
                    <span key={index}>
                        {index > 0 && replace}
                        {v}
                    </span>
                ))}
            </>
        );
    };
    return (
        <span className={props.className} style={props.style} onClick={props.onClick}>
            {props.search && props.text
                ? replaceText(
                      props.text,
                      props.search,
                      <span className='text-danger'>{props.search}</span>
                  )
                : props.text}
        </span>
    );
};

const ItemAdType: React.FC<ItemsProps> = (props) => {
    const onClick = () => {
        props.setSelect && props.setSelect(props.data?.adId || '');
    };
    return (
        <div className='border mt-1 w-100'>
            <div className='p-3 ps-6'>
                <SearchHighlight
                    key={1}
                    className={styles.link}
                    text={props.data.adName}
                    search={props.search}
                    onClick={onClick}
                />
                <br />
            </div>
        </div>
    );
};
