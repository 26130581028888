import Code from './Code';

export default class KakaoMomentCode extends Code {
    public static readonly data = {
        options: {
            /*
            실적Level
            biz-channels
            campaigns
            ad-groups
            ads
            ad-extensions
            keywords
            creatives // 카카오
            creative-links // 카카오
            */
            ExplorerLevel: {
                campaign: {value: 'campaigns', label: '캠페인'},
                adGroup: {value: 'ad-groups', label: '광고그룹'},
                ad: {value: 'ads', label: '소재'},
            },

            /** 지표 선택용 */
            dimensionType: {
                default: {value: '-', label: '기본'},
                device: {value: 'device', label: '디바이스'},
                placement: {value: 'placement', label: '게재지면'},
                creative_format: {value: 'creative_format', label: '소재유형'},
                location: {value: 'location', label: '지역'},
            },
        },

        type: {
            downlaodEntityType: {
                campaign: {value: 'KMET0002', label: '캠페인', data: {path: 'campaign'}},
                adgroup: {value: 'KMET0003', label: '광고그룹', data: {path: 'ad-group'}},
                ad: {value: 'KMET0004', label: '소재', data: {path: 'ads'}},
                device: {value: 'KMET0005', label: '디바이스', data: {path: 'device'}},
                location: {value: 'KMET0006', label: '지역', data: {path: 'placement'}},
                creativeFormat: {
                    value: 'KMET0007',
                    label: '소재유형',
                    data: {path: 'creative-format'},
                },
                placement: {value: 'KMET0008', label: '게제지면', data: {path: 'placement'}},
            },
        },

        Assets: {
            /** 캠페인 상태 */
            config: {
                ON: {value: 'ON', label: 'ON'},
                OFF: {value: 'OFF', label: 'OFF'},
                DEL: {value: '삭제', label: '삭제'},
            },
            /** 캠페인 유형 */
            campaignType: {
                TALK_BIZ_BOARD: {value: 'TALK_BIZ_BOARD', label: '카카오 비즈보드'},
                DISPLAY: {value: 'DISPLAY', label: '디스플레이'},
                TALK_CHANNEL: {value: 'TALK_CHANNEL', label: '카카오톡 채널'},
                DAUM_SHOPPING: {value: 'DAUM_SHOPPING', label: '다음쇼핑'},
                VIDEO: {value: 'VIDEO', label: '동영상'},
                PERSONAL_MESSAGE: {value: 'PERSONAL_MESSAGE', label: '개인화 메시지'},
                PC_TALK_BOTTOM: {value: 'PC_TALK_BOTTOM', label: '포커스보드'},
                SPONSORED_BOARD: {value: 'SPONSORED_BOARD', label: '스폰서드보드'},
                PRODUCT_CATALOG: {value: 'PRODUCT_CATALOG', label: '상품 카탈로그'},
                KAKAO_TV: {value: 'KAKAO_TV', label: '카카오TV'},
                PC_TALK_RICH_POP: {value: 'PC_TALK_RICH_POP', label: '리치팝 올데이'},
                TALK_BIZ_BOARD_RESERVED: {
                    value: 'TALK_BIZ_BOARD_RESERVED',
                    label: '카카오 비즈보드 CPT',
                },
                FOCUS_FULL_VIEW: {value: 'FOCUS_FULL_VIEW', label: '포커스 풀뷰'},
                PROFILE_FULL_VIEW: {value: 'PROFILE_FULL_VIEW', label: '프로필 풀뷰'},
            },

            /** 캠페인 목표 */
            objective: {
                REACH: {value: 'REACH', label: '도달'},
                VIEW: {value: 'VIEW', label: '조회'},
                CONVERSION: {value: 'CONVERSION', label: '전환'},
                VISITING: {value: 'VISITING', label: '방문'},
            },

            /** 광고 목표 대상 */
            objectiveType: {
                CATALOG: {value: 'CATALOG', label: '카탈로그'},
                TALK_CHANNEL: {value: 'TALK_CHANNEL', label: '카카오톡채널'},
                PIXEL_AND_SDK: {value: 'PIXEL_AND_SDK', label: '픽셀&SDK'},
            },

            /** 최적화 전환 유형 */
            objectiveDetailType: {
                PURCHASE: {value: 'PURCHASE', label: '구매'},
                APP_INSTALL: {value: 'APP_INSTALL', label: '앱 설치'},
                COMPLETE_REGISTRATION: {value: 'COMPLETE_REGISTRATION', label: '회원가입'},
                SIGN_UP: {value: 'SIGN_UP', label: '서비스 신청'},
                CART: {value: 'CART', label: '장바구니'},
                PARTICIPATION: {value: 'PARTICIPATION', label: '잠재고객'},
                ADD_FRIEND: {value: 'ADD_FRIEND', label: '채널 친구 추가'},
                SEND_MESSAGE: {value: 'SEND_MESSAGE', label: '메시지 발송'},
                CATALOG_PRODUCT_PURCHASE: {
                    value: 'CATALOG_PRODUCT_PURCHASE',
                    label: '카탈로그 상품 구매',
                },
            },

            /** 상태 */
            status: {
                READY: {label: '집행 예정', value: 'READY'},
                LIVE: {label: '집행 중', value: 'LIVE'},
                FINISHED: {label: '집행 완료', value: 'FINISHED'},
                OFF: {label: '사용자OFF', value: 'OFF'},
                DELETED: {label: '삭제', value: 'DELETED'},
                EXCEED_DAILY_BUDGET: {label: '캠페인 일 예산 초과', value: 'EXCEED_DAILY_BUDGET'},
                PAUSED: {label: '일시중지', value: 'PAUSED'},
                NO_AVAILABLE_CREATIVE: {
                    label: '집행 가능한 소재가 없음',
                    value: 'NO_AVAILABLE_CREATIVE',
                },
                CANCELED: {value: 'CANCELED', label: '계약해지'},
                SYSTEM_CONFIG_EXTERNAL_SERVICE_STOP: {
                    value: 'SYSTEM_CONFIG_EXTERNAL_SERVICE_STOP',
                    label: '연결 서비스 제한으로 운영불가인 상태',
                },
                ADACCOUNT_UNAVAILABLE: {value: 'ADACCOUNT_UNAVAILABLE', label: '광고계정 운영불가'},
            },

            /** 광고그룹 상태 */
            statusAdGroup: {
                READY: {label: '집행 예정', value: 'READY'},
                LIVE: {label: '집행 중', value: 'LIVE'},
                FINISHED: {label: '집행 완료', value: 'FINISHED'},
                OFF: {label: '사용자OFF', value: 'OFF'},
                DELETED: {label: '삭제', value: 'DELETED'},
                EXCEED_DAILY_BUDGET: {
                    label: '광고 그룹 일 예산 초과',
                    value: 'EXCEED_DAILY_BUDGET',
                },
                PAUSED: {label: '일시중지', value: 'PAUSED'},
                NO_AVAILABLE_CREATIVE: {
                    label: '집행 가능한 소재가 없음',
                    value: 'NO_AVAILABLE_CREATIVE',
                },
                CANCELED: {value: 'CANCELED', label: '계약해지'},
                CAMPAIGN_UNAVAILABLE: {
                    value: 'CAMPAIGN_UNAVAILABLE',
                    label: '캠페인 운영불가',
                },
                SYSTEM_CONFIG_ADMIN_STOP: {value: 'SYSTEM_CONFIG_ADMIN_STOP', label: '관리자 정지'},
            },

            /** 소재 상태 */
            statusCreative: {
                OPERATING: {value: 'OPERATING', label: '운영 가능'},
                MONITORING_REJECTED: {
                    value: 'MONITORING_REJECTED',
                    label: '운영 불가 (관리자 정지)',
                },
                UNAPPROVED: {value: 'UNAPPROVED', label: '운영 불가 (심사 미승인)'},
                INVALID_DATE: {value: 'INVALID_DATE', label: '운영 불가 (기간 오류)'},
                SYSTEM_CONFIG_VOID: {value: 'SYSTEM_CONFIG_VOID', label: '운영 불가 (콘텐츠 오류)'},
                ADGROUP_UNAVAILABLE: {
                    value: 'ADGROUP_UNAVAILABLE',
                    label: '운영 불가 (광고그룹 운영불가)',
                },
                OFF: {value: 'OFF', label: '사용자 OFF'},
                DELETED: {value: 'DELETED', label: '삭제'},
            },
            /** 소재 심사 상태 */
            statusDescription: {
                APPROVED: {value: 'APPROVED', label: '심사 승인'},
                WAITING: {value: 'WAITING', label: '심사 중'},
                REJECTED: {value: 'REJECTED', label: '심사 보류'},
                MONITORING_REJECTED: {value: 'MONITORING_REJECTED', label: '모니터링 보류'},
                MODIFICATION_WAITING: {value: 'MODIFICATION_WAITING', label: '수정사항 심사 중'},
                MODIFICATION_REJECTED: {
                    value: 'MODIFICATION_REJECTED',
                    label: '수정사항 심사 보류',
                },
            },

            /** 과금 방식 */
            pricingType: {
                CPV: {value: 'CPV', label: 'CPV'},
                CPM: {value: 'CPM', label: 'CPM'},
                CPT: {value: 'CPT', label: 'CPT'},
                CPMS: {value: 'CPMS', label: 'CPMS'},
                CPA: {value: 'CPA', label: 'CPA'},
                CPC: {value: 'CPC', label: 'CPC'},
            },

            /** 입찰 방식 */
            strategy: {
                AUTOBID: {value: 'AUTOBID', label: '자동입찰'},
                MANUAL: {value: 'MANUAL', label: '수동'},
                OPTIMIZATION: {value: 'OPTIMIZATION', label: '목적 최적화'},
            },

            /** 입찰 전략 */
            bidStrategyTarget: {
                TARGET_CPC: {value: 'TARGET_CPC', label: 'CPC 비용 목표'},
                TARGET_CPA: {value: 'TARGET_CPA', label: 'CPA 비용 목표'},
                TARGET_ROAS: {value: 'TARGET_ROAS', label: 'ROAS 목표'},
                MAXIMIZE_CONVERSION_VALUE: {
                    value: 'MAXIMIZE_CONVERSION_VALUE',
                    label: '전환 가치 최대화',
                },
            },

            /** 광고그룹 유형 */
            adGroupType: {
                DISPLAY: {value: '디스플레이', label: '디스플레이'},
                MESSAGE: {value: '메시지', label: '메시지'},
            },

            adType: {
                IMAGE_BANNER: {value: 'IMAGE_BANNER', label: '이미지 배너'},
                VIDEO_NATIVE: {value: 'VIDEO_NATIVE', label: '비디오 네이티브'},
                IMAGE_NATIVE: {value: 'IMAGE_NATIVE', label: '이미지 네이티브'},
                IMAGE_BOX: {value: 'IMAGE_BOX', label: '이미지 박스'},
                CATALOG_MANUAL: {value: 'CATALOG_MANUAL', label: '이미지 카탈로그'},
                CATALOG_DYNAMIC: {value: 'CATALOG_DYNAMIC', label: '다이나믹 카탈로그'},
                SERVICE_CONTENT: {value: 'SERVICE_CONTENT', label: '콘텐츠'},
                BASIC_TEXT_MESSAGE: {value: 'BASIC_TEXT_MESSAGE', label: '기본 텍스트형'},
                WIDE_LIST_MESSAGE: {value: 'WIDE_LIST_MESSAGE', label: '와이드 리스트형'},
                WIDE_MESSAGE: {value: 'WIDE_MESSAGE', label: '와이드 이미지'},
                CAROUSEL_COMMERCE_MESSAGE: {
                    value: 'CAROUSEL_COMMERCE_MESSAGE',
                    label: '캐러셀 커머스',
                },
                CAROUSEL_FEED_MESSAGE: {value: 'CAROUSEL_FEED_MESSAGE', label: '캐러셀 피드'},
                PREMIUM_VIDEO_MESSAGE: {value: 'PREMIUM_VIDEO_MESSAGE', label: '프리미엄 동영상'},
                RICH_NATIVE: {value: 'RICH_NATIVE', label: '리치 네이티브'},
                VIDEO_INSTREAM: {value: 'VIDEO_INSTREAM', label: '비디오 인스트림'},
            },

            /** 소재 유형 */
            kakaoFormat: {
                IMAGE_BANNER: {value: 'IMAGE_BANNER', label: '이미지 배너'},
                VIDEO_NATIVE: {value: 'VIDEO_NATIVE', label: '비디오 네이티브'},
                IMAGE_NATIVE: {value: 'IMAGE_NATIVE', label: '이미지 네이티브'},
                IMAGE_BOX: {value: 'IMAGE_BOX', label: '이미지 박스'},
                CATALOG_MANUAL: {value: 'CATALOG_MANUAL', label: '이미지 카탈로그'},
                CATALOG_DYNAMIC: {value: 'CATALOG_DYNAMIC', label: '다이나믹 카탈로그'},
                SERVICE_CONTENT: {value: 'SERVICE_CONTENT', label: '콘텐츠'},
                BASIC_TEXT_MESSAGE: {value: 'BASIC_TEXT_MESSAGE', label: '기본 텍스트형'},
                WIDE_LIST_MESSAGE: {value: 'WIDE_LIST_MESSAGE', label: '	와이드 리스트형'},
                WIDE_MESSAGE: {value: 'WIDE_MESSAGE', label: '와이드 이미지'},
                CAROUSEL_COMMERCE_MESSAGE: {
                    value: 'CAROUSEL_COMMERCE_MESSAGE',
                    label: '캐러셀 커머스',
                },
                CAROUSEL_FEED_MESSAGE: {value: 'CAROUSEL_FEED_MESSAGE', label: '캐러셀 피드'},
                PREMIUM_VIDEO_MESSAGE: {value: 'PREMIUM_VIDEO_MESSAGE', label: '프리미엄 동영상'},
                RICH_NATIVE: {value: 'RICH_NATIVE', label: '리치 네이티브'},
                VIDEO_INSTREAM: {value: 'VIDEO_INSTREAM', label: '비디오 인스트림'},
            },

            dimensionDeviceType: {
                PC: {value: 'PC', label: 'PC'},
                iOS: {value: 'IOS', label: 'iOS'},
                Android: {value: 'ANDROID', label: 'Android'},
            },

            dimensionPlacementType: {
                KAKAO_TALK: {value: 'KAKAO_TALK', label: '카카오톡'},
                DAUM: {value: 'DAUM', label: '다음'},
                KAKAO_STORY: {value: 'KAKAO_STORY', label: '카카오스토리'},
                KAKAO_SERVICE: {value: 'KAKAO_SERVICE', label: '카카오서비스'},
                NETWORK: {value: 'NETWORK', label: '네트워크'},
            },
            dimensionLocationType: {
                A: {value: 'A', label: '강원특별자치도'},
                B: {value: 'B', label: '경기도'},
                C: {value: 'C', label: '경상남도'},
                D: {value: 'D', label: '경상북도'},
                E: {value: 'E', label: '광주광역시'},
                F: {value: 'F', label: '대구광역시'},
                G: {value: 'G', label: '대전광역시'},
                H: {value: 'H', label: '부산광역시'},
                I: {value: 'I', label: '서울특별시'},
                J: {value: 'J', label: '울산광역시'},
                K: {value: 'K', label: '인천광역시'},
                L: {value: 'L', label: '전라남도'},
                M: {value: 'M', label: '전북특별자치도'},
                N: {value: 'N', label: '제주특별자치도'},
                O: {value: 'O', label: '충청남도'},
                P: {value: 'P', label: '충청북도'},
                Q: {value: 'Q', label: '세종특별자치시'},
                U: {value: 'U', label: '알수없음'},
                Z: {value: 'Z', label: '해외전체'},
            },
        },
    };
}
