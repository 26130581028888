import clsx from 'clsx';
import React, {CSSProperties} from 'react';
import {Table} from 'react-bootstrap';
import UserSelect from '../../../common/helpers/UserSelect';
import {StatReportInfoType} from '../../../common/services/models/kakaomoment/StatReportType';
import KakaoMomentCode from '../../../modules/code/KakaoMomentCode';
import ExplorerListDetail, {getDimensionInfo} from './ExplorerListDetail';
import {convertStatInfoViewData} from './StatInfoData';
import styles from './explorer.module.scss';

export interface ItemsProps {
    index?: number;
    data: StatReportInfoType;
    media?: string;
    level?: string;
    search?: string;
    show?: boolean;
    isDetail?: boolean;
    setSelect?: (id: string) => void;
}

export const Items: React.FC<ItemsProps> = (props) => {
    const Item = getItemTag(props);
    const id: string | undefined = getItemId(props.level, props.data);
    if (!Item) {
        return <>Unknown {props.level}</>;
    }
    const dimensionInfo = getDimensionInfo(id, props.data);
    return (
        <>
            {props.show && <DetailWindow level={props.level} search={props.search} />}
            <div
                className='pt-5'
                style={{
                    overflowY: 'scroll',
                    maxHeight: 'calc(100vh - 276px)',
                    marginRight: '-10px',
                    paddingRight: '10px',
                }}
            >
                {!props.isDetail && (
                    <>
                        {props.index !== undefined && (
                            <span className='me-3 fw-bold'>{props.index + 1}</span>
                        )}
                        <Item {...props} />
                    </>
                )}
                <Viewer {...props} />
                {props.show && (
                    <ExplorerListDetail {...props} id={getItemId(props.level, props.data)} />
                )}
            </div>
        </>
    );
};

export const ItemsDevice: React.FC<ItemsProps> = (props) => {
    const deviceType = props.search?.split('-')[2] || '';
    const deviceTypeText = KakaoMomentCode.getLabel(
        KakaoMomentCode.data.Assets.dimensionDeviceType,
        deviceType
    );
    return (
        <SearchHighlight
            className={styles.link}
            text={`디바이스: ${deviceTypeText}`}
            search={props.search}
            onClick={() => {
                props.setSelect && props.setSelect(props.data.campaignId || '');
            }}
        />
    );
};

export const PlacementItems: React.FC<ItemsProps> = (props) => {
    const placementType = props.search?.split('-')[2] || '';
    const placementTypeText = KakaoMomentCode.getLabel(
        KakaoMomentCode.data.Assets.dimensionPlacementType,
        placementType
    );
    return (
        <SearchHighlight
            className={styles.link}
            text={`게재지면: ${placementTypeText}`}
            search={props.search}
            onClick={() => {
                props.setSelect && props.setSelect(props.data.campaignId || '');
            }}
        />
    );
};

export const CreativeFormatItems: React.FC<ItemsProps> = (props) => {
    const creativeFormatType = props.search?.split('-')[2] || '';
    const creativeFormatTypeText = KakaoMomentCode.getLabel(
        KakaoMomentCode.data.Assets.kakaoFormat,
        creativeFormatType
    );
    return (
        <SearchHighlight
            className={styles.link}
            text={`소재 유형: ${creativeFormatTypeText}`}
            search={props.search}
            onClick={() => {
                props.setSelect && props.setSelect(props.data.campaignId || '');
            }}
        />
    );
};

export const LocationItems: React.FC<ItemsProps> = (props) => {
    const locationType = props.search?.split('-')[2] || '';
    const locationTypeText = KakaoMomentCode.getLabel(
        KakaoMomentCode.data.Assets.dimensionLocationType,
        locationType
    );
    return (
        <SearchHighlight
            className={styles.link}
            text={`지역: ${locationTypeText}`}
            search={props.search}
            onClick={() => {
                props.setSelect && props.setSelect(props.data.campaignId || '');
            }}
        />
    );
};

export const ItemsCampaign: React.FC<ItemsProps> = (props) => {
    const dimentsionInfo = getDimensionInfo(
        props.data.campaignId,
        props.data,
        props.data.campaignName
    );
    return (
        <SearchHighlight
            className={styles.link}
            text={dimentsionInfo.name}
            search={props.search}
            onClick={() => {
                props.setSelect && props.setSelect(props.data.campaignId || '');
            }}
        />
    );
};

export const ItemsAdGroup: React.FC<ItemsProps> = (props) => {
    const dimentsionInfo = getDimensionInfo(
        props.data.adGroupId,
        props.data,
        props.data.adGroupName
    );
    return (
        <SearchHighlight
            className={styles.link}
            text={dimentsionInfo.name}
            search={props.search}
            onClick={() => {
                props.setSelect && props.setSelect(props.data.adGroupId || '');
            }}
        />
    );
};

export const ItemsAd: React.FC<ItemsProps> = (props) => <ItemAdType {...props} />;

interface ViewerProps extends ItemsProps {
    level?: string;
    data: StatReportInfoType;
    isDetail?: boolean;
}

const Viewer: React.FC<ViewerProps> = (props) => {
    const [dimensionType] = props.search?.split('-') || [];
    const isDimension = Object.keys(KakaoMomentCode.data.options.dimensionType).includes(
        dimensionType
    );

    const viewData = convertStatInfoViewData(isDimension, props.level, props.data);
    return (
        <Table
            className={
                props.isDetail
                    ? 'table border table-bordered border-gray-300 table-row-bordered table-row-gray-300 mb-0'
                    : ''
            }
        >
            <colgroup>
                <col width={props.isDetail ? '200px' : '120px'} />
                <col />
            </colgroup>
            <tbody>
                {viewData?.map((v, index) => (
                    <tr key={index}>
                        <th
                            className={clsx(
                                'text-gray-500 me-3 text-right',
                                props.isDetail ? '' : 'p-1'
                            )}
                        >
                            {v.title}
                        </th>
                        <td className={clsx(props.isDetail ? '' : 'p-1')}>{v.value}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export const getItemId = (level?: string, item?: StatReportInfoType): string | undefined => {
    switch (level) {
        case KakaoMomentCode.data.options.ExplorerLevel.campaign.value:
            return item?.campaignId;
        case KakaoMomentCode.data.options.ExplorerLevel.adGroup.value:
            return item?.adGroupId;
        case KakaoMomentCode.data.options.ExplorerLevel.ad.value:
            return item?.adId;
        default:
    }
    return undefined;
};

export const getItemTag = (props: ItemsProps): React.FC<ItemsProps> | undefined => {
    const [dimensionType] = props?.search?.split('-') || [];
    switch (dimensionType) {
        // 디바이스
        case KakaoMomentCode.data.options.dimensionType.device.value:
            return ItemsDevice;
        // 게재지면
        case KakaoMomentCode.data.options.dimensionType.placement.value:
            return PlacementItems;
        // 소재유형
        case KakaoMomentCode.data.options.dimensionType.creative_format.value:
            return CreativeFormatItems;
        // 지역
        case KakaoMomentCode.data.options.dimensionType.location.value:
            return LocationItems;
        default: {
            switch (props.level) {
                case KakaoMomentCode.data.options.ExplorerLevel.campaign.value:
                    return ItemsCampaign;
                case KakaoMomentCode.data.options.ExplorerLevel.adGroup.value:
                    return ItemsAdGroup;
                case KakaoMomentCode.data.options.ExplorerLevel.ad.value:
                    return ItemsAd;
            }
            return undefined;
        }
    }
};

interface DetailWindowProps {
    level?: string;
    search?: string;
}
const DetailWindow: React.FC<DetailWindowProps> = (props) => {
    const qry = UserSelect.storage.getQueryString({
        explorer: {level: props.level, search: props.search},
    });
    return (
        <div className='text-right w-100'>
            <a target='_blank' rel='noreferrer' href={`/popup/kakaomoment/explorer/?${qry}`}>
                자세히 보기 <i className='bi bi-box-arrow-up-right' />
            </a>
        </div>
    );
};

/**
 * 검색키워드 하일라이트 처리
 * @param props
 * @returns
 */
const SearchHighlight: React.FC<{
    text?: string;
    search?: string;
    className?: string;
    style?: CSSProperties;
    onClick?: () => void;
}> = (props) => {
    const replaceText = (
        text: string,
        needle: string,
        replace: React.ReactNode
    ): React.ReactNode => {
        return (
            <>
                {text.split(needle).map((v, index) => (
                    <span key={index}>
                        {index > 0 && replace}
                        {v}
                    </span>
                ))}
            </>
        );
    };
    return (
        <span className={props.className} style={props.style} onClick={props.onClick}>
            {props.search && props.text
                ? replaceText(
                      props.text,
                      props.search,
                      <span className='text-danger'>{props.search}</span>
                  )
                : props.text}
        </span>
    );
};

const ItemAdType: React.FC<ItemsProps> = (props) => {
    const onClick = () => {
        props.setSelect && props.setSelect(props.data?.adId || '');
    };
    const dimensionInfo = getDimensionInfo(props.data.adId, props.data, props.data.adName);
    return (
        <div className='border mt-1 w-100'>
            <div className='p-3 ps-6'>
                <SearchHighlight
                    key={1}
                    className={styles.link}
                    text={dimensionInfo.name}
                    search={props.search}
                    onClick={onClick}
                />
                <br />
            </div>
        </div>
    );
};
