import Papa from 'papaparse';
import Config from '../../common/conf/Config';
import { UserContextModel } from '../../contexts/UserContext';

class Utils{
    //eslint-disable-next-line
    public static OnlyNumberPattern:RegExp = /[a-zA-Zㄱ-ㅎ|ㅏ-ㅣ|가-힣\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/g;
    //eslint-disable-next-line
    public static PercentNumberPattern:RegExp = /^0[0-9]{1}|%[\%0-9]+|[a-zA-Zㄱ-ㅎ|ㅏ-ㅣ|가-힣\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$&\\\=\(\'\"]/g;
    
    public static arraySimpleName(data?:string[]):string|undefined{
        if(!data || data.length===0){ return; }
        const length = data.length;
        return `'${data[0]}' ${length>1 ? ` 외 ${(length-1).addComma()}개` : ''}`;
    }

    /**
     * 하위 개층에 해당하는 waning만 반환합니다.
     * @param key 해당 노드의 name, 마지막 '.'은 제외
     * @param warning 전체 warning값
     * @returns 
     */
    public static warningFilter = (key:string, warning?:Map<string, string|React.ReactNode>):Map<string, string|React.ReactNode>|undefined=>{
        const name = `${key}.`;
        const data:Map<string, string|React.ReactNode>|undefined = warning?.filter((value, key)=>key.indexOf(name)===0);
        if(!data){ return undefined; }
        return new Map<string, string|React.ReactNode>( Array.from(data).map((v)=>[v[0].substring(name.length), v[1]]) );  //상위 노드 키값은 제외하여 생성
    }

    /**
     * 파일 iconv 처리하기
     */
    public static fileConv = (file?:File, oldCharset?:string, newCharset?:string, callback?:(data?:FormData)=>void)=>{
        const old:string = oldCharset || 'euc-kr';
        const charset:string = newCharset || 'utf-8';
        if(!file){ callback && callback(undefined); return; }

        // 파일 읽기
        const reader = new FileReader();
        reader.onload = async (e) => {
            if(!e.target?.result){ callback && callback(undefined); return; }   //로딩실패
            const fileData = e.target.result as ArrayBuffer;

            // 문자셋 변경
            const originalText = new TextDecoder().decode(fileData);
            const newText = originalText.replace(old, charset); // 문자열 변경
            Utils.csvParse(originalText);

            // 변경된 데이터 서버에 전송
            const formData = new FormData();
            const blob = new Blob([newText], { type: `text/plain;charset=${charset}` });
            formData.append('file', blob, file.name); // 새 파일 이름 설정
            callback && callback(formData);
        };
        reader.readAsArrayBuffer(file);
    }

    /**
     * 파일을 읽어서 utf-8 문자셋으로 csv 제구성합니다.
     * @param file
     * @param charset 파일 문자셋 
     * @param callback 
     * @returns 
     */
    public static csvToBuffer = (file?:File, charset?:string, callback?:(data:ArrayBufferLike|undefined)=>void)=>{
        if(!file){ return; }
        Papa.parse(file, {
            encoding: charset,
            complete: (result) => {
                const data = (result.data as string[][]).map((v=>v.map((v2)=>v2.replace(/[\n\b\t\r]/g, ''))));
                const text = Papa.unparse(data);

                // TextEncoder를 사용하여 문자열을 Uint8Array로 변환
                const textEncoder = new TextEncoder();
                const uint8Array = textEncoder.encode(text);

                // Uint8Array를 ArrayBuffer로 변환
                callback && callback(uint8Array.buffer);

                // 변경된 데이터 서버에 전송
                // const formData = new FormData();
                // const blob = new Blob([complete], { type: `text/plain;charset=UTF-8` });
                // formData.append('file', blob, file.name); // 새 파일 이름 설정
                // callback && callback(formData);
            },
        });
    }

    public static csvParse = (data:string):string=>{
        const Papa = require('papaparse');
        // CSV 파싱
        const rows = data.split('\n'); // 각 행 분리
        for (const row of rows) {
            const columns:string[] = Papa.parse(row);
            console.log(columns); // 행을 배열로 출력
        }
        return '';
    }

    /** 파일 용량을 보기좋게 변경합니다. */
    public static getHumanFilesize = (size:number)=>{
        const units:string[] = ["Kb", "Mb", "Gb", "Tb", "Pb", "Eb", "Zb", "Yb"];
        let unit:string = 'b';
        for(var i=0; i<units.length && size>900; i++){
            unit = units[i];
            size /= 1024;
        }
        return (size < 100 ? (size < 10 ? size.round(2) : size.round(1)) : size.round(0)) + unit;
    }

    /** 브라우저 URL path로부터 해당 이름 뒤의 값을 반환합니다. id추출용 */
    public static getIdFromPath = (name:string):string|undefined=>{
        const token:string[]  = document.location.pathname.split("/");
        for(let i=0; i<token.length-1; i++){
            if(token[i]===name){ return token[i+1]; }
        }
        return undefined;
    }

    public static SelectLimitCheck = <T extends unknown> (context:UserContextModel, list:T[]):boolean=>{
        if( Config.system.selectLimited>0 && list.length>Config.system.selectLimited){
            context.alertMessage.add({
                variant:"danger",
                title:`1회 최대 ${Config.system.selectLimited.addComma()}개 요청`,
                body:`작업 요청은 1회 최대 ${Config.system.selectLimited.addComma()}개까지 선택해 주세요.`
            });
            return false;
        }
        return true;
    }

    public static getParam = (name:string, value?:string):string|null=>{
        return new URL(value || window.location.href).searchParams.get(name);
    }
    public static getParamAll = (name:string, value?:string):string[]|null=>{
        return new URL(value || window.location.href).searchParams.getAll(name);
    }

    /** 요일을 심플하게 표시합니다.(bit 연산 : 0-일요일) */
    public static weekFormater = (list:number):string=>{
        const temp = ['일','월','화','수','목','금','토'].map((v,index)=>({name:v, index})).filter((v,index)=>Math.pow(2,index)&list);
        temp.forEach((v, index, arr)=>{
            v.name = index>0 && index<arr.length-1 && (v.index-1) === arr[index-1].index && (v.index+1) === arr[index+1].index? "~" : v.name;
        });
        const result = temp.map((v)=>v.name).unique();
        return result.join(",").replaceAll(",~,","~");
    }

    public static async copyHtmlToClipboard(obj?:HTMLElement|null){ 
        if(!obj){ return; }

        // 원본 DOM을 건드리지 않기 위해 클론 생성
        const clone = obj.cloneNode(true) as HTMLElement;

        // 클론된 요소에 계산된 스타일을 모두 인라인 스타일로 적용
        this.inlineAllStyles(clone);
        
        // 테이블의 전체 HTML을 가져옵니다.
        const htmlContent = clone?.outerHTML || '';
      
        // Blob을 만들어 "text/html" MIME 타입으로 지정합니다.
        const blob = new Blob([htmlContent], { type: "text/html" });
      
        // ClipboardItem 생성
        const clipboardItem = new ClipboardItem({ "text/html": blob });
      
        try {
            // 클립보드에 HTML 형식 데이터를 씁니다.
            await navigator.clipboard.write([clipboardItem]);
            alert("클립보드에 복사되었습니다!");
        } catch (e) {
            alert("클립보드 복사에 실패하였습니다.");
        }
    }

    public static inlineAllStyles (element: HTMLElement): void {
        const computedStyle = window.getComputedStyle(element);
        let inlineStyle = "";
        for (let i = 0; i < computedStyle.length; i++) {
            const key = computedStyle[i];
            const value = computedStyle.getPropertyValue(key);
            inlineStyle += `${key}:${value};`;
        }
        // 기존 style 속성을 덮어쓰기
        element.style.cssText = inlineStyle;
      
        // 자식 요소들에 대해 재귀적으로 처리 (HTMLElement 타입 체크)
        Array.from(element.children).forEach(child => {
            if (child instanceof HTMLElement) {
                this.inlineAllStyles(child);
            }
        });
    }

    /** 리스트형 데이터를 SCV 파일로 다운로드 */
    // public static downloadScv = (data:any[], filename: string) => {

    //     const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});               
    //     const link = document.createElement('a');
    //     const uri = window.URL.createObjectURL(blob);
    //     link.href = uri;
    //     link.target = '_self';
    //     link.download = filename;
    //     link.click();
    //     link.remove();
    //     window.URL.revokeObjectURL(uri);
    
    
        // var fs = require('fs');
        // var writer = fs.createWriteStream(filename);
        // axios.then((res:AxiosResponse) => {
      
        //   //ensure that the user can call `then()` only when the file has
        //   //been downloaded entirely.
        //   return new Promise((resolve, reject) => {
        //     res.data.pipe(writer);
        //     let error:OnErrorEventHandler = null;
        //     writer.on('error', (err:OnErrorEventHandler) => {
        //       error = err;
        //       writer.close();
        //       reject(err);
        //     });
        //     writer.on('close', () => {
        //       if (!error) {
        //         resolve(true);
        //       }
        //       //no need to call the reject here, as it will have been called in the
        //       //'error' stream;
        //     });
        //   });
        // });
    //   }

}

export default Utils;