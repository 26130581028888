import {StatReportResKeys, StatReportResType} from './StatReportType';

/**
 * GFA Ad Response Type
 */
export interface AdResType extends Omit<StatReportResType, 'key'> {
    // 광고 계정 ID (필수)
    adAccountId: string;
    // 광고소재ID (필수)
    adId: string;
    // 광고그룹ID (필수)
    adsetId: string;
    // 광고소재명
    adName: string;
    // ON/OFF 상태 (true/false)
    activated: string;
    // ON/OFF 상태명
    activatedName: string;
    // 소재 타입
    creativeType: string | null;
    // 소재 타입명
    creativeTypeName: string | null;
    // 상태 코드
    status: string | null;
    // 상태명
    statusName: string | null;
    // 캠페인명
    campaignName: string;
    // 캠페인 ID
    campaignId: string;
    // 캠페인 목적 코드
    objective: string | null;
    // 캠페인 목적명
    objectiveName: string | null;
    // 광고그룹명
    adsetName: string;
    // 청구 기준
    bidType: string | null;
    // 대표 URL
    linkUrl: string | null;
    // 원본 URL
    imageUrl: string;
    // 원본 파일명
    imageFilename: string;
    // 원본 ID
    imageNo: string;
    // 원본 파일사이즈 (바이트)
    imageFileSize: number;
    // 원본 높이 (px)
    imageHeight: number;
    // 원본 너비 (px)
    imageWidth: number;
    // 생성일시
    imageCreatedAt: string | null;
}

/**
 * GFA Ad Response 컬럼 키
 */
export enum AdResKeys {
    // 광고 계정 ID (필수)
    adAccountId = 'adAccountId',
    // 광고소재ID (필수)
    adId = 'adId',
    // 광고그룹ID (필수)
    adsetId = 'adsetId',
    // 광고소재명
    adName = 'adName',
    // ON/OFF 상태 (true/false)
    activated = 'activated',
    // ON/OFF 상태명
    activatedName = 'activatedName',
    // 소재 타입
    creativeType = 'creativeType',
    // 소재 타입명
    creativeTypeName = 'creativeTypeName',
    // 상태 코드
    status = 'status',
    // 상태명
    statusName = 'statusName',
    // 캠페인명
    campaignName = 'campaignName',
    // 캠페인 ID
    campaignId = 'campaignId',
    // 캠페인 목적 코드
    objective = 'objective',
    // 캠페인 목적명
    objectiveName = 'objectiveName',
    // 광고그룹명
    adsetName = 'adsetName',
    // 청구 기준
    bidType = 'bidType',
    // 대표 URL
    linkUrl = 'linkUrl',
    // 원본 URL
    imageUrl = 'imageUrl',
    // 원본 파일명
    imageFilename = 'imageFilename',
    // 원본 ID
    imageNo = 'imageNo',
    // 원본 파일사이즈 (바이트)
    imageFileSize = 'imageFileSize',
    // 원본 높이 (px)
    imageHeight = 'imageHeight',
    // 원본 너비 (px)
    imageWidth = 'imageWidth',
    // 생성일시 (ISO 8601 형식)
    imageCreatedAt = 'imageCreatedAt',
}

export const AdKeys = {...AdResKeys, ...StatReportResKeys};
