/* eslint-disable jsx-a11y/anchor-is-valid */
import ApexCharts, { ApexOptions } from 'apexcharts';
import { useEffect, useRef } from 'react';
import { getCSS } from '../../../../_metronic/assets/ts/_utils';
import { ChartsProps } from './Types';


const BoxPlotCharts:React.FC<ChartsProps> = (props) => {
    const chartRef = useRef<HTMLDivElement | null>(null);
    const refreshChart = () => {
        if (!chartRef.current) { return; }
        const height = parseInt(getCSS(chartRef.current, 'height'));
        const chart = new ApexCharts(chartRef.current, getChartOptions(height, props));
        if (chart) { chart.render(); }
        return chart;
    }

    useEffect(() => {
        const chart = refreshChart();
        return () => { chart && chart.destroy(); }
    // eslint-disable-next-line
    }, [chartRef, props.data]);

    return <div ref={chartRef} className={props.className} style={{height:'300px', ...props.style}} />
}

export default BoxPlotCharts;

const getChartOptions = (height: number, props:ChartsProps): ApexOptions => {
    const labelColor = 'var(--bs-gray-500)';
    const borderColor = 'var(--bs-gray-400)';
    // const dataCount = Math.max(props.barChart.data.length, props.lineChart.data.length);
    // console.log(dataCount, dataCount.gcd(5));
    // console.log(dataCount, dataCount.gcd(6));
    // console.log(dataCount, dataCount.gcd(7));
    // console.log(dataCount, dataCount.gcd(8));
    // console.log(dataCount, dataCount.gcd(9));
    // const tickAmount = Math.min( Math.max(props.barChart.data.length, props.lineChart.data.length), 10);
    // const max = Math.max(...props.data.map((v)=>Math.max(...(v.data as { x: string; y: number[]; }[]).map((v)=>(v.y?.sum() || 0) / (v.y?.length || 1) )))) * 1.2;
    const max = Math.max(...props.data.map((v)=>Math.max(...(v.data as { x: string; y: number[]; }[]).map((v)=>(v.y?.sum() || 0) )))) ;
    // console.log(max, props.data);

    return {
        series: props.data || [],
        chart: {
            type: 'boxPlot',
            // fontFamily: 'inherit',
            stacked: true,
            height: height || 300,
            toolbar: { show: false, offsetX:0, offsetY:0, },
            zoom: { enabled: true },
            background: "transparent",
        },
        yaxis: {
            min: 0,
            max,
            forceNiceScale: true, // 자동으로 적절한 스케일 조정
        }
       
    }
}
