import clsx from 'clsx';
import React, {useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import {StatReportInfoType} from '../../../common/services/models/kakaomoment/StatReportType';
import CustomCard from '../../modules/card/CustomCard';
import styles from './explorer.module.scss';
import {Items, getItemId} from './ExplorerListItems';

export interface ExplorerListProps {
    media?: string;
    level?: string;
    search?: string;
    data: StatReportInfoType[];
    more?: () => void;
    detailState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    onChange?: (data?: StatReportInfoType) => void;
}

export interface ExplorerSelectType {
    id: string;
    data: StatReportInfoType;
}
const ExplorerList: React.FC<ExplorerListProps> = (props) => {
    const [selected, setSelected] = useState<ExplorerSelectType | undefined>();
    const [showDetail, setShowDetail] = props.detailState;
    const setSelect = (value: ExplorerSelectType) => {
        setShowDetail(true);
        setSelected(value);
        props.onChange && props.onChange(value.data);
    };

    // 검색 결과가 1개인 경우(목록 링크로 바로 검색된 경우) 상세페이지까지 바로 열리도록 처리
    useEffect(() => {
        if (props.data?.length === 1) {
            const id: string | undefined = getItemId(props.level, props.data[0]);
            if (id) {
                setSelect({id: id, data: props.data[0]});
            }
        }
        // eslint-disable-next-line
    }, [props.data, props.level]);

    return (
        <CustomCard>
            <div
                style={{
                    overflowY: 'scroll',
                    height: 'calc(100vh - 276px)',
                    display: showDetail ? 'none' : '',
                }}
            >
                {props.data.map((v, index) => {
                    return (
                        <div key={index} className='pt-5 pb-5 border-bottom border-gray-300'>
                            <Items
                                index={index}
                                {...props}
                                data={v}
                                setSelect={(id: string) => setSelect({id, data: v})}
                                show={false}
                            />
                        </div>
                    );
                })}

                {props.more && (
                    <Container className='text-center text-middle mb-5'>
                        <div
                            className={clsx('text-center', styles.link)}
                            onClick={() => {
                                props.more && props.more();
                            }}
                        >
                            <span className='me-5'>더 보기</span>
                            <span className='bi bi-chevron-compact-down fw-bold fs-1' />
                        </div>
                    </Container>
                )}
            </div>

            {selected && showDetail && (
                <div>
                    <Items {...props} data={selected.data} show={showDetail} />
                </div>
            )}
        </CustomCard>
    );
};

export default ExplorerList;
