import { AxiosResponse } from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { AdlyAiServer } from '../../axios';
import UserSelect from '../../helpers/UserSelect';
import { callBackDownload } from '../helpers/Download';
import { RequestFilterType, RequestSearchType } from '../models/RequestTypes';
import { MediaResponseType } from '../models/ResponseTypes';

/** 네이버 표준 Helper */
export default class HelperComponent {
    static path = '';
    static stat = '';
    static performances = '';
    static simple = '';
    static downloadPath = '';

    // 다운로드
    static downloadCall(
        url: string,
        filters: RequestFilterType,
        filename?: string,
        setLoading?: Dispatch<SetStateAction<boolean>>
    ) {
        setLoading && setLoading(true);
        const account = UserSelect.account.get();
        const magId = account?.accountGroup?.magId || 'NONE';
        const name = url.split('/').pop();
        const fn = filename || `[adly-naver]${magId}_${name}.xlsx`;
        AdlyAiServer.get(url, {
            responseType: 'blob',
            params: {useAccount: true, ...filters},
            headers: {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            data: {}, //data 제거하면 설정한 Header 안넘어감.
        })
            .then((res: AxiosResponse) => {
                callBackDownload(res, fn);
            })
            .finally(() => {
                setLoading && setLoading(false);
                setTimeout(() => {
                    document.body.style.overflow = '';
                    document.body.style.paddingRight = '';
                }, 500);
            });
    }

    //표준 목록조회
    public static getList<T = any>(params: RequestSearchType, path?: string) {
        return (
            AdlyAiServer.get<MediaResponseType<T>>(path || this.path, {
                params: params,
                headers: {useAccount: false},
            })
        );
    }


    //표준 상세조회
    public static get<T = any>(id: string) {
        return AdlyAiServer.get<MediaResponseType<T>>(`${this.path}/${id}`, {
                headers: {useAccount: false},
        });
    }

    //표준 수정
    public static put<S = any>(body: S) {
        return AdlyAiServer.put(this.path, body, {headers: {useAccount: false}});
    }

    //표준 삭제
    public static delete<S = any>(body: S) {
        return AdlyAiServer.delete(this.path, {data: body, headers: {useAccount: false}});
    }

    //표준 다운로드
    public static download(
        filters: RequestFilterType,
        setLoading: Dispatch<SetStateAction<boolean>>
    ) {
        this.downloadCall(this.downloadPath, filters, undefined, setLoading);
    }

    //표준 추가
    public static post<T=any, S = any>(body: T, params?: any) {
        return AdlyAiServer.post<S>(this.path, body, {params: params, headers: {useAccount: false}});
    }
}
