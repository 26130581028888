/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import {KakaoMomentService} from '../../../common/services';
import {StatReportInfoType} from '../../../common/services/models/kakaomoment/StatReportType';
import {useUserContext} from '../../../contexts/UserContext';
import KakaoMomentCode from '../../../modules/code/KakaoMomentCode';
import AdploButton from '../../components/buttons/AdploButton';
import ExplorerSearchBar from '../../explorer/ExplorerSearchBar';
import CustomCard from '../../modules/card/CustomCard';
import ExplorerList from './ExplorerList';
import {ExplorerTitle} from './StatInfoData';

export function Explorer() {
    const userContext = useUserContext();
    const [level, setLevel] = useState<string>('');
    const [search, setSearch] = useState<string>('');
    const [from, setFrom] = useState<number>(0);
    const size = 10; //[size, setSize] = useState<number>(10);
    const [result, setResult] = useState<StatReportInfoType[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [showDetail, setShowDetail] = useState<boolean>(false);
    const [selectData, setSelectData] = useState<StatReportInfoType | undefined>();

    useEffect(() => {
        const data = userContext.userSelect.data?.explorer;
        onSearch(data?.level || '', data?.search || '', 0);
    }, [userContext.userSelect.data?.explorer]);

    const onSearch = (level: string, search: string, from: number) => {
        setSearch(search);
        setLevel(level);
        setFrom(from);
        setShowDetail(false);
        if (from === 0) {
            setResult([]);
            setTotal(0);
            setLoaded(false);
        }
        if (!search || !level) {
            setResult([]);
            setTotal(0);
            setLoaded(true);
            return;
        }

        userContext.modalLoading.show();
        KakaoMomentService.explorer<StatReportInfoType>(level, search, from, size)
            .then((res) => {
                const data = res.data?.result;
                if (from > 0) {
                    const searchData: StatReportInfoType[] = data?.search || [];
                    searchData.forEach((v) => result.push(v));
                    setResult(result);
                } else {
                    const searchData: StatReportInfoType[] = data?.search || [];
                    setResult(searchData || []);
                    setTotal(data?.totalCount || 0);
                }
            })
            .catch((e) => {
                userContext.alertMessage.add({
                    variant: 'danger',
                    title: 'Explorer 데이터 조회 오류',
                    body: (
                        <>
                            Explorer 데이터 조회 중 오류가 발생했습니다.
                            <br />
                            {KakaoMomentService.ErrorMessage(e)}
                        </>
                    ),
                });
            })
            .finally(() => {
                userContext.modalLoading.hide();
                setLoaded(true);
            });
    };

    const moreSearch = () => {
        onSearch(level, search, from + size);
    };

    return (
        <>
            <ExplorerSearchBar
                className='mb-5'
                level={level}
                search={search}
                onSearch={onSearch}
                options={KakaoMomentCode.getOptions(KakaoMomentCode.data.options.ExplorerLevel)}
            />

            {loaded && (
                <>
                    {!total && (
                        <CustomCard className='text-center mb-6'>검색결과가 없습니다. </CustomCard>
                    )}
                    {total > 0 && (
                        <CustomCard className='mb-6 fw-bold'>
                            <Container className='d-flex p-0 py-1'>
                                <Container className='align-content-center fs-6 col-9'>
                                    {!showDetail && (
                                        <>
                                            {KakaoMomentCode.getLabel(
                                                KakaoMomentCode.data.options.ExplorerLevel,
                                                level || ''
                                            )}{' '}
                                            검색결과{' '}
                                            <span className='text-danger'>{total.addComma()}</span>{' '}
                                            건
                                        </>
                                    )}
                                    {showDetail && (
                                        <ExplorerTitle
                                            level={level}
                                            data={selectData}
                                            search={search}
                                        />
                                    )}
                                </Container>
                                <Container className='col-3 justify-content-end text-right p-0'>
                                    {showDetail && (
                                        <AdploButton
                                            style={{margin: '-0.735em 0'}}
                                            onClick={() => {
                                                setShowDetail(false);
                                            }}
                                        >
                                            검색결과로 이동
                                        </AdploButton>
                                    )}
                                </Container>
                            </Container>
                        </CustomCard>
                    )}

                    {total > 0 && (
                        <ExplorerList
                            data={result}
                            level={level}
                            search={search}
                            more={total > from + size ? moreSearch : undefined}
                            detailState={[showDetail, setShowDetail]}
                            onChange={(data?: StatReportInfoType) => setSelectData(data)}
                        />
                    )}
                </>
            )}
        </>
    );
}
