/* eslint-disable react/jsx-no-target-blank */
import clsx from 'clsx';
import {CSSProperties, useEffect, useState} from 'react';
import Config from '../../../../../../app/common/conf/Config';
import {AdGroupSimpleResType} from '../../../../../../app/common/services/models/google/AdGroupTypes';
import {CampaignSimpleResType} from '../../../../../../app/common/services/models/google/CampaignTypes';
import {TreeResDataType} from '../../../../../../app/common/services/models/ResponseTypes';
import {useUserContext} from '../../../../../../app/contexts/UserContext';
import GoogleCode from '../../../../../../app/modules/code/GoogleCode';
import Ellipsis from '../../../../../../app/modules/layer/Ellipsis';
import AdPloSearch from '../../../../../../app/pages/components/input/AdPloSearch';
import MoreButton from '../MoreButton';
import {SidebarTreeItem} from '../SidebarTreeItem';
import {SidebarTreeTitle} from '../SidebarTreeTitle';
import '../style.css';
import styles from '../style.module.scss';

const paging: number = 100;
const SidebarMenuMainGoogleAdsTree = () => {
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const userContext = useUserContext();

    useEffect(() => {
        if (Config.app.TreeMode.enable) {
            userContext.tree.google.set([]); //잔상방지
            userContext.modalLoading.show();
            userContext.tree.google.load().finally(() => {
                userContext.modalLoading.hide();
            });
        }
        // eslint-disable-next-line
    }, [userContext.userSelect.accountState?.account]);

    const basePath: string = '/pages/google/tree';
    const data = userContext.tree.google.data.filter(
        (v) =>
            !search ||
            v.data.campaignName.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
    );
    const viewData = data.slice(0, (page + 1) * paging);

    return (
        <SidebarTreeTitle
            to={`${basePath}`}
            icon='/media/icons/duotune/general/gen022.svg'
            title='Google Ads'
        >
            <div className='my-2'>
                <AdPloSearch
                    placeholder='캠페인 검색'
                    onChange={(v) => {
                        setSearch(v.target.value);
                    }}
                    theme='dark'
                />
            </div>
            <div className='fs-7 mt-2' style={{color: '#9D9DA6'}}>
                캠페인 : {data.length.addComma()}
            </div>
            <div className='tree_expanded'>
                {viewData.map((v: TreeResDataType<CampaignSimpleResType, AdGroupSimpleResType>) => (
                    <SidebarMenuAdGroup
                        key={v.data.campaignId}
                        value={v.data}
                        adgroup={v.children}
                        to='/pages/google/tree/campaign'
                    />
                ))}
                {viewData.length < data.length && <MoreButton onClick={() => setPage(page + 1)} />}
            </div>
        </SidebarTreeTitle>
    );
};

export default SidebarMenuMainGoogleAdsTree;

const SidebarMenuAdGroup: React.FC<{
    value: CampaignSimpleResType;
    adgroup: AdGroupSimpleResType[];
    to?: string;
}> = (props) => {
    const [useData, setUseData] = useState<AdGroupSimpleResType[]>([]);
    useEffect(() => {
        setUseData(props.adgroup);
    }, [props.adgroup]);

    const border = '1px solid var(--bs-gray-600)';
    const to: string = `${props.to}/${props.value.campaignId}`;
    return (
        <SidebarTreeTitle
            to={to}
            treeIcon={true}
            minimizeHidden={true}
            disabled={
                props.value.advertisingChannelType ==
                GoogleCode.data.type.campaignType.PERFORMANCE_MAX.value
            } //특정 타입 클릭 제외
            title={
                <>
                    {/* <IconUserLock userLock={props.value && false}/> */}
                    <Ellipsis title={props.value.campaignName}>{props.value.campaignName}</Ellipsis>
                </>
            }
        >
            <div className={clsx(styles.tree_item)}>
                {useData.map((v, index) => (
                    <SidebarTreeItem
                        key={v.adGroupId}
                        className='p-1'
                        to={`${to}/adgroup/${v.adGroupId}`}
                        disabled={
                            v.advertisingChannelType ==
                            GoogleCode.data.type.campaignType.PERFORMANCE_MAX.value
                        } //특정 타입 클릭 제외
                        title={
                            <>
                                <div className={styles.tree_item_bar}></div>
                                {/* <IconUserLock userLock={v && false} style={{fontSize:'1.2em'}}/> */}
                                <Ellipsis title={v.adGroupName}>{v.adGroupName}</Ellipsis>
                            </>
                        }
                    />
                ))}
            </div>
        </SidebarTreeTitle>
    );
};

const IconUserLock: React.FC<{userLock?: boolean; style?: CSSProperties}> = (props) => (
    <span
        className={clsx('me-1', props.userLock ? 'text-danger' : 'text-success')}
        style={{fontSize: '1.5em', lineHeight: '100%', ...props.style}}
    >
        ●
    </span>
);
