import clsx from 'clsx';
import DOMPurify from "dompurify";
import { omit } from 'lodash';
import { HtmlHTMLAttributes, useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { OptionType } from '../../../modules/types/Types';
import { ExplorerStorageType } from '../../common/helpers/ExplorerStorage';
import { AuthService } from '../../common/services';
import AdlyAiService from '../../common/services/adlyai/AdlyAiService';
import { AiChatPostType, AiChatResType } from '../../common/services/models/adlyai/AiChatTypes';
import { EntitySimpleType } from '../../common/services/models/PublicTypes';
import { MediaResponseType } from '../../common/services/models/ResponseTypes';
import { useUserContext } from '../../contexts/UserContext';
import imgWait from '../../images/writing-loading.gif';
import AdlyAiCode from '../../modules/code/AdlyAiCode';
import Code from '../../modules/code/Code';
import { IconAi } from '../../modules/icon';
import Ellipsis from '../../modules/layer/Ellipsis';
import TextButton from '../../modules/layer/TextButton';
import AdploButton from '../components/buttons/AdploButton';
import CustomCard from '../modules/card/CustomCard';
import Utils2 from '../modules/Utils';
import AiExplorerCharts, { AiExplorerChartsProps } from './AiExplorerCharts';
import { getEntityCodeInfo, getEntityDataCsv, getEntityDataJson, getExplorerData } from './AiExplorerData';
import CustomSetting, { CustomSettingType } from './CustomSetting';
import IndicatorSelect from './indicator/IndicatorSelect';
import _qlists from './qlist.json';
import { QListResType } from './QListTypes';
import styles from './style.module.scss';



/** 메세지를 큐에 누적하여 출력한다. */
interface MessageStackType {
    selectQ?: QListResType;
    indicators?: OptionType[];
    chatContent?: AiChatResType[];
    data?: any[];
}
let _SessionKey:number = 0;
const AiExplore: React.FC = () => {
    const userContext = useUserContext();
    const [selectQ, setSelectQ] = useState<QListResType|undefined>(undefined);
    const [indicators, setIndicators] = useState<OptionType[]>([]);
    const [showWait, setShowWait] = useState<boolean>(false);
    const [chatContent, setChatContent] = useState<AiChatResType[]>([]);
    const [confirm, setConfirm] = useState<boolean>(false);
    const [messageStack, setMessageStack] = useState<MessageStackType[]>([]);
    const [customSetting, setCustomSetting] = useState<CustomSettingType|undefined>();
    const [useData, setUseData] = useState<any[]>([]);

    const user = AuthService.storage.get();
    const isAdmin: boolean | undefined = user?.userInfo.authorities.includes('ROLE_IAM_ADMIN');

    useEffect(()=>{
        reset();
    }, [userContext.explorerStorage.data, userContext.explorerStorage.data.updateTime]);

    console.log();

    // 초기화
    const reset = ()=>{
        init();
        setMessageStack([]);
    }

    // 질의 내용 초기화
    const init = ()=>{
        setSelectQ(undefined);
        setIndicators([]);
        setShowWait(false);
        setChatContent([]);
        setConfirm(false);
        setUseData([]);
        _SessionKey = 0;
    }
    // 질의 새로 추가하기
    const messageAppend = ()=>{
        setMessageStack((prev)=>{
            prev.push({selectQ, indicators, chatContent, data:useData});
            return [...prev];
        });
        init();
    }

    const messageSend = async (inds?:OptionType[])=>{
        const explorerStorage = userContext.explorerStorage.data;

        setShowWait(true);
        // setChatContent(undefined);
        inds = inds || indicators || [];
        const ids:string[] = explorerStorage?.clientIds?.map((v)=>`${v.mediaType}_${v.clientId}`) || [];
        const clients = userContext.userSelect.accountState?.list
            .map((v)=>({...v, clients:v.clients
                .filter((v2)=>ids.includes(`${v2.mediaType}_${v2.clientId}`))
            }))
            .filter((v)=>v.clients.length > 0) || [];

        const targetList:EntitySimpleType[] = (
            explorerStorage.type == AdlyAiCode.data.type.entryType.entity.value 
            ? explorerStorage.entityTargetList 
            : explorerStorage.pageTargetList
        ) || [];
        const explorerData = (selectQ?.source === 'explorer') ? await getExplorerData({indicators:inds, columns:explorerStorage.colums}) : undefined;
        const entityData = getEntityDataJson(explorerStorage, explorerData, inds, explorerStorage.colums, selectQ?.chart?.x);
        const entityInfo = getEntityInfo(explorerStorage);
        const messageContent:string = (selectQ?.send
            .replaceAll("{_V_NAME_}", entityInfo.name || '')
            .replaceAll("{_V_LEVEL_}", entityInfo.level || '')
            .replaceAll("{_V_COUNT_}", entityInfo.count?.addComma() || '')
            .replaceAll("{_V_TARGET_}", inds?.map((v)=>v.label).join(',') || '')
            .replaceAll("{_V_DATA_}", JSON.stringify(entityData.daily || entityData))
            .replaceAll("{_V_DATA_EXT_}", JSON.stringify(entityData))
            .replaceAll("{_V_DATA_JSON_}", JSON.stringify(entityData))
            .replaceAll("{_V_DATA_CSV_}", getEntityDataCsv(explorerStorage, explorerData, inds, explorerStorage.colums))
             || '') + '\n' + customSetting?.message;
        console.log(messageContent);
        setUseData(entityData);
    
        const body:AiChatPostType = {
            appId: 1,
            modelId: customSetting?.modelId || 1,
            promptId: selectQ?.id || 1,
            contentId: 1,
            entryTypeCode: explorerStorage.type || '',
            targetList: clients.map((v)=>({
                magId: v.magId,
                magName: v.magName,
                clientList: v.clients.map((v2)=>({
                    mediaType: v2.mediaType,
                    mediaTypeCode: Code.get(Code.base.mediaType, v2.mediaType)?.code || v2.mediaType,
                    clientId: v2.clientId,
                    clientName: v2.name || v2.clientName || '',
                }))
            })),
            perfMetrics: inds?.map((v)=>v.label).join(',') || '',
            requestTarget: {
                adLevel: explorerStorage.level || '',
                mediaTypeCode: explorerStorage.media || '',
                data: targetList,
                perf_period: [userContext.userSelect.data?.dateRange?.startDate, userContext.userSelect.data?.dateRange?.endDate].join('~')
            },
            inputDataArr: [{role: "user", content: messageContent}],
            maxTokens: customSetting?.maxTokens || 6000,
            temperature: customSetting?.temperature.toNumber() || 0.2,
        };

        // API 호출 사용 안함
        if(customSetting?.useApiCall === false){
            setShowWait(false);
            setConfirm(true);
            setChatContent((prev)=>{
                prev.push({
                    id:"12345", object: '', created: 0, model: '',
                    choices: [{index: 0, message: {role: "user", content: "AI API 호출을 사용하지 않습니다. API 호출을 사용하려면 설정을 변경하세요."}}],
                });
                return [...prev];
            });
            return;
        }

        const sessionKey = new Date().getTime();    // 메세지 호출과 수신을 구분하기 위한 키
        _SessionKey = sessionKey;
        console.log(sessionKey)
        const message = await AdlyAiService.openai.post<AiChatPostType, MediaResponseType<AiChatResType>>(body).then((res)=>{ 
            return res.data.result;
        })
        .catch((e)=>{
            return {
                id:'error',
                object: '',
                created: 0,
                model: '',
                choices: [],
                error:{
                    code: 0,
                    message: AdlyAiService.ErrorMessage(e),
                }
            };
        });

        if(_SessionKey !== sessionKey) return;   // 현재 기다리는 상태가 아닌 경우 무시
        setChatContent((prev)=>{
            prev.push(message);
            return [...prev];
        });

        setShowWait(false);
        setConfirm(true);
    }

    const selectedIndicarot = (values:OptionType[])=>{
        setIndicators(values);
        messageSend(values);
    }

    const entityName = getEntityName(userContext.explorerStorage.data);
    return <>
        <div className='d-flex' style={{position:'fixed', right:'2em', top:'70px', zIndex:1000}}>
            <TextButton className='text-gray-600' title="초기화" onClick={()=>reset()}>
                <i className="bi bi-arrow-repeat fs-5"/>초기화
            </TextButton>
        </div>
        <BotMessage>안녕하세요. <b><Ellipsis style={{maxWidth:"400px"}}>{entityName?.main}</Ellipsis>{entityName?.sub && `(${entityName.sub})`}</b>에 대한 분석 요청이 가능합니다.<br />프롬프트를 선택해 주세요.</BotMessage>
       
       {/* // 메세지 스택 */}
       {messageStack.map((v,index)=><div key={index}>
            {index>0 && <BotMessage>프롬프트를 선택해 주세요.</BotMessage>}

            <UserMessage>{v.selectQ?.label}</UserMessage>
            
            <BotMessage>분석 대상 지표를 선택해 주세요.</BotMessage>
            <UserMessage>{v.indicators?.map((v)=>v.label).join(", ")}</UserMessage>

            {v.chatContent?.map((content,index)=><div key={index}>
                <ResultBotMessage chatContent={content} useRefresh={false} messageSend={messageSend}
                    data={v.data} indicators={v.indicators} selectQ={v.selectQ} />
            </div>)}
        </div>)}
       
        {/* // 현재 진행중인 분석 */}
        {messageStack.length > 0 && <BotMessage>프롬프트를 선택해 주세요.</BotMessage>} {/* 재 질의 시 표시용 */}

        <QListSelect state={[selectQ, setSelectQ]} />

        {selectQ && <> 
            <BotMessage>분석 대상 지표를 선택해 주세요.</BotMessage>
            <IndicatorSelect colums={userContext.explorerStorage.data.colums || []} onChange={selectedIndicarot} selectQ={selectQ} />
        </>}

        {chatContent.map((content,index)=><div key={index}>
            <ResultBotMessage chatContent={content} useRefresh={!showWait && (chatContent.length - 1 == index)} messageSend={messageSend}
                data={useData} indicators={indicators} selectQ={selectQ} />
        </div>)}

        {!showWait && confirm && <CustomCard className='mt-6'>
            <Container className='d-flex justify-content-center p-0'>
                <Container className='p-0 justify-content-start align-content-center'>
                    다른 프롬프트로 추가 분석을 요청하고 싶으신가요?
                </Container>
                <Container className='p-0 justify-content-end text-right'>
                    <AdploButton onClick={()=>{setConfirm(false); messageAppend();}}><div className='mx-6'>네</div></AdploButton>
                </Container>
            </Container>
        </CustomCard>}

        {showWait && <BotMessage wait={true}>분석 중 입니다. 잠시만 기다려 주세요.</BotMessage>}

        <div className='h-300px'>
            {isAdmin && <CustomSetting onChange={(v)=>setCustomSetting(v)}/>}
        </div>
    </>
}


const ResultBotMessage: React.FC<{chatContent:AiChatResType, useRefresh:boolean, messageSend:()=>void} & AiExplorerChartsProps> = (props) => {
    const completiondiv = useRef<HTMLDivElement>(null);
    const copyCompletion = () => { 
        Utils2.copyHtmlToClipboard(completiondiv.current);
    }

    useEffect(() => {
        // script 태그가 실행되지 않는 문제 해결
        const scripts = document.querySelectorAll(".gpt-response script");
        if (scripts) {
          for (let i = 0; i < scripts.length; i++) {
            const script = document.createElement("script");
            script.textContent = scripts[i].textContent;
            document.body.appendChild(script);
          }
        }
      }, [props.chatContent?.choices]);
      
    return <>
        <BotMessage>
            <div ref={completiondiv}>
                {props.chatContent?.choices?.map((v,index)=>{
                    // const text = v?.message.content.replaceAll('```html','').replaceAll('```','');
                    // HTML 코드 블록 감지 및 제거
                    const cleanText = v?.message.content.replace(/```html([\s\S]*?)```/g, "$1");
                    // XSS 방지 필터 적용 (script 태그 허용)
                    const sanitizedHtml = DOMPurify.sanitize(cleanText, { ADD_TAGS: ["script", "img"] });
                    
                    return <div key={index}>
                        <div id="gpt-response" dangerouslySetInnerHTML={{__html:sanitizedHtml || ''}}/>
                        <AiExplorerCharts {...props} />
                    </div>
                })}
                {/* {chatContent?.error && <div className='text-danger'>{chatContent?.error.message}</div>} */}
                {props.chatContent?.error && <>답변을 생성하지 못 했습니다. '다시 답변 받기'를 클릭하여 재요청 바랍니다.<br/>동일 현상이 지속되는 경우 관리자에게 문의해 주세요.</>}
            </div>

            {/* <script>
                var myChart = echarts.init(document.getElementById('echart'));
                var option = {
                    title: {\n            text: '비즈채널 클릭수 및 비용 분포'\n        },\n        tooltip: {},\n        legend: {\n            data: ['클릭수', '비용']\n        },\n        xAxis: {\n            data: ['24,652', '300', '17,809', '61,786', '14,935.29', '104,547']\n        },\n        yAxis: {},\n        series: [{\n            name: '클릭수',\n            type: 'bar',\n            data: [24652, 300, 17809, 61786, 14935.29, 104547]\n        }, {\n            name: '비용',\n            type: 'line',\n            data: [7753823, 101519, 1797895, 2330361, 1711942.57, 11983598]\n        }]\n    };\n\n    myChart.setOption(option);\n</script> */}
        </BotMessage>

        <div className='d-flex'>
            {props.useRefresh && <div>
                <TextButton className='me-6 text-gray-600' title="다시 답변 받기" onClick={()=>props.messageSend()}>
                    <i className="bi bi-arrow-clockwise fs-5"/>
                    다시 답변 받기
                </TextButton>
            </div>}
            <div>
                <TextButton className='p-0' style={{height:'inherit !important', lineHeight:'normal'}} onClick={copyCompletion} title="복사하기">
                    <i className='bi bi-copy fs-5'/>
                </TextButton>
            </div>
        </div>
    </>
}


interface BotMessageProps extends HtmlHTMLAttributes<HTMLDivElement>{ wait?: boolean; preview?: boolean; }

/** 봇 답변 대기 메세지 표시 */
export const BotMessage:React.FC<BotMessageProps> = (props)=>
    <div className={clsx('py-3', styles.BotMessageWait)}>
        {!props.preview && <div className={clsx('fs-3', styles.Title)}>
            <IconAi />
            {props.wait && <img alt='wait' src={imgWait} style={{width:'2em'}} className='ms-6' />}
        </div>}
        <div {...omit(props,'wait')} className={clsx('py-3', styles.Content)}>{props.children}</div>
    </div>

/** 사용자 질의 메세지 표시 */
export const UserMessage:React.FC<HtmlHTMLAttributes<HTMLDivElement>> = (props)=>
    <div className={clsx('p-0 text-right my-6', styles.UserMessage)}>
        <div {...props} className='py-3 px-6'>{props.children}</div>
    </div>;


interface EntityInfoType{
    name? : string;
    id? : string;
    level? : string;
    count? : number;
}

/** 선택한 정보 표시용 정보 */
const getEntityInfo = (data:ExplorerStorageType):EntityInfoType=>{
    const targetList:EntitySimpleType[] = (
        data.type == AdlyAiCode.data.type.entryType.entity.value 
        ? data.entityTargetList 
        : data.pageTargetList
    ) || [];

    const info = getEntityCodeInfo(data.media, data.level);
    return {
        name: targetList?.[0]?.name || '',
        id: targetList?.[0]?.id || '',
        level: info?.label,
        count: targetList.length,
    }
}

interface EntityNameType{
    main?: string;
    sub?: string;
}
/** 선택한 정보 표시용 */
const getEntityName = (data:ExplorerStorageType):EntityNameType|undefined=>{
    const info = getEntityInfo(data);
    switch(data.type){
        case AdlyAiCode.data.type.entryType.entity.value : return info.name==info.id ? {main:info.name} : {main: info.name, sub:info.id};
        case AdlyAiCode.data.type.entryType.list.value : return  {main: info.level, sub:`${info.count?.addComma()}개`};
        case AdlyAiCode.data.type.entryType.dashboard.value : {
            break;
        }
    }
}

const QListSelect: React.FC<{state:[QListResType|undefined, React.Dispatch<React.SetStateAction<QListResType | undefined>>]}> = (props) => {
    const useContext = useUserContext();
    const qlists = new Map(Object.entries(_qlists)).get(useContext.explorerStorage.data.type || AdlyAiCode.data.type.entryType.entity.value) || [];
    const [selectQ, setSelectQ] = props.state;
    const onChange = (value:QListResType)=>{
        setSelectQ(value);
    }
    return <>
        {!selectQ && <div className={clsx('mt-6', styles.qlist)}>
            {qlists.map((v,index)=><div key={index} className={styles.group}>
                <div className={clsx('my-6', styles.title)}>{v.title}</div>
                <div className={styles.list}>
                    {v.items.map((v2,index2)=><div key={index2} className={clsx('my-2', styles.item)} onClick={()=>onChange(v2)}>
                        <i className={clsx("bi", v2.icon, styles.icon)}/>
                        <div className={styles.text}>{v2.label}</div>
                        <i className={clsx("bi bi-arrow-right", styles.arrow)}/>
                    </div>)}
                </div>
            </div>)}
        </div>}
        {selectQ && <UserMessage>{selectQ.label}</UserMessage>}
    </>
}


export { AiExplore };

