/* eslint-disable jsx-a11y/anchor-is-valid */
import ApexCharts, { ApexOptions } from 'apexcharts';
import { useEffect, useRef } from 'react';
import { getCSS } from '../../../../_metronic/assets/ts/_utils';
import { ChartsProps } from './Types';


const ScatterCharts:React.FC<ChartsProps> = (props) => {
    const chartRef = useRef<HTMLDivElement | null>(null);
    const refreshChart = () => {
        if (!chartRef.current) { return; }
        const height = parseInt(getCSS(chartRef.current, 'height'));
        const chart = new ApexCharts(chartRef.current, getChartOptions(height, props));
        if (chart) { chart.render(); }
        return chart;
    }

    useEffect(() => {
        const chart = refreshChart();
        return () => { chart && chart.destroy(); }
    // eslint-disable-next-line
    }, [chartRef, props.data]);

    return <div ref={chartRef} className={props.className} style={props.style} />
}

export default ScatterCharts;

const getChartOptions = (height: number, props:ChartsProps): ApexOptions => {
    const name = props.data?.[0]?.name?.split("|") || ['지표1', '지표2'];
    return {
        series: props.data.map((v)=>( { data: v.data, })),
        chart: {
            // fontFamily: 'inherit',
            type: 'scatter',
            stacked: true,
            height: height || 350,
            
            toolbar: { show: false, offsetX:0, offsetY:0, },
            zoom: { enabled: true },
            background: "transparent",
        },
       
        legend: { show: true, position: 'bottom', offsetY: 0 },//범례

        grid: {
            strokeDashArray: 4,
            yaxis: { lines: { show: true, }, },
            padding: { top: 0, bottom: 0, right:50, left: 30, },
        },

        dataLabels: {
            enabled: false,
            enabledOnSeries: undefined,
            textAnchor: 'middle',
            distributed: false,
            offsetX: 0,
            offsetY: 0,
            background: {
                enabled: true,
                foreColor: '#fff',
                padding: 4,
                borderRadius: 2,
                borderWidth: 1,
                borderColor: '#fff',
                opacity: 0.9,
                dropShadow: {
                    enabled: false,
                    top: 1,
                    left: 1,
                    blur: 1,
                    color: '#000',
                    opacity: 0.45
                }
            },
            dropShadow: {
                enabled: false,
                top: 1,
                left: 1,
                blur: 1,
                color: '#000',
                opacity: 0.45
            }
        },


        xaxis: {
            title: { text: name[0], offsetY: 0},
            axisBorder: { show: false, },
            axisTicks: { show: true, },
            labels: {
                style: { colors:"var(--bs-gray-600)", fontSize:'0.9em' },
                showDuplicates: false,
                formatter: (v)=>parseFloat(v).toFixed(1)
            },
            tooltip: { enabled: true, }
        },
        yaxis: {
            title: { text: name[1], offsetX: 0 },
            axisBorder: { show: false, },
            axisTicks: { show: true, },

            labels: {
                style: { colors:"var(--bs-gray-600)", fontSize:'0.9em' },
                formatter: (v)=>v.toFixed(1),
                offsetX: 15,
            },
            tooltip: { enabled: true, },
        },
        states: {
            normal: { filter: { type: 'lighten',  value: 0.03,} }, //'lighten', 'darken', 'none'
            hover: { filter: { type: 'lighten', value: 0.15, } },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: { type: 'darken', value: 0.5, }
            },
        },

        // tooltip: {
        //     style: { fontSize: '0.9em', },
        //     x: {
        //         show:true,
        //         formatter: (v)=>format(v, "yyyy-MM-dd"),
        //     },
        //     y: [
        //         { formatter: (v)=>v.toFixed(1) },
        //         { formatter: (v)=>v.toFixed(1) },
        //     ],
        //     followCursor:true
        // },
        tooltip: {
            custom: ({ seriesIndex, dataPointIndex, w }) => {
              const point = w.config.series[seriesIndex].data[dataPointIndex];
              return `<div class="fs-7 border border-gray-300" style="border-radius: 5px; box-shadow: 2px 2px 5px rgba(0,0,0,0.1);">
                        <div class="p-1 px-3 bg-gray-200 fw-bold border-bottom border-gray-300">${point.date}</div>
                        <div class="p-1 px-3">
                            ${name[0]}: ${(point.x as number|undefined)?.toString().addComma()}<br/>
                            ${name[1]}: ${(point.y as number|undefined)?.toString().addComma()}
                        </div>
                      </div>`;
            },
          },
    }
}
