import html2canvas from "html2canvas";
import { useEffect, useRef, useState } from 'react';
import { OptionType } from '../../../modules/types/Types';
import TextButton from '../../modules/layer/TextButton';
import BarLineCharts2 from "../modules/charts/BarLineCharts2";
import BoxPlotCharts from "../modules/charts/BoxPlotCharts";
import ScatterCharts from '../modules/charts/ScatterCharts';
import { ChartsDataItemType, ChartsDataType } from "../modules/charts/Types";
import { EntityDataType } from "./AiExplorerData";
import { QListResType } from './QListTypes';


export interface AiExplorerChartsProps{
    data?: any[];
    indicators?: OptionType[];
    selectQ?: QListResType;
}

const AiExplorerCharts: React.FC<AiExplorerChartsProps> = (props) =>{
    const refDiv = useRef<HTMLDivElement | null>(null);

    const copyToClipboard = async () => {
        if (!refDiv.current) { return; }
        const canvas = await html2canvas(refDiv.current);
        canvas.toBlob((blob) => {
            if (blob) {
                const item = new ClipboardItem({ "image/png": blob });
                navigator.clipboard.write([item])
                    .then(() => alert("이미지가 클립보드에 복사되었습니다!"))
                    .catch((err) => console.error("복사 실패:", err));
            }
        });
    }
    return <>
        {props.selectQ?.chart && <>
            <div style={{position:"absolute", marginTop:"24px", right:"36px", zIndex:1}}>
                <TextButton onClick={copyToClipboard} className="text-gray-600 fs-7" title="그래프 복사"><i className="bi bi-copy"/> 이미지 복사</TextButton>
            </div>
            <div ref={refDiv} className='bg-body my-3'>
                <div className="pt-6 ps-10 fw-bold">{props.selectQ?.label}</div>
                {props.selectQ?.chart?.type == "scatter" && <ScatterChart {...props} />}
                {props.selectQ?.chart?.type == "boxplot" && <BoxPlotChart {...props} />}
                {props.selectQ?.chart?.type == "mixed" && props.selectQ?.chart?.x?.toString()=='date' && <MixedChart {...props} />} 
                {props.selectQ?.chart?.type == "mixed" && props.selectQ?.chart?.x?.toString()=='weekly' && <MixedChartWeekly {...props} />} 
                {props.selectQ?.chart?.type == "mixed" && props.selectQ?.chart?.x?.toString()=='monthly' && <MixedChartMonthly {...props} />} 
            </div>
        </>}
    </>
}


export default AiExplorerCharts;


const ScatterChart: React.FC<AiExplorerChartsProps> = (props) => {
    const [useData, setUseData] = useState<ChartsDataType[]>([]);
    useEffect(()=>{
        const tmp = props.data as EntityDataType|any|undefined;
        const resource:any[] = tmp?.monthly || tmp?.weekly || tmp?.daily || tmp;
        // console.log(resource);
        const data:ChartsDataItemType[] = resource?.filter((v,index, arr)=>index>0 && index < arr.length-2)  //첫번째 행은 헤더, 마지막은 total, average
            .map((v):ChartsDataItemType=>{
                const key1 = props.indicators?.[props.selectQ?.chart?.xAxisIndex || 0]?.value || '';
                const key2 = props.indicators?.[props.selectQ?.chart?.yAxisIndex || 1]?.value || '';
                return {
                    x: parseFloat(v[key1]?.toString().removeComma() || 0),
                    y: parseFloat(v[key2]?.toString().removeComma() || 0), 
                    date: v.date as string
                };
            }) || [];
        setUseData([{
            data: data,
            name: props.indicators?.map((v)=>v.label).join("|") || '지표1',
        }]);
    },[props.data, props.selectQ, props.indicators]);

    return <ScatterCharts data={useData} />
}

const BoxPlotChart: React.FC<AiExplorerChartsProps> = (props) => {
    const [useData, setUseData] = useState<{x:string, y:number[]}[]>([]);
    useEffect(()=>{
        const tmp = props.data as EntityDataType|any|undefined;
        const resource:any[] = tmp?.monthly || tmp?.weekly || tmp?.daily || tmp;

        const data:{x:string, y:number[]}[] = [];
        props.indicators?.forEach((v, index)=>{
            const tmp = resource?.filter((v2,index2, arr)=>index2>0 && index2 < arr.length-2)  //첫번째 행은 헤더, 마지막은 total, average
                .map((v2):number=>{
                    return parseFloat(v2[v.value]?.toString().removeComma() || 0);
                }) || [];
            data.push({
                x: v.label,
                y: tmp,
            });
        });
        setUseData(data);
    },[props.data, props.selectQ, props.indicators]); 

    return <BoxPlotCharts data={[{data:useData}]} type="datetime" />
}
//[{type:string, name:string, data: [date,number][]}]
const MixedChart: React.FC<AiExplorerChartsProps> = (props) => {
    const [useData, setUseData] = useState<ChartsDataType[]>([]);
    useEffect(()=>{
        const tmp = props.data as EntityDataType|any|undefined;
        const resource:any[]|undefined = ((tmp?.monthly || tmp?.weekly || tmp?.daily || tmp) as any[]|undefined)
            ?.filter((v,index, arr)=>index>0 && index < arr.length-2); //첫번째 행은 헤더, 마지막은 total, average

        const data:ChartsDataType[] = [];
        props.indicators?.forEach((v, index)=>{
            const tmp = resource?.map((v2):[number,number]=>{
                    const key = props.selectQ?.chart?.x?.toString() || 'date';
                    return [ key=='date' ? new Date(v2.date).getTime() : v2[key] , parseFloat(v2[v.value]?.toString().removeComma() || 0)];
                }) || [];
            data.push({
                data: tmp,
                name: v.label,
                type: props.selectQ?.chart?.y?.[index] || 'line',
            });
        });
        setUseData(data);
    },[props.data, props.selectQ, props.indicators]); 

    return <BarLineCharts2 data={useData} type="datetime" />
}

const MixedChartWeekly: React.FC<AiExplorerChartsProps> = (props) => {
    const [useData, setUseData] = useState<ChartsDataType[]>([]);
    const [categories, setCategories] = useState<string[]|undefined>();
    useEffect(()=>{
        const tmp = props.data as EntityDataType|any|undefined;
        const resource:any[] = (tmp?.weekly as any[]|undefined || [])?.filter((v,index, arr)=>index>0 && index < arr.length-2); //첫번째 행은 헤더, 마지막은 total, average

        //카테고리 수집
        const cate:string[]|undefined = resource?.map((v)=> v.week || '');
        setCategories(cate);

        const data:ChartsDataType[] = [];
        props.indicators?.forEach((v, index)=>{
            const tmp = resource.map((v2):number=>{
                return parseFloat(v2[v.value]?.toString().removeComma() || 0);
            }) || [];
            data.push({
                data: tmp,
                name: v.label,
                type: props.selectQ?.chart?.y?.[index] || 'line',
            });
        });
        setUseData(data);
    },[props.data, props.selectQ, props.indicators]); 

    return <BarLineCharts2 data={useData} categories={categories} />
}

const MixedChartMonthly: React.FC<AiExplorerChartsProps> = (props) => {
    const [useData, setUseData] = useState<ChartsDataType[]>([]);
    const [categories, setCategories] = useState<string[]|undefined>();
    useEffect(()=>{
        const tmp = props.data as EntityDataType|any|undefined;
        const resource:any[] = (tmp?.monthly as any[]|undefined || [])
            ?.filter((v,index, arr)=>index>0 && index < arr.length-2); //첫번째 행은 헤더, 마지막은 total, average

        //카테고리 수집
        const cate:string[]|undefined = resource?.map((v)=> v.month || '');
        setCategories(cate);

        const data:ChartsDataType[] = [];
        props.indicators?.forEach((v, index)=>{
            const tmp = resource?.map((v2):number=>{
                    return parseFloat(v2[v.value]?.toString().removeComma() || 0);
                }) || [];
            data.push({
                data: tmp,
                name: v.label,
                type: props.selectQ?.chart?.y?.[index] || 'line',
            });
        });
        setUseData(data);
        console.log(resource,data);
    },[props.data, props.selectQ, props.indicators]); 

    return <BarLineCharts2 data={useData} categories={categories} />
}