import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { AuthService } from '../../common/services';
import TextButton from '../../modules/layer/TextButton';
import AdPloInput from '../components/input/AdPloInput';
import AdPloSelect from '../components/select/AdPloSelect';
import CustomCard from '../modules/card/CustomCard';
import { Switch } from '../modules/form';


export interface CustomSettingType{
    modelId: number;
    maxTokens: number;
    temperature: string;
    message: string;
    useApiCall?: boolean;
}


/** 메세지를 큐에 누적하여 출력한다. */
interface CustomSettingProps {
    onChange?: (value?:CustomSettingType)=>void;
}

const CustomSetting: React.FC<CustomSettingProps> = (props) => {
    const [customSetting, setCustomSetting] = useState<CustomSettingType>({modelId:1, maxTokens:6000, temperature:"0.2", message:'', useApiCall:true});
    const [showCustomSetting, setShowCustomSetting] = useState<boolean>(false);

    const user = AuthService.storage.get();
    const isAdmin: boolean | undefined = user?.userInfo.authorities.includes('ROLE_IAM_ADMIN');

    useEffect(()=>{
        if(props.onChange) props.onChange({modelId:1, maxTokens:6000, temperature:"0.2", message:'', useApiCall:true});
    }, []);

    useEffect(()=>{
        if(props.onChange) props.onChange(customSetting);
    }, [customSetting]);

    return <>
        {!showCustomSetting && <>
            <div className='h-300px'></div>
            {isAdmin && <div style={{position:'fixed', bottom:"0.725em",  marginLeft:"-1.5em",}} >
                <TextButton onClick={()=>setShowCustomSetting(!showCustomSetting)}><i className="bi bi-sliders2-vertical"/></TextButton>
            </div>}
        </>}

        {showCustomSetting && <CustomCard className='mt-12 bg-gray-300' title='Custom Setting' style={{position:'fixed', bottom:'0.5em', right:'180px', width:'600px', zIndex:1}}>
            <Container className='d-flex p-0 mb-6 text-left'>
                <Container className='col-4 text-left'>
                    <Switch checked={customSetting.useApiCall} 
                        title='Use AI API' checkBox={true} size='sm' 
                        onChange={(v:React.ChangeEvent<HTMLInputElement>)=>setCustomSetting({...customSetting, useApiCall:v.currentTarget.checked})}>
                            AI-Api 호출여부</Switch>
                    <div className='text-gray-600 fs-8'>* 그래프 테스트할 때 사용하세요.</div>
                </Container>
                <Container className='col-8 text-left'>
                </Container>
            </Container>

            <Container className='d-flex p-0 my-6'>
                <Container className='col-4'>
                    <div style={{position:'absolute', right:'0.5em', top:'0.725em'}}>
                        <TextButton onClick={(e)=>setShowCustomSetting(false)}><i className="bi bi-x-lg"/></TextButton>
                    </div>

                    <span>Model</span>
                    <div>
                        <AdPloSelect
                            options={[{value:"1", label:"gpt-4o-mini"}]}
                            value={customSetting?.modelId.toString()}
                            className='bg-body'
                            onChange={(v)=>setCustomSetting({...customSetting, modelId:v.toNumber()})}
                        />
                    </div>
                </Container>

                <Container className='col-4'>
                    <span>Max Tokens</span>
                    <div>
                        <AdPloInput type="text" className='form-control' placeholder='Max Tokens' value={customSetting.maxTokens} 
                            onChange={(e)=>setCustomSetting({...customSetting, maxTokens:e.target.value.toNumber()})} />
                    </div>
                </Container>
                
                <Container className='col-4'>
                    <span>Temperature : {customSetting.temperature}</span>
                    <div className='pt-3'>
                        <input type="range" className='w-100' placeholder='Temperature' value={customSetting.temperature}
                            min={0} max={1.2} step={0.1}
                            onChange={(e)=>setCustomSetting({...customSetting, temperature:e.target.value})} />
                    </div>
                </Container>
            </Container>
            <Container className='mt-6'>
                <span>Append Message</span>
                <div>
                    <textarea className='form-control h-100px' placeholder='Message' value={customSetting.message} 
                        onChange={(e)=>setCustomSetting({...customSetting, message:e.target.value})} />
                </div>
            </Container>
        </CustomCard>}
    </>
}

export default CustomSetting;