import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { FormCheckProps } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { Option, OptionType } from '../../../../modules/types/Types';
import Utils from '../../../../modules/utils/Utils';
import { RequestFilterType, RequestOrderType } from '../../../common/services/models/RequestTypes';
import AdploButton from '../../components/buttons/AdploButton';
import DeviceTypeButton from '../../components/buttons/DeviceTypeButton';
import AdPloSearch from '../../components/input/AdPloSearch';
import ColumnList from './ColumnList';
import Filter from './Filter';
import PaginationBootstrap from './Pagenation';
import { CheckedListType, FilterOptionType, FilterPropType, FilterType, FormatterPropType, HeaderColumn, TablePropsType } from './TableType';
import TableUtils from './Utils';
import styles from './table.column.module.scss';
import './table.css';

const Checkbox = (props:FormCheckProps) =>(<input style={{margin:0}} {...props} type="checkbox" />);
export const SortKeys = {
    Asc:'ASC',
    Desc:'DESC',
}

const Table = <RowDataType extends any>(props: TablePropsType<RowDataType>) => {
    const refDiv = useRef<HTMLDivElement>(null);
    const [headerColumns, setHeaderColumns] = useState<HeaderColumn[]>([]);
    const [selectRow, setSelectRow] = useState<string>('');
    const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
    const [sorting, setSorting] = useState<RequestOrderType>({key:'', direction:''});
    const [sortKey, setSortKey] = useState<string>('');
    const [search, setSearch] = useState<string>('');
    const useGroupType = headerColumns.filter((v) => v.group).length > 0;     //그룹 사용여부 확인

    // 컬럼 제어 옵션을 사용하지 않는 경우, 기본 처리
    useEffect(()=>{
        if(props.showColumns===false){
            setHeaderColumns(props.columns);
        }
    },[props.showColumns, props.columns]);

    useEffect(()=>{setSearch(props.searchValue || '')}, [props.searchValue]);

    useEffect(()=>{
        const order:RequestOrderType = props.sorts?.[0] || {key:'', direction:''};
        setSorting(order);
        const col = headerColumns.find((v)=>v.useSort!==false && (v.key || v.accessor)===order.key);
        const key = col?.key || col?.accessor || '';  //그룹사용하는 건들
        setSortKey(key);
    // eslint-disable-next-line
    },[props.sorts]);

    // Table 레이어 모니터링
    useEffect(()=>{
        if(refDiv.current){
            refDiv.current.onmousemove = (e)=>{
                if(refDiv.current){
                    refDiv.current.style.height = "";
                    // console.log(refDiv.current?.scrollHeight , refDiv.current?.clientHeight);
                    if(refDiv.current.scrollHeight > refDiv.current.clientHeight){
                        refDiv.current.style.height = (refDiv.current.scrollHeight +50) + "px";
                    }
                }
            };
        }
    }, [refDiv.current]);


    // Filter values
    const [useFilters,setUseFilters] = useState<RequestFilterType[]>([]);
    const setFilters = (data:RequestFilterType[]) => { 
        setUseFilters([...data]);
        props.setFilterValues && props.setFilterValues([...data]);
    }
    useEffect(()=>{ setUseFilters(props.filterValues || []);}, [props.filterValues]);   //외부에서 필터 정보 변경시 반영하도록 함

    //default Filter
    useEffect(()=>{ setUseFilters(props.defaultFilter || []);},[props.defaultFilter ]); //외부에서 기본필터값이 변경될 경우(BidBase 사용모드)
    
    /// Paging
    const [usePage, setUsePage] = useState<{page:number,size:number}>({page:1,size:10});
    useEffect(()=>{setUsePage({page:props.pageNum || 0, size:props.pageSize || 10})},[props.pageNum, props.pageSize]);
    const onChangePage = (page:number, size:number) => {
        setUsePage({page, size});
        props.onChangePage && props.onChangePage({page, size});
    }

    // 주요 데이터
    const [useData, setUseData] = useState<RowDataType[]>([]);   //// 데이터 관리
    const [useCheckList, setUseCheckList] = useState<CheckedListType<RowDataType>[]>([]);    // Checklist
    useEffect(()=>{
        const list:CheckedListType<RowDataType>[] = TableUtils.checklist.filter(props.data, useCheckList, props.chekcedId || '');
        setUseCheckList(list);
        setUseData(props.data);
        refreshAllCheckbox(props.data, list);
    // eslint-disable-next-line
    },[props.data]);


    /**
     * 전체 선택 관리
     * @param event 
     */
    const onCheckRowAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        const data:CheckedListType<RowDataType>[] = event.target.checked ? useData
            //선택 제한 건 제외
            .filter(v=>{
                const id = TableUtils.getId(v, props.chekcedId);
                return !props.checkDisabled || !props.checkDisabled.includes(id)
            })
            .map(v=>{
                const id = TableUtils.getId(v, props.chekcedId);
                return {id:id, data:v};
            }) : [];

        setUseCheckList(data);
        setIsSelectAll(checked);
        props.setCheckIds && props.setCheckIds(data.map((v)=>v.data));
    }
    
    /**
     * 개별 선택 관리
     * @param e 
     * @param rowData 
     */
    const onCheckRow = (e:React.ChangeEvent<HTMLInputElement>, id:string, rowData:RowDataType) => {
        let prev = useCheckList.filter((v)=>v.id!==id);     //state 오류로 prev 사용 못함
        if(e.target.checked){ prev.push({id:id, data:rowData}); }
        setSelectRow(id);   //현재 선택라인 설정
        refreshAllCheckbox(useData, prev);
        props.setCheckIds && props.setCheckIds(prev.map((v)=>v.data));   //상위노드에 변경내용 반영
        setUseCheckList( prev);
    }

    /**
     * 전체 선택여부를 표시합니다.
     * @param data 
     * @param checked 
     * @returns 
     */
    const refreshAllCheckbox = (data?:RowDataType[], checked?:CheckedListType<RowDataType>[])=>{
        //선택 제외 데이터 반영하여 처리
        const rData:RowDataType[] = ((data || useData) || []).filter((v)=>{
            const id = TableUtils.getId(v, props.chekcedId);
            return !props.checkDisabled || !props.checkDisabled.includes(id);
        });
        const rChecked:CheckedListType<RowDataType>[] = ((checked || useCheckList) || []).filter((v)=>!props.checkDisabled || !props.checkDisabled.includes(v.id));
        setIsSelectAll(rData.length>0 && rData.length === rChecked.length ? true : false);
    }

    const handleSort = (key:string, accesor:string, column?:HeaderColumn) => {
        if(!props.handleSort){ return; }
        let sortValue = column?.defaultSortDirection || SortKeys.Asc;
        if(sorting.key === key) {
            const list = [SortKeys.Asc, SortKeys.Desc];   //순환순서
            sortValue = list[ (list.indexOf(sorting.direction)+1) % list.length ];
        }
        const order:RequestOrderType = {key:(key || accesor), direction: sortValue};
        setSorting(order);
        setSortKey(key);
        props.handleSort(order);
    }

    //필터 초기화처리
    const onReset = ()=>{
        setIsSelectAll(false);
        setFilters(props.defaultFilter || []);   //외부에서 입력받은 기본필터값으로 변경
    }
                                      
    /** 전체컬럼 수 */
    const colspan =  (props.checkbox || props.filterable ? 1 : 0) + headerColumns.length;

    const handleDeviceTypeChange = (value:string, item?:OptionType) => {
        // const key = 'diviceTypes';
        // const tmp = useFilters.filter(v=>v.key !== key);
        // tmp.push({key,value:value, operation:'EQ'});
        // setFilters(tmp);
        props.onChangeDeviceType && props.onChangeDeviceType(value.split(','));    //필터 변경시 자동 재검색
        props.handleSearch && props.handleSearch(1);    //필터 변경시 자동 재검색
    }

    /** 검색창 & 검색 */
    const handleSearch = ()=>{
        props.handleSearch && props.handleSearch(1, undefined, undefined, search);
    }
    const searchOnKeyDown = (e:React.KeyboardEvent<HTMLInputElement>)=>{
        if(e.key === "Enter"){ handleSearch(); }
    }
    const useSort:boolean = props.sorts && props.handleSort ? true : false;
    const totalPage:number = Math.ceil((props.totalData || 0) / usePage.size);
    const isViewCheckColumn:boolean = props.checkbox || props.filterable!==false;

    /** 컬럼 선택 옵션 변경 */
    const lastHeaderColumns = headerColumns.filter((v)=>!props.columnViewOption || !v.viewFilter || v.viewFilter(props.columnViewOption)) //메인 페이지에서 컬럼 노출 여부 제어
        .map((v,index,data)=>{
            // if(v.group){ v.group.colspan = data.filter((v2)=>v2.group?.key===v.group?.key).length; }   //colspan 초기화 및 해당 키를 같는 컬럼수 계산
             //colspan 초기화 및 해당 키를 같는 컬럼수 계산 - 연속성 추가
            if(v.group){
                let count:number = 0;
                for(var i=index; i<data.length; i++){
                    if(data[i]?.group?.key !== v.group?.key){ break; }
                    count++;
                } 
                v.group.colspan = count;
             }
            return v;
        });

    return (<>
        <div className={clsx("d-flex mb-5", {"custom-table-fixed-toolbar":props.fiexedToolbar!==false})} 
            style={{
                maxWidth: props.noneSidebar ? 'calc(100vw- 60px)' : 'calc(100vw - var(--bs-app-sidebar-width) - 5em - 60px)',
                zIndex:3}}>
            <Container className="d-flex justify-content-start p-0" style={{minWidth:'fit-content'}}>{props.customButton}</Container>
            <Container className="justify-content-end p-0 d-flex" style={{textAlign:"right", whiteSpace:"nowrap" }}>
                { props.appendButton}
                { props.showSearchBox===true && props.filterable===false &&
                    <AdPloSearch
                        className='w-200px me-1'
                        placeholder={props.searchBoxPlaceholder || 'Search'}
                        onKeyDown={searchOnKeyDown}
                        onChange={(e)=>setSearch(e.target.value)}
                        value={search}
                    />
                }
                { props.showSearch!==false && props.handleSearch && (<AdploButton className='me-1' onClick={handleSearch} onDoubleClick={()=>{props.dataReload?.()}}><i className='bi bi-search me-1'/>검색</AdploButton>) }
                { props.showRefresh && props.dataReload && (<AdploButton className='me-1' onClick={() =>{ props.dataReload?.()}}><i className='bi bi-arrow-clockwise'/></AdploButton>) }
                { props.showColumns!==false && <ColumnList className='me-1' columns={props.columns} columnOnChange={setHeaderColumns} />}
                { props.showDeviceType && (<DeviceTypeButton onChange={handleDeviceTypeChange} className='me-1' />)}
                { props.optionButton}
                { props.handleDownload && (<AdploButton className='me-1' onClick={()=>{props.handleDownload && props.handleDownload()}} disabled={props.downloadDisabled}><i className='bi bi-download me-1'/>다운로드</AdploButton>) }
            </Container>
        </div>

        {/* <div
            ref={refDiv}
            className='scroll-x'
            style={{
                minWidth:'100%', 
                minHeight: '50vh', 
                maxWidth: props.noneSidebar ? 'calc(100vw - 60px)' : 'calc(100vw - var(--bs-app-sidebar-width) - 5em - 60px)'
            }}
        > */}
        <table className={clsx('table custom_table dataTable table-hover table-bordered table-striped', props.className)} data-checkbox={props.checkbox} >
            <colgroup>
                { isViewCheckColumn && (<col key='maincol' />) }
                { lastHeaderColumns.map((v, index) => (<col key={index} style={{minWidth:'40px', ...v.style}} />)) }
            </colgroup>
            <thead className={clsx('justify-content-md-between', useGroupType?'grouptype':'')}>
                <tr className='text-start text-muted fw-bolder gs-0 custom-table-title'>
                    { isViewCheckColumn && (<th rowSpan={useGroupType ? 2 : 1} className='p-1 text-center'><Checkbox id='selectAll' onChange={onCheckRowAll} checked={isSelectAll} disabled={!props.checkbox}/></th>) }
                    {
                        lastHeaderColumns.filter((v,i)=>!useGroupType || v.group===undefined || i===0 || (v.group && i>0 && v.group.key!==lastHeaderColumns[i-1].group?.key))  //그룹사용 않하거나, 그룹이 아니며, 키가 다를것
                        .map((item, index)=> (!useGroupType || !item.group)
                            ? (<th key={index}
                                    rowSpan={useGroupType ? 2 : 1}
                                    className={clsx('text-gray-600 ps-1 pe-1', item.headerClassName, useSort && item.useSort!==false ? 'cursor-pointer' : '')}
                                    style={{textAlign:'center', ...item.headerStyle}}   //Group인 경우 개별 설정 무시
                                    onClick={() => item.useSort!==false && handleSort(item.key || item.accessor, item.accessor, item)}
                                >
                                    {item.header}
                                    <SortIcon id={item.key || item.accessor} value={sortKey} data={sorting}/>
                                </th>) //일반컬럼
                            : (<th key={index} colSpan={item.group.colspan} className='text-gray-600 th_group' style={{textAlign:'center'}}> {item.group.header}</th>
                        )
                    )}
                </tr>
                {useGroupType && //그룹 설정된 내용 출력
                    (<tr className='bg-gray-800 custom-table-title'>
                        { isViewCheckColumn && <th style={{display:'none'}}/> }
                   
                        {
                            lastHeaderColumns
                            // .filter((v)=>v.group)  //그룹사용하는 건들
                            .map((item, index)=>
                                item.group
                                ? <th key={index}
                                        className={clsx('text-gray-600 th_group', item.headerClassName, useSort && item.useSort!==false ? 'cursor-pointer' : '')}
                                        onClick={() => item.useSort!==false && handleSort(item.key || item.accessor, item.accessor, item)}
                                        style={{textAlign:'center', ...item.headerStyle}}
                                    >
                                        {item.header}
                                        <SortIcon id={item.key || item.accessor} value={sortKey} data={sorting}/>
                                    </th>
                                : <th key={index} style={{display:'none'}} />
                            )
                        }
                    </tr>)
                }
                
                {(props.filterable!==false) && ( 
                    <Filter columns={lastHeaderColumns} data={useFilters} onChange={setFilters} useReset={isViewCheckColumn} onReset={onReset} />
                )}
                {props.staticData?.map((row, index) => { // 통계 데이터 출력 - 수자형만 포맷을 따르고 나머지는 그대로 출력하도록 합니다. - 2023-12-05
                    let colSpan:number=0;   //컬럼 합치기 계산용
                    return (<tr key={index} className='custom-table-total'> 
                        { isViewCheckColumn && (<th style={{backgroundColor:'var(--bs-gray-200)'}}/>) }
                        { lastHeaderColumns.map((header, k) => {
                            const value = Utils.DotNotation.getValue(header.key || header.accessor, row as Object);
                            const isNumber:boolean = typeof(value)==='number';
                            // const viewText = (value && typeof(value)==='number') ? Number(value).addComma() : value;
                            const viewText = formatter(value, row, k, header.formatter, header.filter);
                            const style:React.CSSProperties = (value || isNumber) ? {textAlign: isNumber ? 'right' : 'center', padding:'0.75rem'} : {};
                            colSpan = header.colSpan || (colSpan>0 ? colSpan-1 : 0);
                            const isView:boolean = (header.colSpan || 0)>0 || colSpan===0;
                            const lastValue:any = isNumber ? viewText : value;
                            return isView ? (<th key={k} colSpan={header.colSpan} style={{fontWeight:'normal', backgroundColor:'var(--bs-gray-200)', ...style}}>
                                { lastValue===undefined || lastValue===null ? '-' : lastValue}
                            </th>) : <th key={k} style={{display:'none'}}></th>;    //보이지 않는 컬럼을 빼는 경우, 틀고정이 흐트러짐
                        })}
                    </tr>);
                })}
                
            </thead>
            <tbody>
                {useData?.map((row, index) => {
                    const id = TableUtils.getId(row, props.chekcedId);
                    return (<tr key={index} className={clsx(styles.trFocus,{[styles.active]: id === selectRow,})}>
                        { isViewCheckColumn && (<td className="text-center custom-table-checkbox p-0 align-content-center" style={{lineHeight:'normal'}}>
                            <Checkbox 
                                onChange={(e) => onCheckRow(e, id, row)}
                                checked={useCheckList.find((v)=> v.id === id) ? true : false}
                                disabled={props.checkDisabled?.includes(id) || !props.checkbox}
                            /></td>) }
                        { lastHeaderColumns.map((header, k) => {
                            const value = Utils.DotNotation.getValue(header.accessor, row as Object);
                            const viewText = formatter(value, row, k, header.formatter, header.filter);
                            // const textAlign = (header.align === undefined || header.align === '') ? 'text-left' : 'text-'+header.align;
                            // const style = textAlign;//['px-4', 'py-3', textAlign].join(' ');

                            return <td
                                key={`${(header.key || header.accessor)}_${k}`}
                                className={header.className} style={header.style}
                            >{viewText || '-'}</td>
                        })}
                    </tr>)})
                }
                {useData?.length===0 && (<tr><td colSpan={colspan}>
                    <div className='p-12 text-center fs-4 custom-table-fixed-toolbar' style={{maxWidth:'calc(100vw - var(--bs-app-sidebar-width) - 140px)'}}>
                        <div>
                            {!props.dataNoneNotice && <>
                                <i className="bi bi-emoji-frown me-3" style={{fontSize:"2rem"}} />
                                <span style={{lineHeight:'180%', verticalAlign:'bottom'}}>조회된 데이터가 없습니다.</span>
                            </>}
                            {props.dataNoneNotice && <>{props.dataNoneNotice}</>}
                        </div>
                    </div>
                </td></tr>)}
            </tbody>
        </table>
        {/* </div> */}
        {props.pageable!==false && <div className='mt-5 custom-table-fixed-toolbar' style={{maxWidth:'calc(100vw - var(--bs-app-sidebar-width) - 140px)', zIndex:0, }}>
            <PaginationBootstrap
                page={usePage.page}
                total={props.totalData || 0}
                pageSize={usePage.size}
                pageSizeOptions={props.pageSizeOptions}
                onChange={onChangePage}
            />
        </div>}
        {props.handleMoreLoad && usePage.page < totalPage && <div
            className={clsx('mt-5 btn btn-light w-100 btn-outline', {'custom-table-fixed-toolbar':props.fiexedToolbar!==false})}
            style={{zIndex:'unset', maxWidth:'calc(100vw - var(--bs-app-sidebar-width) - 5em - 60px)'}}
            onClick={()=>props.handleMoreLoad && props.handleMoreLoad(usePage.page+1, usePage.size, useFilters)}
        >더보기 ({usePage.page} / {totalPage})</div>}
    </>);
}


const SortIcon:React.FC<{id:string, value:string, data:RequestOrderType}> = (props)=>
    (props.id === props.value && [SortKeys.Asc, SortKeys.Desc].indexOf(props.data.direction)!==-1) 
? <i className={`sort-by-${props.data.direction.toLowerCase()}`}/> : <></>;


/**
 * Filter의 Option데이터를 기반으로 자동으로 format처리 가능하도록 합니다. 
 * @param value 
 * @param row 
 * @param index 
 * @param formatter 
 * @param filter 
 * @returns 
 */
export const formatter = (
    value:any, 
    row?:any, 
    index?:number, 
    formatter?:FormatterPropType, 
    filter?:FilterPropType,
):React.ReactNode|string|undefined => {
    //포맷지정
    if(formatter){ return formatter(value, row, index); };

    //목록 선택형
    const options:FilterOptionType[]|undefined = filter?.options;
    if( filter && options && [FilterType.mSelect, FilterType.arraySelect, FilterType.select].includes(filter.type)){
        if(Array.isArray(value)){
            return <ul style={{margin:0,paddingLeft:'1em'}}>{value.map((v:string, index:number)=><li key={index}>{Option.getValue(options, v.toString())}</li>) || value}</ul>
        }
        return Option.getValue(options, value?.toString()) || value;
    }

    //숫자형
    if( filter?.type === FilterType.number ){
        return value?.toString().addComma() || value;
    }
    return value;
}

export default Table;