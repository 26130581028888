
interface TemplateTaskType{
    create?:string;
    modify?:string;
    onOff?:string;
    delete?:string;
    copy?:string;
    bidAmount?:string;
}
interface TemplateType{
    naver:{
        powerlink:{
            campaign:   TemplateTaskType,
            adgroup:    TemplateTaskType,
            keyword:    TemplateTaskType,
            ad:         TemplateTaskType,
            adExtension:TemplateTaskType,
        },
        shopping:{
            campaign:   TemplateTaskType,
            adgroup:    TemplateTaskType,
            keyword:    TemplateTaskType,
            ad:         TemplateTaskType,
            adExtension:TemplateTaskType,
        },
    },
    kakao:{
        campaign:   TemplateTaskType,
        adgroup:    TemplateTaskType,
        keyword:    TemplateTaskType,
        creative:   TemplateTaskType,
        creativeLink:TemplateTaskType,
    }
}


const _path : Readonly<string> = '/template';
const Template:Readonly<TemplateType> = {
    naver:{
        powerlink:{
            campaign: {
                create: `${_path}/naver/template_naver_powerlink_campaign_create.csv`,
                modify: `${_path}/naver/template_naver_powerlink_campaign_update.csv`,
                onOff:  `${_path}/naver/template_naver_powerlink_campaign_onoff.csv`,
            },
            adgroup: {    
                create: `${_path}/naver/20240829/template_naver_powerlink_adgroup_create.csv`,
                modify: `${_path}/naver/20240822/template_naver_powerlink_adgroup_update.csv`,
                onOff:  `${_path}/naver/template_naver_powerlink_adgroup_onoff.csv`,
                delete: `${_path}/naver/template_naver_powerlink_adgroup_delete.csv`,
            },
            keyword: {    
                create: `${_path}/naver/template_naver_powerlink_keyword_create.csv`,
                modify: `${_path}/naver/20250214/template_naver_powerlink_keyword_update.csv`,
                onOff:  `${_path}/naver/20231128/template_naver_powerlink_keyword_onoff.csv`,
                delete: `${_path}/naver/template_naver_powerlink_keyword_delete.csv`,
                copy:   `${_path}/naver/template_naver_powerlink_keyword_copy.csv`,
            },
            ad:{    
                create: `${_path}/naver/template_naver_powerlink_ad_create.csv`,
                onOff:  `${_path}/naver/template_naver_powerlink_ad_onoff.csv`,
                delete: `${_path}/naver/template_naver_powerlink_ad_delete.csv`,
                copy:   `${_path}/naver/template_naver_powerlink_ad_copy.csv`,
            },
            adExtension:{    
                // create: `${_path}/naver/20231121/template_naver_powerlink_adextension_create.csv`,
                // modify: `${_path}/naver/20231121/template_naver_powerlink_adextension_update.csv`,
                onOff:  `${_path}/naver/20231121/template_naver_powerlink_adextension_onoff.csv`,
                delete: `${_path}/naver/20231121/template_naver_powerlink_adextension_delete.csv`,
                copy:   `${_path}/naver/20231128/template_naver_powerlink_adextension_copy.csv`,
            },
        },

        shopping:{
            campaign: {
                create: `${_path}/naver/template_naver_shopping_campaign_create.csv`,
                modify: `${_path}/naver/template_naver_shopping_campaign_update.csv`,
                onOff:  `${_path}/naver/template_naver_shopping_campaign_onoff.csv`,
            },
            adgroup: {    
                create: `${_path}/naver/20230919/template_naver_shopping_adgroup_create.csv`,
                modify: `${_path}/naver/template_naver_shopping_adgroup_update.csv`,
                onOff:  `${_path}/naver/template_naver_shopping_adgroup_onoff.csv`,
                delete: `${_path}/naver/template_naver_shopping_adgroup_delete.csv`,
            },
            keyword: {    
                onOff:  `${_path}/naver/20231128/template_naver_shopping_keyword_onoff.csv`,
            },
            ad:{    
                bidAmount:`${_path}/naver/template_naver_shopping_ad_bidamount.csv`,
                onOff:  `${_path}/naver/template_naver_shopping_ad_onoff.csv`,
                delete: `${_path}/naver/template_naver_shopping_ad_delete.csv`,
                copy:   `${_path}/naver/20230919/template_naver_shopping_ad_copy.csv`,
            },
            adExtension:{    
                // create: `${_path}/naver/template_naver_shopping_adextension_create.csv`,
                // modify: `${_path}/naver/template_naver_shopping_adextension_update.csv`,
                onOff:  `${_path}/naver/20231121/template_naver_shopping_adextension_onoff.csv`,
                delete: `${_path}/naver/20231121/template_naver_shopping_adextension_delete.csv`,
                // copy:   `${_path}/naver/template_naver_shopping_adextension_copy.csv`,
            },
        },
    },
    kakao:{
        campaign:{
            create: `${_path}/kakao/template_kakao_campaign_create.csv`,
            modify: `${_path}/kakao/template_kakao_campaign_update.csv`,
            onOff:  `${_path}/kakao/template_kakao_campaign_onoff.csv`,
        },
        adgroup: {
            create: `${_path}/kakao/template_kakao_adgroup_create.csv`,
            modify: `${_path}/kakao/template_kakao_adgroup_update.csv`,
            onOff:  `${_path}/kakao/template_kakao_adgroup_onoff.csv`,
            delete: `${_path}/kakao/template_kakao_adgroup_delete.csv`,
        },
        keyword: {
            create: `${_path}/kakao/template_kakao_keyword_create.csv`,
            modify: `${_path}/kakao/template_kakao_keyword_update.csv`,
            onOff:  `${_path}/kakao/template_kakao_keyword_onoff.csv`,
            delete: `${_path}/kakao/template_kakao_keyword_delete.csv`,
            copy:   `${_path}/kakao/20230919/template_kakao_keyword_copy.csv`,
        },
        creative:{
            delete: `${_path}/kakao/template_kakao_creative_delete.csv`,
        },
        creativeLink:{
            create: `${_path}/kakao/template_kakao_creativelink_create.csv`,
            onOff:  `${_path}/kakao/template_kakao_creativelink_onoff.csv`,
            delete: `${_path}/kakao/template_kakao_creativelink_delete.csv`,
        },
    }
}

export default Template;