/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import { CSSProperties, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { OptionType } from '../../../../modules/types/Types';
import AdploButton from '../../components/buttons/AdploButton';
import AdPloSearch from '../../components/input/AdPloSearch';
import CustomCard, { Warning } from '../../modules/card/CustomCard';
import { HeaderColumn } from '../../modules/table/TableType';
import { UserMessage } from '../AiExplorer';
import { QListResType } from '../QListTypes';
import styles from './style.module.scss';

interface IndicatorSelectProps{
    className?: string;
    style?: CSSProperties;

    values?: OptionType[];
    colums: HeaderColumn[];
    selectQ?: QListResType
    onChange?:(value:OptionType[])=>void;
}
const IndicatorSelect:React.FC<IndicatorSelectProps> = (props)=>{
    const [values, setValues] = useState<OptionType[]>([]);
    const [search, setSearch] = useState<string>("");
    const [selected, setSelected] = useState<boolean>(false);
    const [warning, setWarning] = useState<string|undefined>(undefined);
    const [fixed, setFixed] = useState<string[]>([]);

    // 초기값 설정 - 고정형
    useEffect(()=>{
        const fixed = props.selectQ?.selectRange.fixed || [];
        setFixed(fixed);

        const tmp = props.colums
            .filter((v)=>fixed.includes(v.key || v.accessor))
            .map((v)=>({value:(v.key || v.accessor), label: getColumnName(v)}));
        setValues(tmp);
    },[props.colums, props.selectQ]);

    const appendItem = (item:HeaderColumn)=>{
        if( !values.find((v)=>v.value===(item.key || item.accessor) )){
            setValues((prev)=>{
                prev.push({value:(item.key || item.accessor), label: getColumnName(item)});
                return [...prev];
            });
        }
    }

    const removeItem = (key:string)=>{
        setValues((prev)=>prev.filter(v=>v.value!==key));
    }

    const onSelected = ()=>{
        if(values.length < (props.selectQ?.selectRange.min || 0)){
            setWarning(`최소 ${props.selectQ?.selectRange.min}개 이상 선택해 주세요.`);
            return;
        }
        if(values.length > (props.selectQ?.selectRange.max || 0)){
            setWarning(`최대 ${props.selectQ?.selectRange.max}개 이하로 선택해 주세요.`);
            return;
        }
        props.onChange?.(values);
        setSelected(true);
    }

    return <>
        {!selected && <div className={props.className} style={props.style}>
            <CustomCard>
                <div className='d-flex'>
                    <div className='col-5'>
                        <div className='h-50px'>
                            <b>선택 지표 ({values.length}개)</b>
                            <div className='text-gray-600'>필수 {props.selectQ?.selectRange.min}개, 최대 {props.selectQ?.selectRange.max}개</div>
                            <Warning warning={warning} />
                        </div>

                        <div className={clsx('scroll-y my-3', styles.select)}>
                            {values.map((v,index)=><div key={index} className={clsx('my-1', styles.item)}>
                                {v.label}
                                {fixed.includes(v.value) && <i className={clsx("bi bi-lock", styles.remove)} title="필수항목"/>}
                                {!fixed.includes(v.value) && <i className={clsx("bi bi-x-lg", styles.remove)} onClick={()=>{removeItem(v.value);}}/>}
                            </div>)}
                        </div>
                    </div>
                    <div className='col-1'></div>
                    <div className='col-6'>
                        <div className='h-50px align-content-center' style={{position:'relative'}}>
                            <AdPloSearch onChange={(v)=>setSearch(v.target.value)}/>
                        </div>
                        <div className={clsx('scroll-y my-3', styles.list)}>
                            {props.colums
                                .filter((v)=>!search || getColumnName(v).indexOf(search) > -1)
                                .filter((v)=>!values.find(v2=>v2.value === (v.key || v.accessor)))
                                .map((v, index)=><div key={index} onClick={()=>{appendItem(v);}} className={clsx('my-1', styles.item)}>{getColumnName(v)}</div>)}
                        </div>
                    </div>
                </div>

                <Container className="p-0 text-right mt-6">
                    <AdploButton onClick={onSelected} >선택 완료</AdploButton>
                </Container>
            </CustomCard>
        </div>}

        {selected && <UserMessage>{values.map((v)=>v.label).join(", ")}</UserMessage>}
    </>
}

export const getColumnName = (v:HeaderColumn):string =>`${v.group?.header ? `${v.group?.header} - ` : ""}${v.header}`;


export default IndicatorSelect;