/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTSVG } from '../../../_metronic/helpers';
import PopupNotice from '../modules/tooltip/PopupNotice';
import { AiExplore } from './AiExplorer';

export function AiExplorerLayer() {
    return (<>
        {/* begin::Header */}
        <div className='card-header' id='kt_ai-explore_header'>
            <h5 className='card-title fw-bold text-gray-600'>
                adly AI
                <div className='ms-3 fs-7 fw-bold text-gray-400'>
                    <PopupNotice title='참고사항' className='fs-8 fw-normal text-gray-700' style={{lineHeight:'1.5'}}>
                        adly AI의 분석 결과는 ChatGPT 4.0 mini 모델을 활용해 생성됩니다.<br/>
                        adly AI는 분석 결과를 생성할 때 adly 내 다른 광고주의 데이터는 참조하지 않습니다.<br/>
                        간혹 분석 결과가 실제와 다를 수 있으므로 최종 의사결정 전에 반드시 추가 확인을 부탁드립니다.
                    </PopupNotice>
                </div>
            </h5>

            <div className='card-toolbar'>
                <button
                    type='button'
                    className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                    id='kt_engage_explorer_close'
                >
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
                </button>
            </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body bg-light pe-5' id='kt_ai-explore_body'>
            {/* begin::Content */}
            <div
                id='kt_ai-explore_scroll'
                className='scroll-y me-n5 pe-6'
                data-kt-scroll='true'
                data-kt-scroll-height='auto'
                data-kt-scroll-wrappers='#kt_engage_ai-explorer_body'
                data-kt-scroll-dependencies='#kt_engage_ai-explorer_header'
                data-kt-scroll-offset='5px'
                style={{maxHeight:'calc( 100vh - 70px - 3px - 5em)'}}
            >
                <AiExplore />
            </div>
            {/* end::Content */}
        </div>
    </>)
}
