import clsx from 'clsx';
import React, {CSSProperties, useState} from 'react';
import {Table} from 'react-bootstrap';
import UserSelect from '../../../common/helpers/UserSelect';
import {AdResType} from '../../../common/services/models/gfa/AdTypes';
import {StatReportInfoType} from '../../../common/services/models/gfa/StatReportType';
import GfaCode from '../../../modules/code/GfaCode';
import ExplorerListDetail from './ExplorerListDetail';
import {convertStatInfoViewData} from './StatInfoData';
import styles from './explorer.module.scss';

export interface ItemsProps {
    index?: number;
    data: StatReportInfoType;
    media?: string;
    level?: string;
    search?: string;
    show?: boolean;
    isDetail?: boolean;
    setSelect?: (id: string) => void;
}

export const Items: React.FC<ItemsProps> = (props) => {
    const Item = getItemTag(props);
    const id: string | undefined = getItemId(props.level, props.data);
    if (!Item) {
        return <>Unknown {props.level}</>;
    }
    return (
        <>
            {props.show && <DetailWindow level={props.level} search={id} />}
            <div
                className='pt-5'
                style={{
                    overflowY: props.isDetail ? 'scroll' : undefined,
                    maxHeight: props.isDetail ? 'calc(100vh - 276px)' : undefined,
                    marginRight: '-10px',
                    paddingRight: '10px',
                }}
            >
                {!props.isDetail && (
                    <>
                        {props.index !== undefined && (
                            <span className='me-3 fw-bold'>{props.index + 1}</span>
                        )}
                        <Item {...props} />
                    </>
                )}
                <Viewer {...props} />
                {props.show && (
                    <ExplorerListDetail {...props} id={getItemId(props.level, props.data)} />
                )}
            </div>
        </>
    );
};

export const ItemsCampaign: React.FC<ItemsProps> = (props) => (
    <SearchHighlight
        className={styles.link}
        text={props.data.campaignName}
        search={props.search}
        onClick={() => {
            props.setSelect && props.setSelect(props.data.campaignId || '');
        }}
    />
);

export const ItemsAdGroup: React.FC<ItemsProps> = (props) => (
    <SearchHighlight
        className={styles.link}
        text={props.data.adsetName}
        search={props.search}
        onClick={() => {
            props.setSelect && props.setSelect(props.data.adsetId || '');
        }}
    />
);

export const ItemsAd: React.FC<ItemsProps> = (props) => (
    <SearchHighlight
        className={styles.link}
        text={props.data.adName}
        search={props.search}
        onClick={() => {
            props.setSelect && props.setSelect(props.data.adId || '');
        }}
    />
);

const Viewer: React.FC<{level?: string; data?: StatReportInfoType; isDetail?: boolean}> = (
    props
) => {
    const viewData = convertStatInfoViewData(props.level, props.data);
    return (
        <>
            {props.level === GfaCode.data.options.ExplorerLevel.ad.value && (
                <ImageTooltip value={(props.data as AdResType | undefined)?.imageUrl} />
            )}
            <Table
                className={
                    props.isDetail
                        ? 'table border table-bordered border-gray-300 table-row-bordered table-row-gray-300 mb-0'
                        : ''
                }
            >
                <colgroup>
                    <col width={props.isDetail ? '200px' : '120px'} />
                    <col />
                </colgroup>
                <tbody>
                    {viewData?.map((v, index) => (
                        <tr key={index}>
                            <th
                                className={clsx(
                                    'text-gray-500 me-3 text-right',
                                    props.isDetail ? '' : 'p-1'
                                )}
                            >
                                {v.title}
                            </th>
                            <td className={clsx(props.isDetail ? '' : 'p-1')}>{v.value}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    );
};

export const getItemId = (level?: string, item?: StatReportInfoType): string | undefined => {
    switch (level) {
        case GfaCode.data.options.ExplorerLevel.campaign.value:
            return item?.campaignId;
        case GfaCode.data.options.ExplorerLevel.adGroup.value:
            return item?.adsetId;
        case GfaCode.data.options.ExplorerLevel.ad.value:
            return item?.adId;
        default:
    }
    return undefined;
};

export const getItemTag = (props: ItemsProps): React.FC<ItemsProps> | undefined => {
    switch (props.level) {
        case GfaCode.data.options.ExplorerLevel.campaign.value:
            return ItemsCampaign;
        case GfaCode.data.options.ExplorerLevel.adGroup.value:
            return ItemsAdGroup;
        case GfaCode.data.options.ExplorerLevel.ad.value:
            return ItemsAd;
    }
    return undefined;
};

interface DetailWindowProps {
    level?: string;
    search?: string;
}
const DetailWindow: React.FC<DetailWindowProps> = (props) => {
    const qry = UserSelect.storage.getQueryString({
        explorer: {level: props.level, search: props.search},
    });
    return (
        <div className='text-right w-100'>
            <a target='_blank' rel='noreferrer' href={`/popup/gfa/explorer/?${qry}`}>
                자세히 보기 <i className='bi bi-box-arrow-up-right' />
            </a>
        </div>
    );
};

/**
 * 검색키워드 하일라이트 처리
 * @param props
 * @returns
 */
const SearchHighlight: React.FC<{
    text?: string;
    search?: string;
    className?: string;
    style?: CSSProperties;
    onClick?: () => void;
}> = (props) => {
    const replaceText = (
        text: string,
        needle: string,
        replace: React.ReactNode
    ): React.ReactNode => {
        return (
            <>
                {text.split(needle).map((v, index) => (
                    <span key={index}>
                        {index > 0 && replace}
                        {v}
                    </span>
                ))}
            </>
        );
    };
    return (
        <span className={props.className} style={props.style} onClick={props.onClick}>
            {props.search && props.text
                ? replaceText(
                      props.text,
                      props.search,
                      <span className='text-danger'>{props.search}</span>
                  )
                : props.text}
        </span>
    );
};

const ImageTooltip: React.FC<{value?: string}> = (props) => {
    const [show, setShow] = useState<boolean>(false);
    let tm: number | null = null;
    const onShow = () => {
        if (tm) {
            window.clearTimeout(tm);
            tm = null;
        }
        setShow(true);
    };

    const onHide = () => {
        tm = window.setTimeout(() => setShow(false), 100);
    };
    return (
        <div style={{position: 'relative'}}>
            <div
                className={clsx(
                    'bg-body mt-3 shadow p-2 border border-gray-300 rounded position-absolute collapse',
                    {show: show}
                )}
                style={{zIndex: 1, left: '90px', top: '50px'}}
                // onMouseOver={onShow} onMouseOut={onHide}
            >
                <img src={props.value} style={{maxWidth: '500px'}} />
            </div>
            {props.value && (
                <img
                    src={props.value}
                    className='border border-gray-300 cursor-pointer'
                    style={{maxHeight: '60px', margin: '0.75em', maxWidth: '300px'}}
                    onMouseOver={onShow}
                    onMouseOut={onHide}
                />
            )}
        </div>
    );
};
