import { AdlyAiServer } from '../../axios';
import { RequestSearchType } from '../models/RequestTypes';
import { MediaResponseType } from '../models/ResponseTypes';
import HelperComponent from './HelperComponent';

/** Helper */
export default class ReqHistoryHelper extends HelperComponent{
    static path="/req-history"; //기본 Path

    //표준 목록조회
    public static getList<T=any>(params: RequestSearchType) {
        return AdlyAiServer.get<MediaResponseType<T>>(`${this.path}/list`, {
            params: params,
            headers: {useAccount: false},
        });
    }
    
}
