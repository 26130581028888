/** 리포트 결과 배열타입 */
export interface StatReportResultArrayType<T = StatReportResType, V = StatReportInfoType> {
    info: V;
    performance: T;
}

/**  리포트 결과 타입 */
export interface StatReportResultType<T = StatReportResType> {
    info?: StatReportInfoType;
    total?: T[];
    daily?: T[];
    performances?: T[];
}

/** Explorer 상세보기 타입 */
export interface StatReportInfoType {
    objective: string;
    objectiveName: string;
    campaignName?: string;
    campaignId?: string;
    brandName?: string;
    brandId?: string;
    pricingName?: string;
    createdDatetime?: string;

    placement?: string;
    placementName?: string;
    adsetName?: string;
    adsetId?: string;
    startTime?: string;
    stopTime?: string;

    creativeTypeName: string;
    adName: string;
    adId: string;
    ng_creative_type: string;
    creative_id: string;
    img_url: string;
}

/** 리포트 데이터 타입, key - entry ID or Date */
export interface StatReportResType {
    key: string;
    impressions: number;
    clicks: number;
    cost: number;
    videoView: number;
    conversions: number;
    conversionsValue: number;

    ctr: number;
    cvr: number;
    vtr: number;
    roas: number;
    cpc: number;
    cpm: number;
    cpv: number;
}

/**
 * 리포트 컬럼 처리용 키
 */
export enum StatReportResKeys {
    key = 'key',
    impressions = 'impressions',
    clicks = 'clicks',
    cost = 'cost',
    videoView = 'videoView',
    conversions = 'conversions',
    conversionsValue = 'conversionsValue',

    ctr = 'ctr',
    cvr = 'cvr',
    vtr = 'vtr',
    roas = 'roas',
    cpc = 'cpc',
    cpm = 'cpm',
    cpv = 'cpv',
    cpr = 'cpr',
}
