import { AdlyAiServer } from '../../axios';
import Utils from '../../utils/Utils';
import { RequestSearchType } from '../models/RequestTypes';
import { MediaResponseType } from '../models/ResponseTypes';
import HelperComponent from './HelperComponent';

/** Helper */
export default class ContentHelper extends HelperComponent{
    static path="/content/info"; //기본 Path

    //표준 수정
    public static put<S = any>(body: S, id?: number) {
        return AdlyAiServer.put(`${this.path}/${id?.toString()}`, body, {headers: {useAccount: false}});
    }

    //표준 목록조회
    public static getList<T = any>(params: RequestSearchType) {
        return (
            Utils.apiRequestValidation({}) ||
            AdlyAiServer.get<MediaResponseType<T>>("/content/list", {
                params: params,
                headers: {useAccount: false},
            })
        );
    }
    
}
