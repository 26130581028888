import clsx from 'clsx';
import React, { CSSProperties } from 'react';
import styles from './style.module.scss';

interface PopupNoticeProps {
    className?: string;
    style?: CSSProperties;
    title?: React.ReactNode;
    children?: React.ReactNode;
}

/** 타이틀에 대해서 마우스 오버할 경우, 툴팁형태로 표시합니다. */
const PopupNotice: React.FC<PopupNoticeProps> = (props) => {
    return <div className={clsx('p-relative', styles.PopupNotice)}>
        <div className={styles.title}>{props.title}</div>
        <div 
            className={clsx('border border-gray-300 p-3 bg-body text-left shadow', props.className, styles.popup)}
            style={{zIndex:999, position:'absolute', ...props.style}}
        >{props.children}</div>
    </div>;
};

export default PopupNotice;
